import React, { useEffect, useState } from "react";
import { Calendar, DatePicker, Form, Input, Spin, Tag, TimePicker } from "antd";

import axiosInstance from "../../../../utils/axiosInstance";
import PopupModal from "../../popupModal";
import {
  standard_date_only_format_backend,
  standard_date_only_format_frontend,
  standard_time_only_format_backend,
  standard_time_only_format_frontend,
} from "../../../../utils/dateAndTimeFormatter";
import dayjs from "dayjs";
import notificationShow from "../../notificationService";
import { useLocation, useParams } from "react-router-dom";
import decodeAccessToken from "../../../../utils/decodedAccessToken";
import ClockInOut from "./clock-in-out";
const { TextArea } = Input;

const AttendanceCalender = () => {
  const accessToken = localStorage.getItem("accessToken");
  const { _id, is_admin }: any = decodeAccessToken(accessToken);
  const { user_id } = useParams();
  const { pathname } = useLocation();
  const path = pathname.split("/");
  const [error, setError] = useState<string>("");
  const [form] = Form.useForm();
  const [holidays, setHolidays] = useState<any>([]);
  const [attendances, setAttandances] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<any>("");
  const [matchingAttendances, setMatchingAttendances] = useState<any>([]);
  const [selectedAttendanceId, setSelectedAttendanceId] = useState<string>("");
  const [popupManuelEntry, setPopupManuelEntry] = useState(false);

  const getAttendances = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `/attendance?user_id=${user_id ? user_id : _id}`
      );
      setAttandances(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getHolidays = async () => {
    try {
      const response = await axiosInstance.get("/settings/holidays");
      setHolidays(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAttendances();
  }, []);

  useEffect(() => {
    getHolidays();
  }, []);

  const dateCellRender = (value: any) => {
    const formattedDate = value.format(standard_date_only_format_backend);

    const matchingHoliday = holidays.find(
      (holiday: any) =>
        dayjs(holiday.holiday_date).format(
          standard_date_only_format_backend
        ) === formattedDate
    );

    const isHolidayDate = matchingHoliday !== undefined;

    const matchingAttendances = attendances.filter(
      (attendance: any) =>
        dayjs(attendance.day_start).format(
          standard_date_only_format_backend
        ) === formattedDate
    );

    if (matchingAttendances.length > 0) {
      return matchingAttendances.map((attendance: any, index: any) => (
        <div
          className={`${
            attendance.approval_status.toLowerCase() === "pending" &&
            "bg-pendingColor"
          } ${
            attendance.approval_status.toLowerCase() === "rejected" &&
            "bg-rejectedColor"
          }   ${
            attendance.approval_status.toLowerCase() === "approved" &&
            attendance.attendance_clockout_status.toLowerCase() ===
              "full time" &&
            attendance.attendance_clockin_status.toLowerCase() === "on time" &&
            "bg-approvedColor"
          } ${
            attendance.approval_status.toLowerCase() === "approved" &&
            attendance.attendance_clockout_status.toLowerCase() ===
              "full time" &&
            attendance.attendance_clockin_status.toLowerCase() === "half day" &&
            "bg-late"
          }  ${
            attendance.approval_status.toLowerCase() === "approved" &&
            attendance.attendance_clockout_status.toLowerCase() ===
              "left early" &&
            attendance.attendance_clockin_status.toLowerCase() === "on time" &&
            "bg-late"
          } ${
            attendance.approval_status.toLowerCase() === "approved" &&
            attendance.attendance_clockout_status.toLowerCase() ===
              "left early" &&
            attendance.attendance_clockin_status.toLowerCase() === "half day" &&
            "bg-late"
          }  ${
            attendance.approval_status.toLowerCase() === "approved" &&
            attendance.attendance_clockout_status.toLowerCase() ===
              "left early" &&
            attendance.attendance_clockin_status.toLowerCase() === "late" &&
            "bg-late"
          }  ${
            attendance.approval_status.toLowerCase() === "approved" &&
            attendance.attendance_clockout_status.toLowerCase() ===
              "full time" &&
            attendance.attendance_clockin_status.toLowerCase() === "late" &&
            "bg-late"
          }  h-full p-2 flex flex-col items-center justify-center text-whiteColor text-2xl font-semibold`}
        >
          {value.format("DD")}
        </div>
      ));
    } else {
      if (isHolidayDate) {
        return (
          <div className="flex flex-col items-center justify-center h-full">
            <div className="text-2xl font-semibold text-rejectedColor">
              {value.format("DD")}
            </div>
            {isHolidayDate && (
              <div className="text-center text-rejectedColor font-semibold text-[12px]">
                {matchingHoliday.holiday_description}
              </div>
            )}
          </div>
        );
      } else {
        return (
          <>
            {dayjs(formattedDate).isBefore(dayjs()) ? (
              <div className="h-full flex flex-col items-center justify-center text-2xl font-semibold text-whiteColor bg-rejectedColor p-2">
                {value.format("DD")}
              </div>
            ) : (
              <div className="h-full p-2 flex flex-col items-center justify-center text-2xl font-semibold ">
                {value.format("DD")}
              </div>
            )}
          </>
        );
      }
    }
  };

  const [popupModal, setPopupModal] = useState(false);
  const showAttendanceDetailsModal = (date: any) => {
    setLoading(false);

    const matchingDate = attendances.reduce((result: any, attendance: any) => {
      if (
        dayjs(attendance.clock_in_time).format(
          standard_date_only_format_backend
        ) === date?.format(standard_date_only_format_backend)
      ) {
        return attendance;
      }
      return result;
    }, null);

    if (
      matchingDate &&
      matchingDate.approval_status.toLowerCase() !== "rejected"
    ) {
      setSelectedDate(
        dayjs(matchingDate.clock_in_time).format(
          standard_date_only_format_backend
        )
      );
      setSelectedAttendanceId(matchingDate._id);
      setMatchingAttendances(matchingDate);
      setPopupModal(true);
    } else {
      return;
    }
  };

  const onCancel = () => {
    setLoading(false);
    setPopupModal(false);
  };

  const onOkay = () => {
    setPopupModal(false);
  };

  //manual entry

  const requestManualEntry = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const formValues = form.getFieldsValue();
      const response = await axiosInstance.post("attendance/manualEntry", {
        manual_entry_date: dayjs(formValues.manual_entry_date).format(
          standard_date_only_format_backend
        ),
        clock_in_time: dayjs(formValues.clock_in_time).format(
          standard_time_only_format_backend
        ),
        clock_out_time: dayjs(formValues.clock_out_time).format(
          standard_time_only_format_backend
        ),
        manual_entry_reason: formValues.manual_entry_reason,
      });
      getAttendances();
      notificationShow({
        type: "success",
        content: "Requested for Manual Attendance Entry!",
      });
      form.resetFields();
      setPopupManuelEntry(false);
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      notificationShow({
        type: "error",
        content: error?.response?.data?.error,
      });
    }
  };
  const showManualEntryModal = () => {
    setPopupManuelEntry(true);
    setLoading(false);
  };

  const onManuelEntryCancel = () => {
    setPopupManuelEntry(false);
  };

  const onManuelEntryOkay = () => {
    requestManualEntry();
  };

  const deleteAttendanceDetail = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const formValues = form.getFieldsValue();
      const response = await axiosInstance.post(
        "/attendance/deleteAttendance",
        {
          attendance_id: selectedAttendanceId,
          delete_reason: formValues.delete_reason,
        }
      );
      getAttendances();
      notificationShow({
        type: "success",
        content: (
          <div>
            Attendance details of{" "}
            <span className="text-rejectedColor font-semibold">
              {dayjs(selectedDate).format("DD MMM YYYY")}
            </span>{" "}
            deleted!
          </div>
        ),
      });
      setDeleteAttendanceConfirmationModal(false);
      setPopupModal(false);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setError(error?.response?.data?.error);
    }
  };

  const [
    deleteAttendanceConfirmationModal,
    setDeleteAttendanceConfirmationModal,
  ] = useState(false);

  const showDeleteAttendanceModal = async () => {
    setLoading(false);
    setDeleteAttendanceConfirmationModal(true);
  };
  const onDeleteAttendaceConfirmationModalCancel = () => {
    setDeleteAttendanceConfirmationModal(false);
  };
  const onDeleteAttendanceConfirmationModalOkay = () => {
    deleteAttendanceDetail();
  };

  return (
    <>
      <div className="flex flex-col">
        {!user_id && (
          <div className="pb-6 flex items-center justify-between ">
            <div className=" text-3xl font-medium">Attendance</div>
            <div className="flex items-center gap-4">
              <ClockInOut
                mainStyles={{
                  display: "flex",
                  flexDirection: "Row",
                  gap: "12px",
                  alignItems: "Center",
                }}
                showTimeStyles={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "16px",
                }}
                displayClock="hidden"
              />
              <div
                onClick={showManualEntryModal}
                className="px-6 py-2 rounded-md border border-black hover:border-primaryColor hover:text-primaryColor transition-all duration-150 ease-in  cursor-pointer"
              >
                Manual Entry
              </div>
            </div>
          </div>
        )}

        <div className="ml-auto flex items-center gap-4 pb-4">
          <div className="flex  items-center gap-1">
            <div className="bg-approvedColor h-6 w-6"></div>
            <div className="text-secondaryText">Present</div>
          </div>
          <div className="flex  items-center gap-1">
            <div className="bg-late h-6 w-6"></div>
            <div className="text-secondaryText">
              Late / Half Day / Left Early
            </div>
          </div>
          <div className="flex  items-center gap-1">
            <div className="bg-rejectedColor h-6 w-6"></div>
            <div className="text-secondaryText">Absent</div>
          </div>
        </div>

        <Spin spinning={loading}>
          <Calendar
            cellRender={dateCellRender}
            onSelect={(date: any) => {
              showAttendanceDetailsModal(date);
            }}
          />
        </Spin>
      </div>
      {popupModal && (
        <PopupModal
          top="20vh"
          width={500}
          open={popupModal}
          closeIcon={false}
          onCancel={onCancel}
          onOkay={onOkay}
          content={
            <div className="flex flex-col items-center gap-8 w-full p-2">
              <div className="flex items-center gap-4 w-full">
                {selectedDate && (
                  <div className="flex flex-col justify-center w-full items-center gap-4">
                    <div className="text-2xl text-center font-semibold">
                      Attendance Details of <br />
                      {dayjs(selectedDate).format("DD MMM")}!
                    </div>
                    <div>
                      Status:{" "}
                      {(matchingAttendances &&
                        matchingAttendances.approval_status === "Approved" && (
                          <Tag
                            color="#"
                            className="font-semibold bg-approvedColor"
                          >
                            {matchingAttendances.approval_status}
                          </Tag>
                        )) ||
                        (matchingAttendances &&
                          matchingAttendances.approval_status === "Pending" && (
                            <Tag
                              color="#"
                              className="font-semibold bg-pendingColor"
                            >
                              {matchingAttendances.approval_status}
                            </Tag>
                          ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="w-full border border-b-gray-200"></div>
              {matchingAttendances && (
                <div className="flex flex-col gap-8 mb-4 w-full">
                  <div className="flex items-center justify-between w-full gap-4">
                    <span>
                      Clock In Time:{" "}
                      {dayjs(matchingAttendances.clock_in_time).format(
                        standard_time_only_format_frontend
                      )}{" "}
                    </span>
                    {(matchingAttendances.attendance_clockin_status ===
                      "On Time" && (
                      <Tag color="#" className="bg-approvedColor font-semibold">
                        {matchingAttendances.attendance_clockin_status}
                      </Tag>
                    )) ||
                      (matchingAttendances.attendance_clockin_status ===
                        "Half Day" && (
                        <Tag color="#" className="bg-late font-semibold">
                          {matchingAttendances.attendance_clockin_status}
                        </Tag>
                      )) ||
                      (matchingAttendances.attendance_clockin_status ===
                        "Late" && (
                        <Tag color="#" className="bg-late font-semibold">
                          {matchingAttendances.attendance_clockin_status}
                        </Tag>
                      ))}
                  </div>
                  <div className="flex items-center justify-between w-full gap-4">
                    <span>
                      Clock Out Time:{" "}
                      {matchingAttendances.clock_out_time
                        ? dayjs(matchingAttendances.clock_out_time).format(
                            standard_time_only_format_frontend
                          )
                        : " "}
                    </span>

                    {(matchingAttendances.attendance_clockout_status ===
                      "Full Time" && (
                      <Tag color="#" className="font-semibold bg-approvedColor">
                        {matchingAttendances.attendance_clockout_status}
                      </Tag>
                    )) ||
                      (matchingAttendances.attendance_clockout_status ===
                        "Left Early" && (
                        <Tag color="#" className="font-semibold bg-late">
                          {matchingAttendances.attendance_clockout_status}
                        </Tag>
                      ))}
                  </div>
                </div>
              )}
              {is_admin && path[1] === "dashboard" && (
                <>
                  <div className="w-full border border-b-gray-200"></div>
                  <div
                    onClick={showDeleteAttendanceModal}
                    className="text-sm px-2 py-1 rounded-md text-red-400 border border-red-400 cursor-pointer"
                  >
                    Delete This Attendance
                  </div>
                </>
              )}
            </div>
          }
          button1=""
          button2="Okay"
          hideButton1="hidden"
          hideButton2="hidden"
          button1Color=""
          button2Color="white"
          button1BgColor=""
          button2BgColor="primaryColor"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      {popupManuelEntry && (
        <PopupModal
          top="15vh"
          width={550}
          open={popupManuelEntry}
          onCancel={onManuelEntryCancel}
          onOkay={onManuelEntryOkay}
          content={
            <div className="flex flex-col items-start gap-6 w-full p-2">
              <div className="text-xl font-semibold">
                Manual Attendance Entry !
              </div>
              <Form
                layout="vertical"
                form={form}
                className="flex flex-col w-full"
              >
                <Form.Item
                  required
                  label="Manual Entry Date"
                  name="manual_entry_date"
                  className="font-medium"
                >
                  <DatePicker
                    className="border border-gray-300 rounded-md w-full py-2 px-4"
                    format={standard_date_only_format_frontend}
                  />
                </Form.Item>
                <div className="flex items-center gap-4 w-full">
                  <Form.Item
                    required
                    label="Clock In Time"
                    name="clock_in_time"
                    className="font-medium w-full"
                  >
                    <TimePicker
                      className="border border-gray-300 rounded-md w-full py-2 px-4"
                      format={standard_time_only_format_frontend}
                    />
                  </Form.Item>
                  <Form.Item
                    required
                    label="Clock Out Time"
                    name="clock_out_time"
                    className="font-medium w-full"
                  >
                    <TimePicker
                      className="border border-gray-300 rounded-md w-full py-2 px-4"
                      format={standard_time_only_format_frontend}
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  required
                  label="Reason for Manual Entry"
                  name="manual_entry_reason"
                  className="font-medium"
                >
                  <TextArea
                    className="border border-gray-300 rounded-md w-full py-2 px-4"
                    maxLength={200}
                    rows={3}
                  />
                </Form.Item>
              </Form>
            </div>
          }
          button1="Cancel"
          button2="Request Entry"
          hideButton1=""
          hideButton2=""
          button1Color="white"
          button2Color="white"
          button1BgColor="rejectedColor"
          button2BgColor="primaryColor"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}

      {deleteAttendanceConfirmationModal && (
        <PopupModal
          width={500}
          top="20vh"
          open={deleteAttendanceConfirmationModal}
          onCancel={onDeleteAttendaceConfirmationModalCancel}
          onOkay={onDeleteAttendanceConfirmationModalOkay}
          content={
            <div className="flex flex-col items-center gap-8 p-2">
              <div className="text-2xl text-center font-semibold">
                Attendance Delete <br /> Confirmation!
              </div>
              <div className="text-center">
                You are about to delete attendance details of
                <br />
                <span className="text-xl font-semibold text-primaryColor">
                  {dayjs(selectedDate).format("DD MMM YYYY")}
                </span>{" "}
                .
              </div>
              <Form
                layout="vertical"
                form={form}
                className="flex flex-col w-full"
              >
                <Form.Item
                  required
                  label="Delete Reason"
                  name="delete_reason"
                  className="font-medium"
                  rules={[
                    {
                      required: true,
                      message: "Attendance deletion reason is required.",
                    },
                  ]}
                >
                  <TextArea
                    maxLength={200}
                    rows={4}
                    className="border border-gray-300 rounded-md w-full py-2 px-4"
                  />
                </Form.Item>
              </Form>
              {error && (
                <div className="bg-red-50 text-red-500 rounded-md text-sm text-center py-2">
                  {error}
                </div>
              )}
            </div>
          }
          button1="Cancel"
          button2="Delete"
          hideButton1=""
          hideButton2=""
          button1Color="primaryColor"
          button2Color="white"
          button1BgColor="secondaryColor"
          button2BgColor="rejectedColor"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}
    </>
  );
};

export default AttendanceCalender;
