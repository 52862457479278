import { Spin } from "antd";
import { useEffect, useState, useRef } from "react";

import axiosInstance from "../../../../utils/axiosInstance";
import notificationShow from "../../notificationService";

interface EmployeeWorklog {
  heightOfTextArea?: string;
  styleSaveButton?: string;
  bottonColor?: string;
  textColor?: string;
  bottonHoverColor?: string;
  paddingInTextArea?: string;
}

const EmployeeTodaysWorklog = ({
  heightOfTextArea,
  styleSaveButton,
  bottonColor,
  textColor,
  bottonHoverColor,
  paddingInTextArea,
}: EmployeeWorklog) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loading1, setLoading1] = useState<boolean>(false);
  const textareaRef: any = useRef(null);
  const [todayWorklog, setTodayWorklog] = useState<any>([]);
  const debounceTimeout = useRef<any>(null);

  const getTodaysWorklog = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/worklog/my/today`);
      setTodayWorklog(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTodaysWorklog();
  }, []);

  useEffect(() => {
    if (todayWorklog?.log) {
      textareaRef.current.value = todayWorklog?.log;
    }
  }, [todayWorklog]);

  useEffect(() => {
    const textArea = textareaRef.current;

    if (textArea) {
      textArea.value = "● ";
      textArea.focus();

      textArea.addEventListener("input", handleInput);
      textArea.addEventListener("keydown", handleKeyDown);

      return () => {
        textArea.removeEventListener("input", handleInput);
        textArea.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, []);

  const handleInput = (event: any) => {
    const textArea = textareaRef.current;

    if (textArea) {
      if (textArea.value === "") {
        textArea.value = "● ";
        textArea.setSelectionRange(2, 2);
      }
    }
  };

  const handleKeyDown = (event: any) => {
    const textArea = textareaRef.current;

    if (event.key === "Enter" && textArea) {
      event.preventDefault();
      const selectionStart = textArea.selectionStart;
      const newText =
        textArea.value.slice(0, selectionStart) +
        "\n● " +
        textArea.value.slice(selectionStart);
      textArea.value = newText;
      textArea.setSelectionRange(selectionStart + 3, selectionStart + 3);
    }
  };

  const updateTodaysWorklog = async () => {
    try {
      setLoading1(true);
      const response = await axiosInstance.post(
        "/worklog/addUpdateTodaysWorkLog",
        {
          log: textareaRef.current.value,
        }
      );

      setTimeout(() => {
        setLoading1(false);
      }, 500);
      notificationShow({
        type: "success",
        content: "Daily worklog updated successfully!",
      });
    } catch (error: any) {
      console.log(error);
      setLoading1(false);
      notificationShow({
        type: "error",
        message: error?.response?.data?.error,
      });
    }
  };

  const handleTextareaChange = () => {
    clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(() => {
      updateTodaysWorklog();
    }, 1000);
  };

  return (
    <Spin spinning={loading}>
      <div className="flex flex-col gap-6 relative">
        <div
          style={{
            scrollbarWidth: "thin",
          }}
          className={`${heightOfTextArea} overflow-auto`}
        >
          <textarea
            ref={textareaRef}
            className={`w-full h-full rounded-md outline-none ${paddingInTextArea}`}
            onChange={handleTextareaChange}
          />
        </div>

        <div
          onClick={() => {
            updateTodaysWorklog();
          }}
          className={` px-4 py-2 rounded-md border border-${bottonColor} bg-${bottonColor} hover:bg-${bottonHoverColor} hover:border-${bottonHoverColor} text-${textColor}
          transition-all duration-150 ease-in shadow-2xl cursor-pointer ${styleSaveButton}`}
        >
          {loading1 ? (
            <div>
              <Spin className="text-white mr-1" /> Auto Saving...
            </div>
          ) : (
            "Save Worklog"
          )}
        </div>
      </div>
    </Spin>
  );
};

export default EmployeeTodaysWorklog;
