import { Form, Input, Select, Spin, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";

import deleteIcon from "../../../../assets/delete.svg";
import noinvitations from "../../../../assets/no/no-invitations.svg";
import axiosInstance from "../../../../utils/axiosInstance";
import notificationShow from "../../../globalComponents/notificationService";
import PopupModal from "../../../globalComponents/popupModal";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import ProfilePictureHead from "../../../globalComponents/profilePictureHead/profilePictureHead";

const AllTeamsMembers = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [businessRoles, setBusinessRoles] = useState<any>([]);
  const [businessPolicies, setBusinessPolicies] = useState<any>([]);
  const [teamMembers, setTeamMembers] = useState<any>([]);
  const [teamMembersDetails, setTeamMembersDetails] = useState<any>([]);
  const [teamInvitationsDetails, setTeamInvitationsDetails] = useState<any>([]);
  const [teamInvitations, setTeamInvitations] = useState<any>([]);
  const [singleInvitation, setSingleInvitation] = useState<any>({});

  const getTeamMembers = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get("/users/all");
      setTeamMembers(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getTeamInvitations = async () => {
    try {
      const response = await axiosInstance.get("/settings/team/invites");
      setTeamInvitations(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTeamMembers();
    getTeamInvitations();
  }, []);

  const getBusinessRoles = async () => {
    try {
      const response = await axiosInstance.get("/settings/roles");
      setBusinessRoles(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const businessRole = businessRoles?.map((role: any) => {
    return { value: role._id, label: role.role };
  });

  const getBusinessPolicies = async () => {
    try {
      const response = await axiosInstance.get("/settings/policies");
      setBusinessPolicies(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const businessPolicy = businessPolicies?.map((policy: any) => {
    return { value: policy._id, label: policy.policy_name };
  });

  const managers = teamMembers?.map((member: any) => {
    return { value: member._id, label: member.full_name };
  });

  const teamMembersColumn: any = [
    {
      title: "S.N",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "User Roles",
      dataIndex: "roles",
      key: "roles",
    },
    {
      title: "Date Added",
      dataIndex: "date_added",
      key: "date_added",
    },
  ];

  useEffect(() => {
    const teamMembersData = teamMembers?.map((member: any, index: number) => {
      return {
        key: member?._id,
        index: index + 1 + ".",
        name: (
          <div
            onClick={() => {
              navigate(`/dashboard/team-members/${member._id}`);
            }}
            className="flex items-center gap-2"
          >
            <ProfilePictureHead
              username={member.full_name}
              profile={member.profile_picture}
              user_id={member._id}
              height="h-8"
              width="w-8"
              showUpdate="hidden"
            />

            <div className="font-semibold text-primaryColor">
              {member?.full_name}
            </div>
            {member.archived && (
              <Tag className="bg-rejectedColor font-semibold text-white">
                Archived
              </Tag>
            )}
          </div>
        ),
        email: member?.email,
        roles: (
          <Tag
            color="#"
            className={`text-white ${
              member?.role_id.role.toLowerCase() === "admin" && "bg-late"
            } ${
              member?.role_id.role.toLowerCase() === "employee" &&
              "bg-approvedColor"
            }  font-semibold`}
          >
            {member?.role_id.role}
          </Tag>
        ),
        date_added: (
          <div className="flex items-center justify-between">
            <div>{moment(member?.createdAt).format("D MMMM, YYYY")}</div>
          </div>
        ),
      };
    });
    setTeamMembersDetails(teamMembersData);
  }, [teamMembers]);

  const deleteTeamInvitations = async () => {
    try {
      const response = await axiosInstance.delete(
        `/settings/team/invite/${singleInvitation._id}`
      );
      getTeamInvitations();
      notificationShow({
        type: "success",
        content: (
          <div>
            Invitation sent to{" "}
            <span className="text-primaryColor font-semibold">
              {singleInvitation.email}
            </span>{" "}
            has been deleted!
          </div>
        ),
      });
      setPopupDeleteInvitationModal(false);
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      notificationShow({
        type: "error",
        message: `${error.response.data.error}`,
      });
      setLoading(false);
    }
  };

  const inviteTeamMembers = async () => {
    try {
      await form.validateFields();
      const formValues = form.getFieldsValue();
      console.log(formValues);
      const response = await axiosInstance.post("/settings/team/invite", {
        email: formValues.email,
        role_id: formValues.role,
        policy_id: formValues.policy,
        manager_ids: formValues.managers,
      });
      getTeamMembers();
      getTeamInvitations();
      notificationShow({
        type: "success",
        content: (
          <div>
            Invitation sent to{" "}
            <span className="text-primaryColor font-semibold">
              {formValues.email}
            </span>
            !
          </div>
        ),
      });
      form.resetFields();
      setPopupInviteMembersModal(false);
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      notificationShow({
        type: "error",
        message: `${error?.response?.data?.error}`,
      });
      setLoading(false);
    }
  };

  const teamMembersInvitationsColumn: any = [
    {
      title: "S.N",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Role Invited To",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Policy Assigned",
      dataIndex: "policy",
      key: "policy",
    },
    {
      title: "Date of Invitations",
      dataIndex: "date_invitation",
      key: "date_invitation",
      width: "30%",
    },
  ];

  useEffect(() => {
    const teamInvitationsData = teamInvitations?.map(
      (invitation: any, index: number) => {
        return {
          key: invitation?._id,
          index: index + 1 + ".",
          email: invitation?.email,
          role: invitation?.role_id.role,
          policy: invitation?.policy_id.policy_name,
          date_invitation: (
            <div className="flex items-center justify-between">
              <div>{moment(invitation?.createdAt).format("D MMMM, YYYY")}</div>
              <div className="flex items-center gap-4">
                <div
                  onClick={() => {
                    setSingleInvitation(invitation);
                    showResendInvitationModal();
                  }}
                  className="px-4 py-2 bg-secondaryBackground rounded-md"
                >
                  Resend Invitation
                </div>
                <img
                  onClick={() => {
                    setSingleInvitation(invitation);
                    showDeleteInvitationModal();
                  }}
                  src={deleteIcon}
                  alt="delete"
                  className="h-4 w-4 cursor-pointer"
                />
              </div>
            </div>
          ),
        };
      }
    );
    setTeamInvitationsDetails(teamInvitationsData);
  }, [teamInvitations]);

  const [popupInviteMembersModal, setPopupInviteMembersModal] = useState(false);

  const showInviteMembersModal = () => {
    getBusinessRoles();
    getBusinessPolicies();
    setPopupInviteMembersModal(true);
    setLoading(false);
  };

  const onCancel = () => {
    setPopupInviteMembersModal(false);
  };

  const onOkay = () => {
    inviteTeamMembers();
  };

  const [popupDeleteInvitationModal, setPopupDeleteInvitationModal] =
    useState(false);

  const showDeleteInvitationModal = () => {
    setPopupDeleteInvitationModal(true);
    setLoading(false);
  };

  const onDeleteInvitationCancel = () => {
    setPopupDeleteInvitationModal(false);
  };

  const onDeleteInvitationOkay = () => {
    deleteTeamInvitations();
  };

  const [popupResendInvitationModal, setPopupResendInvitationModal] =
    useState(false);

  const showResendInvitationModal = () => {
    setPopupResendInvitationModal(true);
    setLoading(false);
  };

  const onResendInvitationCancel = () => {
    setPopupResendInvitationModal(false);
  };

  const onResendInvitationOkay = () => {
    resendInvitations();
  };

  //resend invitations
  const resendInvitations = async () => {
    try {
      const response = await axiosInstance.post(
        `/settings/team/invite/resendEmail?invitation_id=${singleInvitation._id}`
      );
      getTeamMembers();
      notificationShow({
        type: "success",
        content: "Invitation sent Successfully!",
      });
      setPopupResendInvitationModal(false);
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      notificationShow({
        type: "error",
        content: `${error?.response?.data?.error}`,
      });
      setLoading(false);
    }
  };

  return (
    <div className="text-primaryText">
      <div className="pb-6 flex items-center justify-between">
        <div className="text-3xl font-medium ">Team Members</div>
        <div className="flex items-center gap-4 group">
          <div
            onClick={showInviteMembersModal}
            className="flex items-center gap-1 px-4 py-2 cursor-pointer  border border-primaryText hover:border-borderColor hover:text-primaryColor rounded-md"
          >
            <FaPlus /> <span className="font-medium">Invite members</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-8 w-full">
        <Spin spinning={loading}>
          <Table
            className="cursor-pointer p-4 bg-primaryBackground rounded-md"
            size="middle"
            columns={teamMembersColumn}
            dataSource={teamMembersDetails}
            pagination={false}
          />
        </Spin>

        <div className="text-primaryText w-full">
          <div className="pb-6 text-3xl font-medium ">Invitations</div>
          <div className="min-h-[30vh] bg-primaryBackground p-4 rounded-md">
            {teamInvitationsDetails && teamInvitationsDetails.length > 0 ? (
              <Table
                className="cursor-pointer p-4 rounded-md"
                size="middle"
                columns={teamMembersInvitationsColumn}
                dataSource={teamInvitationsDetails}
              />
            ) : (
              <div className="flex flex-col items-center justify-center p-4">
                <img
                  className="h-auto w-64"
                  src={noinvitations}
                  alt="no-invitations"
                />
                <div className="text-2xl font-semibold text-primaryColor">
                  No invitations sent
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {popupInviteMembersModal && (
        <PopupModal
          top="10vh"
          width={550}
          open={popupInviteMembersModal}
          onCancel={onCancel}
          onOkay={onOkay}
          content={
            <div className="flex flex-col items-start gap-6 w-full p-2">
              <div className="text-xl font-semibold">Invite Team Member!</div>
              <div className="w-full">
                <Form layout="vertical" form={form} className="w-full">
                  <Form.Item
                    required
                    className="font-medium"
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Email is required.",
                      },
                    ]}
                  >
                    <Input
                      type="email"
                      className="border border-gray-300 rounded-md w-full py-2 px-4"
                    />
                  </Form.Item>
                  <Form.Item
                    className="font-medium"
                    required
                    name="role"
                    label={<div className="">Role</div>}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Member's role is required.",
                      },
                    ]}
                  >
                    <Select
                      bordered={false}
                      className="border border-gray-300 rounded-md h-10 px-1"
                      options={businessRole}
                    />
                  </Form.Item>
                  <Form.Item
                    required
                    className="font-medium"
                    name="policy"
                    label={<div className="">Policy</div>}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Policy is required.",
                      },
                    ]}
                  >
                    <Select
                      bordered={false}
                      className="border border-gray-300 rounded-md h-10 px-1"
                      options={businessPolicy}
                    />
                  </Form.Item>
                  <Form.Item
                    required
                    className="font-medium"
                    name="managers"
                    label={<div className="">Manager(s)</div>}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Manager is required.",
                      },
                    ]}
                  >
                    <Select
                      bordered={false}
                      className="border border-gray-300 rounded-md h-10 px-1"
                      mode="multiple"
                      allowClear
                      options={managers}
                    />
                  </Form.Item>
                </Form>
              </div>
            </div>
          }
          button1="Cancel"
          button2="Invite Member"
          hideButton1=""
          hideButton2=""
          button1Color="primaryColor"
          button2Color="white"
          button1BgColor="secondaryColor"
          button2BgColor="primaryColor"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}

      {popupDeleteInvitationModal && singleInvitation && (
        <PopupModal
          top="20vh"
          width={500}
          open={popupDeleteInvitationModal}
          onCancel={onDeleteInvitationCancel}
          onOkay={onDeleteInvitationOkay}
          content={
            <div className="flex flex-col items-start gap-6 w-full p-2">
              <div className="text-xl font-semibold">
                Delete Team Invitation !
              </div>
              <div className="flex flex-col gap-2">
                <div>
                  You're about to delete the inviations to your team for{" "}
                  <span className=" text-red-500 font-semibold">
                    {singleInvitation.email}{" "}
                  </span>
                </div>
              </div>
            </div>
          }
          button1="Cancel"
          button2="Delete Invitations"
          hideButton1=""
          hideButton2=""
          button1Color="primaryColor"
          button2Color="white"
          button1BgColor="secondaryColor"
          button2BgColor="red-500"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      {popupResendInvitationModal && singleInvitation && (
        <PopupModal
          top="20vh"
          width={500}
          open={popupResendInvitationModal}
          onCancel={onResendInvitationCancel}
          onOkay={onResendInvitationOkay}
          content={
            <div className="flex flex-col items-start gap-6 w-full p-2">
              <div className="text-xl font-semibold">
                Resend Team Invitation !
              </div>
              <div className="flex flex-col gap-2">
                <div>
                  Are you sure you want to resend team invitations for{" "}
                  <span className="text-md text-red-500 font-semibold">
                    {singleInvitation.email}{" "}
                  </span>
                </div>
              </div>
            </div>
          }
          button1="Cancel"
          button2="Resend Invitations"
          hideButton1=""
          hideButton2=""
          button1Color="primaryColor"
          button2Color="white"
          button1BgColor="secondaryColor"
          button2BgColor="red-500"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}
    </div>
  );
};

export default AllTeamsMembers;
