import { Form, Input, Spin } from "antd";
import axiosInstance from "../../../utils/axiosInstance";
import { useState } from "react";
import NavbarChibiLogo from "../../globalComponents/navbar-chibi-logo";

const VerfiyEmail = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");
  const [error, setError] = useState<string>("");

  const verifyEmail = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const formValues = form.getFieldsValue();
      const response = await axiosInstance.post("/users/checkOTP", {
        email: email,
        otp: formValues.otp,
      });
      window.location.href = `/create-account?email=${email}&code=${formValues.otp}`;
      form.resetFields();
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setError(error?.response?.data?.error);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-[100vh] gap-4 bg-secondaryBackground text-primaryText">
      <NavbarChibiLogo />
      <div className="flex items-center justify-center  p-12 rounded-md bg-primaryBackground">
        <div className="w-full md:w-[25vw] flex flex-col">
          <div className="flex flex-col items-center gap-4 text-center mb-8">
            <div className="font-semibold text-2xl md:text-4xl ">
              Email Verification
            </div>
            <div className="text-center">
              We have sent a verification code to your email address -{" "}
              <span className="font-semibold text-primaryColor">{email}</span>
            </div>
          </div>
          <Form autoComplete="off" size="large" layout="vertical" form={form}>
            <Form.Item
              required
              name="otp"
              label={<div className="font-semibold">Verification Code</div>}
              rules={[
                {
                  required: true,
                  message: "Verification code is required.",
                },
              ]}
              className="w-full"
            >
              <Input
                type="number"
                maxLength={5}
                minLength={5}
                className="border border-gray-300 rounded-md w-full py-2 px-4"
              />
            </Form.Item>
          </Form>
          {error && (
            <div className="text-center text-sm text-red-500">{error}</div>
          )}
          <Spin spinning={loading}>
            <button
              onClick={verifyEmail}
              className="bg-primaryColor text-white font-semibold px-4 py-2 rounded-md w-full"
            >
              Continue
            </button>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default VerfiyEmail;
