import React, { useEffect, useState } from "react";
import { Calendar, Form, Input } from "antd";
import PopupModal from "../../../globalComponents/popupModal";
import axiosInstance from "../../../../utils/axiosInstance";
import notificationShow from "../../../globalComponents/notificationService";
import dayjs from "dayjs";
import { standard_date_only_format_backend } from "../../../../utils/dateAndTimeFormatter";
const { TextArea } = Input;

const SettingsHoliday = () => {
  const [form] = Form.useForm();
  const [selectedDate, setSelectedDate] = useState<any>("");
  const [holidays, setHolidays] = useState<any>([]);
  const [deleteHoliday, setDeleteHoliday] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const getHolidays = async () => {
    try {
      const response = await axiosInstance.get("/settings/holidays");
      setHolidays(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getHolidays();
  }, []);

  const dateCellRender = (value: any) => {
    const formattedDate = value.format(standard_date_only_format_backend);

    const matchingHoliday = holidays.find(
      (holiday: any) =>
        dayjs(holiday.holiday_date).format(
          standard_date_only_format_backend
        ) === formattedDate
    );

    const isHolidayDate = matchingHoliday !== undefined;

    if (isHolidayDate) {
      return (
        <div className="flex flex-col items-center justify-center h-full">
          <div className="text-2xl font-semibold text-rejectedColor">
            {value.format("DD")}
          </div>
          {isHolidayDate && (
            <div className="text-center text-rejectedColor font-semibold text-[12px]">
              {matchingHoliday.holiday_description}
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="h-full flex flex-col items-center justify-center text-2xl font-semibold">
        {value.format("DD")}
      </div>
    );
  };

  const addNewHoliday = async () => {
    try {
      await form.validateFields();
      const formValues = form.getFieldsValue();

      const matchingHoliday = holidays.reduce((result: any, holiday: any) => {
        if (
          dayjs(holiday.holiday_date).format(
            standard_date_only_format_backend
          ) === selectedDate?.format(standard_date_only_format_backend)
        ) {
          return holiday;
        }
        return result;
      }, null);

      if (matchingHoliday) {
        const response = await axiosInstance.post("/settings/holidays", {
          _id: matchingHoliday._id,
          holiday_date: selectedDate,
          holiday_description: formValues.holiday_description,
        });
        notificationShow({
          type: "success",
          message: "Holiday Updated Successfully!",
        });
      } else {
        const response = await axiosInstance.post("/settings/holidays", {
          holiday_date: selectedDate,
          holiday_description: formValues.holiday_description,
        });
        notificationShow({
          type: "success",
          content: "New Holiday Added Successfully!",
        });
      }
      getHolidays();
      form.resetFields();
      setPopupModal(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const [popupModal, setPopupModal] = useState(false);
  const showAddHolidayModal = (date: any) => {
    setLoading(false);
    const matchingHoliday = holidays.reduce((result: any, holiday: any) => {
      if (
        dayjs(holiday.holiday_date).format(
          standard_date_only_format_backend
        ) === date?.format(standard_date_only_format_backend)
      ) {
        setDeleteHoliday(true);
        return holiday;
      }
      return result;
    }, null);

    if (matchingHoliday) {
      form.setFieldsValue({
        holiday_date: dayjs(matchingHoliday.holiday_date),
        holiday_description: matchingHoliday.holiday_description,
      });
    } else {
      form.resetFields();
    }
    const dayOfWeek = dayjs(date).day();
    if (
      dayOfWeek === 0 ||
      dayOfWeek === 1 ||
      dayOfWeek === 2 ||
      dayOfWeek === 3 ||
      dayOfWeek === 4 ||
      dayOfWeek === 5 ||
      dayOfWeek === 6
    ) {
      setPopupModal(true);
    }
  };

  const onCancel = () => {
    setPopupModal(false);
    setDeleteHoliday(false);
  };

  const onOkay = () => {
    addNewHoliday();
  };

  const deleteExistingHoliday = async () => {
    const matchingHoliday = holidays.reduce((result: any, holiday: any) => {
      if (
        dayjs(holiday.holiday_date).format(
          standard_date_only_format_backend
        ) === selectedDate?.format(standard_date_only_format_backend)
      ) {
        return holiday;
      }
      return result;
    }, null);
    try {
      const response = await axiosInstance.delete(
        `/settings/holidays/${matchingHoliday._id}`
      );
      notificationShow({
        type: "success",
        content: "Holiday Deleted Successfully!",
      });
      setConfirmationModal(false);
      setPopupModal(false);
      getHolidays();
    } catch (error) {
      console.log(error);
    }
  };

  const [confirmationModal, setConfirmationModal] = useState(false);
  const showModel = async () => {
    setLoading(false);
    setConfirmationModal(true);
  };
  const onConfirmationModalCancel = () => {
    setConfirmationModal(false);
  };
  const onConfirmationModalOkay = () => {
    deleteExistingHoliday();
  };

  return (
    <div className="text-primaryText">
      <div className="pb-6 flex items-center justify-between ">
        <div className=" text-3xl font-medium">Holidays</div>
      </div>
      <div className="p-4 rounded-md bg-primaryBackground">
        <Calendar
          className="text-primaryText"
          onSelect={(date: any) => {
            showAddHolidayModal(date);
            setSelectedDate(dayjs(date, { utc: true }));
          }}
          cellRender={dateCellRender}
        />
      </div>
      {popupModal && (
        <PopupModal
          top="20vh"
          width={550}
          open={popupModal}
          closeIcon={false}
          onCancel={onCancel}
          onOkay={onOkay}
          content={
            <div className="flex flex-col items-start gap-6 w-full p-2">
              <div className="flex items-center gap-4">
                <div className="text-xl font-semibold">Add a new Holiday !</div>
                {deleteHoliday && (
                  <div
                    onClick={showModel}
                    className="text-[12px] px-2 py-1 rounded-md text-red-400 border border-red-400 cursor-pointer"
                  >
                    Delete This Holiday
                  </div>
                )}
              </div>
              <div className="w-full">
                <Form
                  layout="vertical"
                  form={form}
                  className="flex flex-col w-full"
                >
                  <Form.Item
                    className="font-medium"
                    required
                    label="Holiday Date"
                    name="holiday_date"
                  >
                    <Input
                      defaultValue={selectedDate}
                      value={selectedDate}
                      disabled
                      className="border border-gray-300 rounded-md w-full py-2 px-4"
                    />
                  </Form.Item>
                  <Form.Item
                    required
                    className="font-medium"
                    label="Holiday Description"
                    name="holiday_description"
                    rules={[
                      {
                        required: true,
                        message: "Holiday Description is required.",
                      },
                    ]}
                  >
                    <TextArea
                      maxLength={200}
                      rows={4}
                      className="border border-gray-300 rounded-md w-full py-2 px-4"
                    />
                  </Form.Item>
                </Form>
              </div>
            </div>
          }
          button1="Cancel"
          button2="Add Holiday"
          hideButton1=""
          hideButton2=""
          button1Color="white"
          button2Color="white"
          button1BgColor="rejectedColor"
          button2BgColor="primaryColor"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}

      {confirmationModal && (
        <PopupModal
          width={500}
          top="20vh"
          open={confirmationModal}
          onCancel={onConfirmationModalCancel}
          onOkay={onConfirmationModalOkay}
          content={
            <div className="flex flex-col items-start gap-8 p-2">
              <div className="text-xl font-medium">Delete Confirmation!</div>
              <div>Are you sure you want delete this holiday?</div>
            </div>
          }
          button1="Cancel"
          button2="Delete"
          hideButton1=""
          hideButton2=""
          button1Color="primaryColor"
          button2Color="white"
          button1BgColor="secondaryColor"
          button2BgColor="rejectedColor"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}
    </div>
  );
};

export default SettingsHoliday;
