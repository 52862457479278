export const TypesOfIds = [
  {
    index: 1,
    id_type: "Citizenship ID",
  },
  {
    index: 2,
    id_type: "National ID",
  },
  {
    index: 3,
    id_type: "Driver's License",
  },
  {
    index: 4,
    id_type: "Passport ID",
  },
  {
    index: 5,
    id_type: "Social Security Number",
  },
  {
    index: 6,
    id_type: "Voter ID Card",
  },
  {
    index: 7,
    id_type: "Biometric ID",
  },
];
