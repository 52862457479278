import React, { useState, useEffect } from "react";
import { Calendar, Spin } from "antd";
import decodeAccessToken from "../../../../utils/decodedAccessToken";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../../utils/axiosInstance";
import {
  standard_date_only_format_backend,
  standard_date_only_format_frontend,
} from "../../../../utils/dateAndTimeFormatter";
import dayjs from "dayjs";
import PopupModal from "../../popupModal";

const WorklogCalender = () => {
  const accessToken = localStorage.getItem("accessToken");
  const { _id }: any = decodeAccessToken(accessToken);
  const { user_id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [holidays, setHolidays] = useState<any>([]);
  const [worklogs, setWorkLogs] = useState<any>([]);
  const [singleWorklog, setSingleWorklog] = useState<any>([]);
  const [selectedDate, setSelectedDate] = useState<any>("");

  const getWorkLogs = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `/worklog?user_id=${user_id ? user_id : _id}`
      );
      setWorkLogs(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getHolidays = async () => {
    try {
      const response = await axiosInstance.get("/settings/holidays");
      setHolidays(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getWorkLogs();
  }, []);

  useEffect(() => {
    getHolidays();
  }, []);

  const dateCellRender = (value: any) => {
    const formattedDate = value.format(standard_date_only_format_backend);

    const matchingHoliday = holidays.find(
      (holiday: any) =>
        dayjs(holiday.holiday_date).format(
          standard_date_only_format_backend
        ) === formattedDate
    );

    const isHolidayDate = matchingHoliday !== undefined;

    const matchingWorklogs = worklogs.filter(
      (worklog: any) =>
        dayjs(worklog.worklog_day).format(standard_date_only_format_backend) ===
        formattedDate
    );

    if (matchingWorklogs.length > 0) {
      return (
        <div className="bg-approvedColor flex flex-col items-center justify-center h-full">
          <div className="text-2xl font-semibold text-white">
            {value.format("DD")}
          </div>
          <div className="text-[12px] font-semibold text-white">
            Worklog Available
          </div>
        </div>
      );
    } else {
      if (isHolidayDate) {
        return (
          <div className="flex flex-col items-center justify-center h-full">
            <div className="text-2xl font-semibold text-rejectedColor">
              {value.format("DD")}
            </div>
            {isHolidayDate && (
              <div className="text-center text-rejectedColor font-semibold text-[12px]">
                {matchingHoliday.holiday_description}
              </div>
            )}
          </div>
        );
      } else {
        return (
          <div className="flex flex-col items-center justify-center h-full">
            <div className="text-2xl font-semibold">{value.format("DD")}</div>
          </div>
        );
      }
    }
  };

  const [popupModal, setPopupModal] = useState(false);
  const showAttendanceDetailsModal = (date: any) => {
    setLoading(false);

    const matchingDate = worklogs.reduce((result: any, worklog: any) => {
      if (
        dayjs(worklog.worklog_day).format(standard_date_only_format_backend) ===
        date?.format(standard_date_only_format_backend)
      ) {
        return worklog;
      }
      return result;
    }, null);

    if (matchingDate) {
      setSelectedDate(
        dayjs(matchingDate.worklog_day).format(
          standard_date_only_format_backend
        )
      );
      setSingleWorklog(matchingDate.log);
      setPopupModal(true);
    }
  };

  const onCancel = () => {
    setLoading(false);
    setPopupModal(false);
  };

  const onOkay = () => {
    setPopupModal(false);
  };

  return (
    <div>
      <Spin spinning={loading}>
        <Calendar
          cellRender={dateCellRender}
          onSelect={(date: any) => {
            showAttendanceDetailsModal(date);
          }}
        />
      </Spin>
      {popupModal && (
        <PopupModal
          top="17vh"
          width={600}
          open={popupModal}
          closeIcon={false}
          onCancel={onCancel}
          onOkay={onOkay}
          content={
            <div className="flex flex-col items-start gap-8 w-full p-2">
              <div className="flex items-center gap-4 w-full">
                {selectedDate && (
                  <div className="flex  flex-col gap-4 w-full">
                    <div className=" text-xl font-semibold">
                      Worklog Details of{" "}
                      {dayjs(selectedDate).format(
                        standard_date_only_format_frontend
                      )}
                      !
                    </div>
                    <div className="w-full h-[40vh] rounded-md outline-none p-4 bg-secondaryBackground overflow-auto">
                      {singleWorklog
                        .split("\n")
                        .map((message: any, index: any) => (
                          <div key={index}>{message.trim()}</div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          }
          button1="hidden"
          button2="hidden"
          hideButton1="hidden"
          hideButton2="hidden"
          button1Color="white"
          button2Color="white"
          button1BgColor="red-500"
          button2BgColor="primaryColor"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}
    </div>
  );
};

export default WorklogCalender;
