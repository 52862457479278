import React, { useState, useEffect } from "react";
import axiosInstance from "../../../../utils/axiosInstance";
import notificationShow from "../../notificationService";
import { Spin, Tag } from "antd";
import dayjs from "dayjs";
import { standard_time_only_format_frontend } from "../../../../utils/dateAndTimeFormatter";

import clockoutin from "../../../../assets/clock-out-in.svg";

const ClockInOut = ({ mainStyles, displayClock, showTimeStyles }: any) => {
  const [isHoliday, setIsHoliday] = useState<boolean>(false);
  const [clockData, setClockData] = useState<any>([]);
  const [loading1, setLoading1] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const checkClockInStatus = async () => {
    try {
      setLoading1(true);
      const response = await axiosInstance.get(
        "attendance/check-clock-in-status"
      );
      setClockData(response.data.clock_data);
      setIsHoliday(response.data.is_holiday);
      setLoading1(false);
    } catch (error) {
      console.log(error);
      setLoading1(false);
    }
  };

  useEffect(() => {
    checkClockInStatus();
  }, []);

  const clockIn = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post("attendance/clockin");
      checkClockInStatus();
      setLoading(false);
    } catch (error: any) {
      notificationShow({
        type: "error",
        content: error?.response?.data?.error,
      });
      setLoading(false);
    }
  };

  const clockOut = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post("attendance/clockout");
      checkClockInStatus();
      setLoading(false);
    } catch (error: any) {
      notificationShow({
        type: "error",
        content: error?.response?.data?.error,
      });
      setLoading(false);
    }
  };
  return (
    <Spin spinning={loading1} className="">
      {isHoliday ? (
        <img
          src={clockoutin}
          alt="clockoutin"
          className={`${displayClock} w-32 h-32 text-primaryColor p-4`}
        />
      ) : (
        <div style={{ ...mainStyles }} className="">
          <div className={`${displayClock} w-40 h-40 p-4 mx-auto`}>
            <img
              src={clockoutin}
              alt="clockoutin"
              className={`${displayClock}  text-primaryColor h-full w-full`}
            />
          </div>

          {
            <div style={{ ...showTimeStyles }}>
              {clockData?.clock_in_time &&
                (clockData?.approval_status.toLowerCase() === "approved" ||
                  clockData?.approval_status.toLowerCase() === "pending") && (
                  <div className=" flex items-center gap-2">
                    <div className="">Clocked-In</div>
                    <div
                      className={`${
                        clockData?.attendance_clockin_status.toLowerCase() ===
                        "on time"
                          ? "text-approvedColor"
                          : "text-late"
                      } font-bold `}
                    >
                      {clockData?.clock_in_time
                        ? dayjs(clockData.clock_in_time).format(
                            standard_time_only_format_frontend
                          )
                        : "-"}
                    </div>

                    <Tag
                      color="#"
                      className={`${displayClock} ${
                        clockData?.attendance_clockin_status.toLowerCase() ===
                        "on time"
                          ? "bg-approvedColor"
                          : "bg-late"
                      } font-semibold ml-auto`}
                    >
                      {clockData?.attendance_clockin_status}
                    </Tag>
                  </div>
                )}

              {clockData?.clock_out_time &&
                clockData?.approval_status.toLowerCase() === "approved" && (
                  <div className="flex items-center gap-2">
                    <div className="">Clocked-Out</div>
                    <div
                      className={`${
                        clockData?.attendance_clockout_status.toLowerCase() ===
                        "full time"
                          ? "text-approvedColor"
                          : "text-late"
                      } font-bold `}
                    >
                      {clockData?.clock_out_time
                        ? dayjs(clockData.clock_out_time).format(
                            standard_time_only_format_frontend
                          )
                        : "-"}
                    </div>
                    <Tag
                      color="#"
                      className={`${displayClock} ${
                        clockData?.attendance_clockout_status.toLowerCase() ===
                        "full time"
                          ? "bg-approvedColor"
                          : "bg-late"
                      } font-semibold ml-auto`}
                    >
                      {clockData?.attendance_clockout_status}
                    </Tag>
                  </div>
                )}
            </div>
          }
          <div className="flex flex-col items-center justify-center">
            {!clockData?.clock_in_time && !clockData?.clock_out_time && (
              <Spin spinning={loading}>
                <div
                  onClick={clockIn}
                  className="px-8 py-2 rounded-md bg-primaryColor border border-primaryColor text-white cursor-pointer"
                >
                  Clock In
                </div>
              </Spin>
            )}

            {clockData?.clock_in_time && !clockData?.clock_out_time && (
              <Spin spinning={loading}>
                <div
                  onClick={clockOut}
                  className="px-6 py-2 rounded-md  bg-rejectedColor border border-rejectedColor text-white cursor-pointer"
                >
                  Clock Out
                </div>
              </Spin>
            )}
          </div>
        </div>
      )}
    </Spin>
  );
};

export default ClockInOut;
