import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../../utils/axiosInstance";
import { Form, Input, Spin, Tabs, Tag } from "antd";
import edit from "../../../../../assets/edit.svg";
import { standard_date_only_format_frontend } from "../../../../../utils/dateAndTimeFormatter";
import dayjs from "dayjs";
import { BsPlusLg } from "react-icons/bs";
import TimeoffRequests from "./timeoff-request";
import AttendanceCalender from "../../../../globalComponents/pageComponents/attendance/attendance-calender";
import ActionsSingleTeamMembers from "./actions-single-team-members";
import PopupModal from "../../../../globalComponents/popupModal";
import notificationShow from "../../../../globalComponents/notificationService";
import SingleMemberPolicyDetails from "./single-member-policy-details";
import WorklogCalender from "../../../../globalComponents/pageComponents/worklog/worklog-calender";
import ProfilePictureHead from "../../../../globalComponents/profilePictureHead/profilePictureHead";
import Payroll from "./payroll";
import ShowLableValueForDetails from "../../../../globalComponents/showLabelValueForDetails";

const SingleTeamMember = () => {
  const { user_id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [userData, setUserData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [updateEmploymentDetailsModal, setUpdateEmploymentDetailsModal] =
    useState(false);

  const getUserData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/users/all?_id=${user_id}`);
      setUserData(response.data.data[0]);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserData();
  }, [user_id]);

  const showUpdateEmployementDetailsModal = () => {
    setLoading(false);
    setUpdateEmploymentDetailsModal(true);
  };

  const updateEmploymentDetails = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const formValues = form.getFieldsValue();
      const response = await axiosInstance.patch(`/users`, {
        user_id: user_id,
        position: formValues.position,
      });
      getUserData();
      setUpdateEmploymentDetailsModal(false);
      notificationShow({
        type: "success",
        content: "Employee position updated successfully!",
      });
      setLoading(false);
      form.resetFields();
    } catch (error: any) {
      setLoading(false);
    }
  };

  const onUpdateEmploymentDetailsModalCancel = () => {
    setUpdateEmploymentDetailsModal(false);
  };
  const onUpdateEmploymentDetailsModalOkay = () => {
    updateEmploymentDetails();
  };

  return (
    <div>
      <Spin spinning={loading}>
        <div className="flex flex-col gap-6 text-primaryText">
          <div className="flex items-center gap-1">
            <div
              onClick={() => {
                navigate("/dashboard/team-members");
              }}
              className="text-secondaryText cursor-pointer"
            >
              Team Members /
            </div>
            <div className="">{userData?.full_name}</div>
          </div>
          <div className="p-4 bg-primaryBackground rounded-md flex flex-col gap-4">
            <div className="flex items-center gap-4">
              <ProfilePictureHead
                username={userData.full_name}
                profile={userData.profile_picture}
                user_id={userData._id}
                height="h-24"
                width="w-24"
                showUpdate="hidden"
                fontSize="text-xl"
              />
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-2">
                  <div className="text-2xl font-semibold">
                    {userData?.full_name}
                  </div>
                  {userData?.archived && (
                    <Tag className="bg-rejectedColor font-semibold text-white">
                      Archived
                    </Tag>
                  )}
                </div>
                <div className="text-secondaryText">{userData?.email}</div>
              </div>
            </div>
            <Tabs
              defaultActiveKey="1"
              tabPosition="top"
              style={{ height: "auto" }}
              items={[
                {
                  label: "Personal Info",
                  key: "1",
                  children: (
                    <div className="flex flex-col gap-8">
                      <div className="flex flex-col gap-4">
                        <div className="text-xl font-medium">Personal Info</div>
                        <ShowLableValueForDetails
                          details={[
                            {
                              label: "Preferred Full Name",
                              value: userData?.full_name
                                ? userData.full_name
                                : "-",
                            },
                            {
                              label: "Gender",
                              value: userData?.gender ? userData?.gender : "-",
                            },
                            {
                              label: "Bio",
                              value: userData?.bio ? userData?.bio : "-",
                            },
                            {
                              label: "Date of Birth",
                              value: userData?.dob
                                ? dayjs(userData?.dob).format(
                                    standard_date_only_format_frontend
                                  )
                                : "-",
                            },
                          ]}
                        />
                      </div>
                      <div className="flex flex-col gap-4 ">
                        <div className="text-xl font-medium">
                          Contact Details
                        </div>
                        <ShowLableValueForDetails
                          details={[
                            {
                              label: "Personal Email",
                              value: userData?.personal_email
                                ? userData?.personal_email
                                : "-",
                            },
                            {
                              label: "Personal Phone",
                              value: userData?.personal_phone
                                ? userData?.personal_phone
                                : "-",
                            },
                            {
                              label: "Current Address",
                              value: userData?.current_address
                                ? userData?.current_address
                                : "-",
                            },
                            {
                              label: "Permanent Address",
                              value: userData?.permanent_address
                                ? userData?.permanent_address
                                : "-",
                            },
                            {
                              label: "ID Type",
                              value: userData?.id_type
                                ? userData?.id_type
                                : "-",
                            },
                            {
                              label: "ID Number",
                              value: userData?.id_number
                                ? userData?.id_number
                                : "-",
                            },
                            {
                              label: "PAN Number",
                              value: userData?.pan_number
                                ? userData?.pan_number
                                : "-",
                            },
                          ]}
                        />
                      </div>
                    </div>
                  ),
                },

                {
                  label: "Employment Details",
                  key: "2",
                  children: (
                    <div className="flex flex-col gap-8">
                      <div className="flex flex-col gap-4">
                        <div className="flex items-center justify-between w-full">
                          <div className="text-xl font-medium">
                            Employment Details
                          </div>
                          <div
                            onClick={() => {
                              showUpdateEmployementDetailsModal();
                            }}
                            className="flex items-center gap-1 cursor-pointer"
                          >
                            <img src={edit} alt="edit" />
                            <div className="text-primaryColor">Edit</div>
                          </div>
                        </div>
                        <ShowLableValueForDetails
                          details={[
                            {
                              label: "Current Position",
                              value: userData?.position
                                ? userData?.position
                                : "-",
                            },
                          ]}
                        />
                      </div>
                      <div className="flex flex-col gap-4">
                        <div className="text-xl font-medium">
                          Contract Details
                        </div>
                        <div className="w-full min-h-[20vh] bg-gray-100">
                          {userData?.contract_details ? (
                            <div className="flex items-center gap-4 py-4"></div>
                          ) : (
                            <div className="h-[20vh] flex items-center justify-center">
                              <div className="text-primaryColor font-semibold flex items-center justify-center gap-1 cursor-pointer">
                                <BsPlusLg className="h-4 w-4" />
                                <span> Add Contract Details</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col gap-4">
                        <div className="text-xl font-medium">Bank Details</div>
                        <div className="w-full min-h-[20vh] bg-gray-100 py-4 rounded-md">
                          {userData?.bank_name ||
                          userData?.bank_branch ||
                          userData?.bank_account_name ||
                          userData?.bank_account_number ? (
                            <ShowLableValueForDetails
                              details={[
                                {
                                  label: "Bank Name",
                                  value: userData?.bank_name
                                    ? userData?.bank_name
                                    : "-",
                                },
                                {
                                  label: "Branch Name",
                                  value: userData?.bank_branch
                                    ? userData?.bank_branch
                                    : "-",
                                },
                                {
                                  label: "Account Name",
                                  value: userData?.bank_account_name
                                    ? userData?.bank_account_name
                                    : "-",
                                },
                                {
                                  label: "Account Number",
                                  value: userData?.bank_account_number
                                    ? userData?.bank_account_number
                                    : "-",
                                },
                              ]}
                            />
                          ) : (
                            <div className="h-[20vh] flex items-center justify-center">
                              No Bank Details
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ),
                },
                {
                  label: "Payroll",
                  key: "3",
                  children: <Payroll />,
                },
                {
                  label: "Tracking Policy",
                  key: "4",
                  children: (
                    <SingleMemberPolicyDetails
                      getUserData={getUserData}
                      userId={userData._id}
                      policyId={userData.policy_id}
                    />
                  ),
                },
                {
                  label: "Worklogs",
                  key: "5",
                  children: <WorklogCalender />,
                },
                {
                  label: "Timeoffs",
                  key: "6",
                  children: <TimeoffRequests user_id={user_id} />,
                },
                {
                  label: "Attendance",
                  key: "7",
                  children: <AttendanceCalender />,
                },
                {
                  label: "Actions",
                  key: "8",
                  children: (
                    <ActionsSingleTeamMembers
                      userData={userData}
                      getUserData={getUserData}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </Spin>
      {updateEmploymentDetailsModal && (
        <PopupModal
          top="20vh"
          width={500}
          open={updateEmploymentDetailsModal}
          onCancel={onUpdateEmploymentDetailsModalCancel}
          onOkay={onUpdateEmploymentDetailsModalOkay}
          content={
            <div className="flex flex-col items-start gap-6 w-full p-2">
              <div className="text-xl font-semibold">
                Update Employee's Position!
              </div>
              <div className="w-full">
                <Form
                  layout="vertical"
                  form={form}
                  className="flex flex-col w-full"
                  initialValues={{
                    position: userData.position,
                  }}
                >
                  <Form.Item
                    required
                    className="font-medium"
                    label="Position"
                    name="position"
                    rules={[
                      {
                        required: true,
                        message: "Position is required.",
                      },
                    ]}
                  >
                    <Input className="border border-gray-300 rounded-md w-full py-2 px-4" />
                  </Form.Item>
                </Form>
              </div>
            </div>
          }
          button1="Cancel"
          button2="Update Position"
          hideButton1=""
          hideButton2=""
          button1Color="white"
          button2Color="white"
          button1BgColor="rejectedColor"
          button2BgColor="primaryColor"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}
    </div>
  );
};

export default SingleTeamMember;
