import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../../../utils/axiosInstance";
import dayjs from "dayjs";
import {
  standard_date_only_format_backend,
  standard_date_only_format_frontend,
} from "../../../../../utils/dateAndTimeFormatter";
import {
  DatePicker,
  Drawer,
  Dropdown,
  Form,
  Input,
  Select,
  Spin,
  Tag,
} from "antd";
import nopayrollhistoryimage from "../../../../../assets/no/no-payroll.svg";
import PopupModal from "../../../../globalComponents/popupModal";
import notificationShow from "../../../../globalComponents/notificationService";
import { HiDotsVertical } from "react-icons/hi";

const Payroll = () => {
  const { user_id } = useParams();
  const [form] = Form.useForm();
  const [payroll, setPayroll] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [payrollPupupboxTitle, setPayrollPopupboxTitle] = useState<string>("");
  const [loadingSalaryCalculation, setLoadingSalaryCalculation] =
    useState<boolean>(false);
  const [popupAddToPayrollModal, setPopupAddToPayrollModal] = useState(false);
  const [error, setError] = useState<string>("");
  const [openSalaryCalculationDrawer, setOpenSalaryCalculationDrawer] =
    useState(false);
  const [totalPay, setTotalPay] = useState<number>(0);
  const [salaryDetails, setSalaryDetails] = useState<any>([]);
  const [startDateOfSalaryGeneration, setStartDateOfSalaryGeneration] =
    useState<string>("");
  const [endDateOfSalaryGeneration, setEndDateOfSalaryGeneration] =
    useState<string>("");

  const getPayroll = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`payroll?user_id=${user_id}`);
      setPayroll(response.data.data);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPayroll();
  }, [user_id]);

  const addToPayroll = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const formValues = form.getFieldsValue();
      const response = await axiosInstance.post("/payroll", {
        user_id: user_id,
        pay_amount: formValues.pay_amount,
        pay_frequency: formValues.pay_frequency,
        payroll_start_date: dayjs(formValues.payroll_start_date).format(
          standard_date_only_format_backend
        ),
      });
      getPayroll();
      notificationShow({
        type: "success",
        content: `Payroll created Successfully!`,
      });
      form.resetFields();
      setPopupAddToPayrollModal(false);
      setLoading(false);
      setError("");
    } catch (error: any) {
      setError(error?.response?.data?.error);
    }
  };

  const showAddToPayrollModal = () => {
    setPopupAddToPayrollModal(true);
    setLoading(false);
  };

  const onAddToPayrollCancel = () => {
    setPopupAddToPayrollModal(false);
  };

  const onAddToPayrollOkay = () => {
    addToPayroll();
  };

  const calculateSalary = async () => {
    try {
      setLoadingSalaryCalculation(true);
      await form.validateFields();
      const formValues = form.getFieldsValue();
      const selectedMonthStartDate = dayjs()
        .year(formValues.year)
        .month(months.indexOf(formValues.month))
        .date(1)
        .format(standard_date_only_format_backend);

      const selectedMonthEndDate = dayjs(selectedMonthStartDate)
        .endOf("month")
        .format(standard_date_only_format_backend);

      setStartDateOfSalaryGeneration(selectedMonthStartDate);
      setEndDateOfSalaryGeneration(selectedMonthEndDate);
      const response = await axiosInstance.post("payroll/salaryCalculate", {
        user_id: user_id,
        start_date_of_generation: selectedMonthStartDate,
        end_date_of_generation: selectedMonthEndDate,
      });
      setSalaryDetails(response.data.all_payrolls);
      setTotalPay(response.data.total_amount);
      notificationShow({
        type: "success",
        content: "Salary calculated Successfully!",
      });
      setLoadingSalaryCalculation(false);
    } catch (error: any) {
      console.log(error);
      setLoadingSalaryCalculation(false);
    }
  };

  const showSalaryCalculationDrawer = () => {
    setOpenSalaryCalculationDrawer(true);
  };

  const onClose = () => {
    setOpenSalaryCalculationDrawer(false);
  };

  const generatePayslip = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post("payslip/generatePaySlip", {
        user_id: user_id,
        start_date_of_generation: startDateOfSalaryGeneration,
        end_date_of_generation: endDateOfSalaryGeneration,
      });
      notificationShow({
        type: "success",
        content: "Payslip generated Successfully!",
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const years = Array.from({ length: 8 }, (_, index) => 2023 + index);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthOptions = months.map((month) => ({
    value: month,
    label: month,
  }));

  const yearOptions = years.map((year) => ({
    value: year,
    label: year,
  }));

  const items: any = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            showAddToPayrollModal();
          }}
          className="flex items-center gap-2 px-2"
        >
          <div>Update Payroll</div>
        </div>
      ),
    },
  ];

  return (
    <div className="flex flex-col gap-8 text-primaryText">
      <div className="flex w-full ml-auto">
        {payroll.length > 0 && payroll && (
          <div
            onClick={() => {
              showSalaryCalculationDrawer();
            }}
            className="ml-auto bg-primaryColor px-4 py-2 rounded-md cursor-pointer text-white"
          >
            Calculate Salary
          </div>
        )}
      </div>
      <Spin spinning={loading}>
        {payroll.length > 0 && payroll ? (
          <div className="flex flex-col gap-8">
            {payroll.map((payroll: any, index: number) => {
              return (
                <div
                  key={index}
                  className="p-6 flex flex-col gap-6 border-2 rounded-md bg-primaryBackground"
                >
                  <div className="flex flex-col gap-6">
                    <div className="flex items-center justify-between gap-4 w-full">
                      <div>
                        {payroll.is_expired ? (
                          <div className="bg-red-100  text-rejectedColor font-semibold px-4 py-2 border-2 border-rejectedColor rounded-full">
                            Payroll Expired
                          </div>
                        ) : (
                          <div className="bg-green-100  text-approvedColor font-semibold px-4 py-2 border-2 border-approvedColor rounded-full">
                            Active Payroll
                          </div>
                        )}
                      </div>
                      <Dropdown
                        menu={{ items }}
                        placement="bottomRight"
                        arrow
                        trigger={["click"]}
                      >
                        <HiDotsVertical
                          onClick={() => {
                            setPayrollPopupboxTitle("Update Payroll");
                          }}
                          className="cursor-pointer"
                        />
                      </Dropdown>
                    </div>
                    <div className="flex items-center">
                      <div className="flex-1 flex flex-col gap-2">
                        <div className="flex items-center justify-between">
                          <div className="w-[30%]">Pay Frequency</div>
                          <div className="w-[60%] font-semibold">
                            {payroll.pay_frequency}
                          </div>
                        </div>
                        <div className="flex items-center justify-between">
                          <div className="w-[30%]">Payroll Start Date</div>
                          <div className="w-[60%] font-semibold">
                            {dayjs(payroll.payroll_start_date).format(
                              standard_date_only_format_frontend
                            )}
                          </div>
                        </div>
                        <div className="flex items-center justify-between">
                          <div className="w-[30%]">Payroll End Date</div>
                          <div className="w-[60%] font-semibold">
                            {dayjs(payroll.payroll_end_date).format(
                              standard_date_only_format_frontend
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex-1 text-lg text-secondaryText flex flex-col">
                        <span>Payroll Amount </span>
                        <span className="text-green-500 font-semibold">
                          Rs. {payroll.pay_amount}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* {!payroll.is_expired && (
                    <div className="flex items-center gap-2 justify-center text-primaryColor">
                      <div>Update Payroll?</div>
                      <div
                        onClick={() => {}}
                        className="cursor-pointer font-semibold text-lg"
                      >
                        Update Payroll
                      </div>
                    </div>
                  )} */}
                </div>
              );
            })}
          </div>
        ) : (
          <div className="flex flex-col gap-6 items-center justify-center my-6">
            <img src={nopayrollhistoryimage} alt="payroll" />
            <div className="text-lg font-semibold text-secondaryText">
              No payroll records found for this employee.
            </div>
            <div className="text-secondaryText">
              To manage payroll, create a payroll now.
            </div>
            <div
              onClick={() => {
                setPayrollPopupboxTitle("Create Payroll");
                showAddToPayrollModal();
              }}
              className="bg-green-500 px-4 py-2 rounded-md cursor-pointer text-white"
            >
              Create Payroll
            </div>
          </div>
        )}
      </Spin>
      {popupAddToPayrollModal && (
        <PopupModal
          top="20vh"
          width={550}
          open={popupAddToPayrollModal}
          onCancel={onAddToPayrollCancel}
          onOkay={onAddToPayrollOkay}
          content={
            <div className="flex flex-col items-start w-full p-2">
              <div className="text-xl font-semibold mb-6">
                {payrollPupupboxTitle}!
              </div>
              <Form layout="vertical" form={form} className="w-full">
                <Form.Item
                  required
                  label="Pay Amount"
                  name="pay_amount"
                  className="flex-1"
                >
                  <Input
                    type="number"
                    className="border border-gray-300 rounded-md w-full py-2 px-4"
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Pay Frequency"
                  name="pay_frequency"
                  className="flex-1"
                  initialValue="Monthly"
                >
                  <Input
                    disabled
                    value="Monthly"
                    className="border border-gray-300 rounded-md w-full py-2 px-4"
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Payroll Start Date"
                  name="payroll_start_date"
                  className="flex-1"
                >
                  <DatePicker
                    className="border border-gray-300 rounded-md w-full py-2 px-4"
                    format={standard_date_only_format_frontend}
                  />
                </Form.Item>
              </Form>
              {error && (
                <div className="bg-red-50 text-red-500 rounded-md text-sm text-center py-2 w-full">
                  {error}
                </div>
              )}
            </div>
          }
          button1="Cancel"
          button2={payrollPupupboxTitle}
          hideButton1=""
          hideButton2=""
          button1Color="red-500"
          button2Color="white"
          button1BgColor="secondaryColor"
          button2BgColor="primaryColor"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      <Drawer
        placement="right"
        onClose={onClose}
        title={
          <div className="text-2xl text-primaryColor font-semibold">
            Salary Calculation
          </div>
        }
        open={openSalaryCalculationDrawer}
        className="h-full"
        width={600}
        style={{
          scrollbarWidth: "thin",
        }}
      >
        <div className="flex flex-col gap-8 w-full">
          <div className="">
            <div className="w-full">
              <Form
                layout="vertical"
                form={form}
                className="w-full flex items-center gap-4"
              >
                <Form.Item
                  required
                  label="Select Month"
                  name="month"
                  className="w-full font-medium"
                  rules={[
                    {
                      required: true,
                      message: "Start and end date is required.",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    bordered={false}
                    className="border border-gray-300 rounded-md h-10 px-1 w-full"
                    options={monthOptions}
                    placeholder="Select a month."
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Select Year"
                  name="year"
                  rules={[
                    {
                      required: true,
                      message: "Year is required.",
                    },
                  ]}
                  initialValue={"2023"}
                  className="w-[25%] font-medium"
                >
                  <Select
                    showSearch
                    bordered={false}
                    className="border border-gray-300 rounded-md h-10 px-1 w-full"
                    defaultValue={"2023"}
                    options={yearOptions}
                  />
                </Form.Item>
              </Form>
            </div>

            <Spin
              spinning={loadingSalaryCalculation}
              className="w-full ml-auto"
            >
              <div
                onClick={() => {
                  calculateSalary();
                }}
                className="cursor-pointer px-4 mt-2 ml-auto py-2 rounded-md bg-primaryColor text-white text-center"
              >
                Calculate Salary
              </div>
            </Spin>
          </div>
          <div>
            {salaryDetails && salaryDetails.length > 0 && (
              <div className="flex flex-col gap-8 w-full">
                <div>
                  Salary Calculated from{" "}
                  <span className="text-xl font-semibold text-primaryColor">
                    {startDateOfSalaryGeneration}
                  </span>{" "}
                  to{" "}
                  <span className="text-xl font-semibold text-primaryColor">
                    {endDateOfSalaryGeneration}
                  </span>
                </div>
                <div className="flex flex-col gap-8 w-full">
                  {salaryDetails.map((salary: any, index: number) => {
                    return (
                      <div
                        key={index}
                        className=" p-6 w-full flex flex-col gap-3 text-md border-2 border-primaryColor border-dashed"
                      >
                        <div className="text-center font-semibold text-xl pb-2">
                          Payroll #{index + 1}
                        </div>
                        <div className="flex items-center gap-4 justify-between w-full">
                          <div className="">
                            <span className="text-secondaryText">
                              Payroll Start Date:{" "}
                            </span>
                            <span className="text-primaryColor font-semibold">
                              {dayjs(salary.payroll_start_date).format(
                                standard_date_only_format_frontend
                              )}
                            </span>
                          </div>
                          <div className="">
                            <span className="text-secondaryText">
                              {" "}
                              Payroll End Date :{" "}
                            </span>
                            <span className="text-red-500 font-semibold">
                              {salary.payroll_end_date === "Ongoing" ? (
                                <Tag color="green">Ongoing</Tag>
                              ) : (
                                dayjs(salary.payroll_end_date).format(
                                  standard_date_only_format_frontend
                                )
                              )}
                            </span>
                          </div>
                        </div>
                        <div>
                          <span className="text-secondaryText">
                            Monthly Pay Amount :{" "}
                          </span>
                          <span className="font-semibold">
                            {salary.pay_amount}
                          </span>
                        </div>
                        <div>
                          <span className="text-secondaryText">
                            Pay Frequency :{" "}
                          </span>
                          <span className="font-semibold">Monthly</span>
                        </div>
                        <div>
                          <span className="text-secondaryText">
                            Pay Per Day :{" "}
                          </span>

                          <span className="font-semibold">
                            {salary?.per_day_pay.toFixed(2)}
                          </span>
                        </div>
                        <div>
                          <span className="text-secondaryText">
                            Payable Days :{" "}
                          </span>
                          <span className="font-semibold">
                            {salary.payable_days}
                          </span>
                        </div>
                        <div>
                          <span className="text-secondaryText">
                            Paid Leave Days :{" "}
                          </span>
                          <span className="font-semibold">
                            {salary.paid_leave_days}
                          </span>
                        </div>
                        <div>
                          <span className="text-secondaryText">
                            Paid Leave Amount :{" "}
                          </span>

                          <span className="font-semibold">
                            {salary.paid_leave_amount.toFixed(2)}
                          </span>
                        </div>
                        <div>
                          <span className="text-secondaryText">
                            Holidays :{" "}
                          </span>
                          <span className="font-semibold">
                            {salary.holidays}
                          </span>
                        </div>
                        <div>
                          <span className="text-secondaryText">
                            Holiday Amount :{" "}
                          </span>

                          <span className="font-semibold">
                            {salary.holiday_amount.toFixed(2)}
                          </span>
                        </div>
                        <div>
                          <span className="text-secondaryText">
                            Late Early Present :{" "}
                          </span>

                          <span className="font-semibold">
                            {salary.late_early_present}
                          </span>
                        </div>
                        <div>
                          <span className="text-secondaryText">
                            Pay Late Early Present :{" "}
                          </span>

                          <span className="font-semibold">
                            {salary.pay_late_or_early.toFixed(2)}
                          </span>
                        </div>
                        <div>
                          <span className="text-secondaryText">
                            Half Day Present :{" "}
                          </span>

                          <span className="font-semibold">
                            {salary.half_day_present}
                          </span>
                        </div>
                        <div>
                          <span className="text-secondaryText">
                            Pay Half Day Present :{" "}
                          </span>

                          <span className="font-semibold">
                            {salary.pay_half_day.toFixed(2)}
                          </span>
                        </div>
                        <div>
                          <span className="text-secondaryText">
                            Total Pay amount :{" "}
                          </span>

                          <span className="font-semibold text-green-500">
                            {salary.amount.toFixed(2)}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="border-b-2 border-primaryColor"></div>
                <div className="flex items-center justify-between w-full gap-4">
                  <div
                    onClick={() => {
                      generatePayslip();
                    }}
                    className="cursor-pointer px-4 py-2 rounded-md bg-primaryColor text-white"
                  >
                    Generate Payslip
                  </div>
                  <div className="ml-auto text-xl font-semibold">
                    Total Pay Amount :{" "}
                    <span className="text-green-500">
                      {/* {salaryDetails
                      .reduce(
                        (total: any, salary: any) => total + salary.amount,
                        0
                      )
                      .toFixed(2)} */}
                      {totalPay.toFixed(2).toString().toLocaleString()}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Payroll;
