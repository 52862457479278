import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { standard_date_only_format_frontend } from "../../../../utils/dateAndTimeFormatter";
import EmployeeTodaysWorklog from "../../../globalComponents/pageComponents/worklog/employee-todays-worklog";

const EmployeeWorkLog = () => {
  return (
    <div>
      <div className="pb-6 flex items-center justify-between ">
        <div className=" text-3xl font-medium">
          Daily Worklog{" "}
          <span className="text-sm text-primaryColor">
            ({dayjs().startOf("day").format(standard_date_only_format_frontend)}
            )
          </span>
        </div>
        <Link
          to="/employee/worklog/history"
          className="px-6 py-2 rounded-md border hover:text-white border-primaryColor bg-primaryColor hover:bg-hoverPrimaryColor hover:border-hoverPrimaryColor transition-all duration-150 ease-in text-white cursor-pointer"
        >
          View Worklog History
        </Link>
      </div>
      <EmployeeTodaysWorklog
        heightOfTextArea="h-[65vh]"
        styleSaveButton="absolute -bottom-14"
        bottonColor="primaryColor"
        textColor="white"
        bottonHoverColor="hoverPrimaryColor"
        paddingInTextArea="p-4"
      />
    </div>
  );
};

export default EmployeeWorkLog;
