import React, { useState, useEffect, useContext } from "react";
import axiosInstance from "../../../utils/axiosInstance";
import { userContext } from "../../../context/userContext";
import decodeAccessToken from "../../../utils/decodedAccessToken";
import TasksComponent from "../../globalComponents/pageComponents/tasks/tasks-component";
import { Spin } from "antd";

const EmployeeTasks = () => {
  const userContextData: any = useContext(userContext);
  const accessToken = localStorage.getItem("accessToken");
  const { _id }: any = decodeAccessToken(accessToken);
  const [users, setUsers] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [tasks, setTasks] = useState<any>([]);
  const [taskType, setTaskType] = useState<string>("assigned_to");
  const [taskStatus, setTaskStatus] = useState<string>("Open");
  const [tasksDetails, setTasksDetails] = useState<any>();

  const getUsers = async () => {
    try {
      const response = await axiosInstance.get("/users");
      setUsers(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const getTasks = async (taskType: string) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `/tasks?${taskType ? taskType : "assigned_to"}=${_id}`
      );
      setTasks(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    userContextData.getConfig();
  }, []);

  useEffect(() => {
    if (taskType) {
      getTasks(taskType);
    }
  }, [taskType]);

  useEffect(() => {
    const myTasks = tasks.filter((task: any) => {
      return task.task_status === taskStatus;
    });
    if (myTasks) {
      setTasksDetails(myTasks);
    }
  }, [tasks, taskStatus]);

  return (
    <Spin spinning={loading}>
      <TasksComponent
        getTasks={getTasks}
        tasks={tasks}
        setTasks={setTasks}
        taskType={taskType}
        setTaskType={setTaskType}
        taskStatus={taskStatus}
        setTaskStatus={setTaskStatus}
        tasksDetails={tasksDetails}
        setTasksDetails={setTasksDetails}
        users={users}
        showTaskInfo="hidden"
      />
    </Spin>
  );
};

export default EmployeeTasks;
