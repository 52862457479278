import React, { useState, useEffect } from "react";
import axiosInstance from "../../../utils/axiosInstance";
import dayjs from "dayjs";
import {
  standard_date_only_format_backend,
  standard_date_only_format_frontend,
} from "../../../utils/dateAndTimeFormatter";
import { Drawer, Spin, Tag } from "antd";
import decodeAccessToken from "../../../utils/decodedAccessToken";
import payslipImage from "../../../assets/payslip-white.svg";
import nopayslip from "../../../assets/no/no-payslip-found.svg";

const EmployeePayslip = () => {
  const accessToken = localStorage.getItem("accessToken");
  const { _id }: any = decodeAccessToken(accessToken);
  const [openSalaryCalculationDrawer, setOpenSalaryCalculationDrawer] =
    useState(false);
  const [salaryDetails, setSalaryDetails] = useState<any>([]);
  const [payslips, setPayslips] = useState<any>([]);
  const [totalPay, setTotalPay] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [viewDetailsLoading, setViewDetailsLoading] = useState<boolean>(false);
  const [payslipStartDate, setPayslipStartDate] = useState<string>("");
  const [payslipEndDate, setPayslipEndDate] = useState<string>("");

  const getPayslips = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/payslip?user_id=${_id}`);
      setPayslips(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPayslips();
  }, []);

  const calculateSalary = async (
    start_date_of_generation: any,
    end_date_of_generation: any
  ) => {
    try {
      setViewDetailsLoading(true);
      const response = await axiosInstance.post("payroll/salaryCalculate", {
        user_id: _id,
        start_date_of_generation: dayjs(start_date_of_generation).format(
          standard_date_only_format_backend
        ),
        end_date_of_generation: dayjs(end_date_of_generation).format(
          standard_date_only_format_backend
        ),
      });
      setSalaryDetails(response.data.all_payrolls);
      setTotalPay(response.data.total_amount);
      setViewDetailsLoading(false);
    } catch (error: any) {
      console.log(error);
      setViewDetailsLoading(false);
    }
  };

  const showSalaryCalculationDrawer = async (payslip: any) => {
    const { start_date_of_generation, end_date_of_generation } = payslip;
    calculateSalary(start_date_of_generation, end_date_of_generation);
    setOpenSalaryCalculationDrawer(true);
  };

  const onClose = () => {
    setSalaryDetails([]);
    setOpenSalaryCalculationDrawer(false);
  };

  return (
    <div className="flex flex-col">
      <div className="pb-6 flex items-center justify-between">
        <div className="text-3xl font-medium ">Payslips</div>
      </div>

      <Spin spinning={loading} className="min-h-[40vh]">
        {payslips && payslips.length > 0 ? (
          <div className="flex flex-col gap-8 ">
            {payslips.map((payslip: any, index: number) => {
              return (
                <div
                  key={index}
                  className="p-8 rounded-md bg-primaryBackground flex flex-col gap-8 justify-between"
                >
                  <div className="flex justify-between w-full gap-4">
                    <div className="flex items-center gap-4">
                      <div className="h-12 w-12 bg-[#888] rounded-full flex items-center justify-center">
                        <img src={payslipImage} alt="payslip" />
                      </div>
                      <div>
                        <div className="font-semibold text-lg">
                          {dayjs(payslip.start_date_of_generation).format(
                            "MMMM"
                          )}
                        </div>
                        <div className="text-secondaryText">
                          <span>
                            {" "}
                            {dayjs(payslip.start_date_of_generation).format(
                              standard_date_only_format_frontend
                            )}
                          </span>{" "}
                          -{" "}
                          <span>
                            {" "}
                            {dayjs(payslip.end_date_of_generation).format(
                              standard_date_only_format_frontend
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      {payslip.payslip_status === "Complete" && (
                        <Tag color="#008000" className="font-semibold">
                          {payslip.payslip_status}
                        </Tag>
                      )}

                      {payslip.payslip_status === "Pending" && (
                        <Tag color="#808080" className="font-semibold">
                          {payslip.payslip_status}
                        </Tag>
                      )}

                      {payslip.payslip_status === "Void" && (
                        <Tag color="#FF0000" className="font-semibold">
                          {payslip.payslip_status}
                        </Tag>
                      )}
                    </div>
                  </div>
                  <div className="border-b-2 w-full"></div>
                  <div>
                    <div className="text-secondaryText">Pay Amount</div>
                    <div className="font-semibold text-xl">
                      Rs. {payslip.pay_amount.toFixed(2)}
                    </div>
                  </div>
                  <div className="border-b-2 w-full"></div>
                  {payslip.payslip_status === "Void" && (
                    <div className="text-secondaryText">
                      Void Reason: {payslip.void_reason}
                    </div>
                  )}
                  {payslip.payslip_status === "Complete" && (
                    <div
                      onClick={() => {
                        setPayslipStartDate(payslip.start_date_of_generation);
                        setPayslipEndDate(payslip.end_date_of_generation);
                        showSalaryCalculationDrawer(payslip);
                      }}
                      className="text-secondaryText underline underline-secondaryText cursor-pointer hover:text-primaryColor hover:underline-primaryColor underline-offset-4"
                    >
                      View Details
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <div className="flex items-center justify-center min-h-[50vh] bg-primaryBackground p-4 rounded-md">
            <img src={nopayslip} alt="nopayslip" className="h-72 w-72" />
          </div>
        )}
      </Spin>

      {openSalaryCalculationDrawer && (
        <Drawer
          placement="right"
          closeIcon={false}
          onClose={onClose}
          open={openSalaryCalculationDrawer}
          className="h-full"
          width={600}
          style={{
            scrollbarWidth: "thin",
          }}
        >
          <div className="flex flex-col gap-8 w-full">
            <div className="text-2xl text-primaryColor font-semibold">
              Payslip Details
            </div>
            <div className="flex flex-col">
              {salaryDetails.length > 0 ? (
                <div className="flex flex-col gap-8 w-full">
                  <div>
                    Payslip Calculated from{" "}
                    <span className="text-xl font-semibold text-primaryColor">
                      {dayjs(payslipStartDate).format(
                        standard_date_only_format_frontend
                      )}
                    </span>{" "}
                    to{" "}
                    <span className="text-xl font-semibold text-primaryColor">
                      {dayjs(payslipEndDate).format(
                        standard_date_only_format_frontend
                      )}
                    </span>
                  </div>
                  <div className="flex flex-col gap-8 w-full">
                    {salaryDetails.map((salary: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className=" p-6 w-full flex flex-col gap-3 text-md border-2 border-primaryColor border-dashed"
                        >
                          <div className="text-center font-semibold text-xl pb-2">
                            Payslip #{index + 1}
                          </div>
                          <div className="flex items-center gap-4 justify-between w-full">
                            <div className="">
                              <span className="text-secondaryText">
                                Payroll Start Date:{" "}
                              </span>
                              <span className="text-primaryColor font-semibold">
                                {dayjs(salary.payroll_start_date).format(
                                  standard_date_only_format_frontend
                                )}
                              </span>
                            </div>
                            <div className="">
                              <span className="text-secondaryText">
                                {" "}
                                Payroll End Date :{" "}
                              </span>
                              <span className="text-red-500 font-semibold">
                                {salary.payroll_end_date === "Ongoing" ? (
                                  <Tag color="green">Ongoing</Tag>
                                ) : (
                                  dayjs(salary.payroll_end_date).format(
                                    standard_date_only_format_frontend
                                  )
                                )}
                              </span>
                            </div>
                          </div>
                          <div>
                            <span className="text-secondaryText">
                              Monthly Pay Amount :{" "}
                            </span>
                            <span className="font-semibold">
                              {salary.pay_amount}
                            </span>
                          </div>
                          <div>
                            <span className="text-secondaryText">
                              Pay Frequency :{" "}
                            </span>
                            <span className="font-semibold">Monthly</span>
                          </div>
                          <div>
                            <span className="text-secondaryText">
                              Pay Per Day :{" "}
                            </span>

                            <span className="font-semibold">
                              {salary?.per_day_pay.toFixed(2)}
                            </span>
                          </div>
                          <div>
                            <span className="text-secondaryText">
                              Payable Days :{" "}
                            </span>
                            <span className="font-semibold">
                              {salary.payable_days}
                            </span>
                          </div>
                          <div>
                            <span className="text-secondaryText">
                              Paid Leave Days :{" "}
                            </span>
                            <span className="font-semibold">
                              {salary.paid_leave_days}
                            </span>
                          </div>
                          <div>
                            <span className="text-secondaryText">
                              Paid Leave Amount :{" "}
                            </span>

                            <span className="font-semibold">
                              {salary.paid_leave_amount.toFixed(2)}
                            </span>
                          </div>
                          <div>
                            <span className="text-secondaryText">
                              Holidays :{" "}
                            </span>
                            <span className="font-semibold">
                              {salary.holidays}
                            </span>
                          </div>
                          <div>
                            <span className="text-secondaryText">
                              Holiday Amount :{" "}
                            </span>

                            <span className="font-semibold">
                              {salary.holiday_amount.toFixed(2)}
                            </span>
                          </div>
                          <div>
                            <span className="text-secondaryText">
                              Late Early Present :{" "}
                            </span>

                            <span className="font-semibold">
                              {salary.late_early_present}
                            </span>
                          </div>
                          <div>
                            <span className="text-secondaryText">
                              Pay Late Early Present :{" "}
                            </span>

                            <span className="font-semibold">
                              {salary.pay_late_or_early.toFixed(2)}
                            </span>
                          </div>
                          <div>
                            <span className="text-secondaryText">
                              Half Day Present :{" "}
                            </span>

                            <span className="font-semibold">
                              {salary.half_day_present}
                            </span>
                          </div>
                          <div>
                            <span className="text-secondaryText">
                              Pay Half Day Present :{" "}
                            </span>

                            <span className="font-semibold">
                              {salary.pay_half_day.toFixed(2)}
                            </span>
                          </div>
                          <div>
                            <span className="text-secondaryText">
                              Total Pay amount :{" "}
                            </span>

                            <span className="font-semibold text-green-500">
                              {salary.amount.toFixed(2)}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="border-b-2 border-primaryColor"></div>
                  <div className="flex items-center justify-between w-full gap-4">
                    <div className="ml-auto text-xl font-semibold">
                      Total Pay Amount :{" "}
                      <span className="text-green-500">
                        {totalPay.toFixed(2).toString().toLocaleString()}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <Spin
                  className="self-center mt-[40vh]"
                  spinning={viewDetailsLoading}
                ></Spin>
              )}
            </div>
          </div>
        </Drawer>
      )}
    </div>
  );
};

export default EmployeePayslip;
