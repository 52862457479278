export const BanksInNepal = [
  {
    index: 1,
    bank: "Kumari Bank",
  },
  {
    index: 2,
    bank: "Rastriya Banijya Bank",
  },
  {
    index: 3,
    bank: "Agriculture Development Bank",
  },
  {
    index: 4,
    bank: "Nabil Bank",
  },
  {
    index: 5,
    bank: "Nepal Investment Mega Bank",
  },
  {
    index: 6,
    bank: "Standard Chartered Bank Nepal",
  },
  {
    index: 7,
    bank: "Himalayan Bank",
  },
  {
    index: 8,
    bank: "Nepal SBI Bank",
  },
  {
    index: 9,
    bank: "Everest Bank",
  },
  {
    index: 10,
    bank: "Prabhu Bank",
  },
  {
    index: 11,
    bank: "Laxmi Sunrise Bank",
  },
  {
    index: 12,
    bank: "Global IME Limited",
  },
  {
    index: 13,
    bank: "Citizens Bank International",
  },
  {
    index: 14,
    bank: "Prime Commercial Bank",
  },
  {
    index: 15,
    bank: "NMB Bank Nepal",
  },
  {
    index: 16,
    bank: "NIC Asia Bank",
  },
  {
    index: 17,
    bank: "Siddhartha Bank",
  },
  {
    index: 18,
    bank: "Sanima Bank",
  },
  {
    index: 19,
    bank: "Machhapuchchhre Bank",
  },
  {
    index: 20,
    bank: "Nepal Bank Limited",
  },
];
