import React, { useContext, useEffect, useState } from "react";
import ClockInOut from "../../globalComponents/pageComponents/attendance/clock-in-out";
import EmployeeTodaysWorklog from "../../globalComponents/pageComponents/worklog/employee-todays-worklog";
import greet from "../../../utils/greetAccordingToTime";
import { userContext } from "../../../context/userContext";
import decodeAccessToken from "../../../utils/decodedAccessToken";
import { standard_time_only_format_frontend } from "../../../utils/dateAndTimeFormatter";
import dayjs from "dayjs";
import axiosInstance from "../../../utils/axiosInstance";
import { Avatar, Checkbox, Spin, Table } from "antd";
import ProfilePictureHeadGroup from "../../globalComponents/profilePictureHead/profilePictureHeadGroup";
import truncateLongerUsername from "../../../utils/truncateLongerUsernames";
import { Link, useNavigate } from "react-router-dom";

const EmployeeOverview = () => {
  const userContextData: any = useContext(userContext);
  const [loading, setLoading] = useState<boolean>(false);
  const accessToken = localStorage.getItem("accessToken");
  const { _id, name }: any = decodeAccessToken(accessToken);
  const firstName = name.split(" ", 1);
  const navigate = useNavigate();
  useEffect(() => {
    userContextData.getConfig();
  }, []);

  const [tasks, setTasks] = useState<any>([]);
  const [tasksDetails, setTasksDetails] = useState<any>([]);

  const mainStyles = {
    display: "flex",
    flexDirection: "Column",
    gap: "12px",
    alignItems: "Center",
    justifyContent: "Center",
    padding: "16px",
    borderRadius: "9px",
    backgroundColor: "#FFFFFF",
  };
  const showTimeStyles = {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  };

  const getTasks = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/tasks?assigned_to=${_id}`);
      setTasks(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTasks();
  }, []);

  useEffect(() => {
    const myTasks = tasks.filter((task: any) => {
      return task.task_status === "Open";
    });
    if (myTasks) {
      setTasksDetails(myTasks);
    }
  }, [tasks]);

  return (
    <div>
      <div className="flex items-center justify-between gap-4 pb-6">
        <div className="text-3xl font-medium">
          {greet()},{firstName}
        </div>
        <div className="flex flex-col items-end">
          <div className="text-xl font-medium">
            {dayjs().format(standard_time_only_format_frontend)}
          </div>
          <div className="text-sm text-secondaryText">
            {dayjs().format("MMMM D, YYYY")}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="h-84 flex items-center gap-4 w-full">
          <div className="w-[60%] flex flex-col gap-4 bg-primaryBackground rounded-md p-4 h-full">
            <div className="text-secondaryText text-sm">
              Today's Clock In/Out
            </div>
            <div className="w-full border border-b"></div>

            <ClockInOut
              showTimeStyles={showTimeStyles}
              mainStyles={mainStyles}
            />
          </div>
          <div className="w-[40%] flex flex-col gap-4 h-full">
            <div className="flex-1 p-4 bg-primaryBackground rounded-md flex flex-col justify-between ">
              <div className="text-secondaryText text-sm">
                Working Days{" "}
                <span className="text-[10px] text-primaryColor">
                  ({dayjs().format("MMM, YYYY")})
                </span>
              </div>
              <div className="text-xl font-medium text-approvedColor">0</div>
            </div>
            <div className="flex-1 p-4 bg-primaryBackground rounded-md flex flex-col justify-between ">
              <div className="text-secondaryText text-sm">Present Days</div>
              <div className="text-xl font-medium text-approvedColor">0</div>
            </div>
            <div className="flex-1 p-4 bg-primaryBackground rounded-md flex flex-col justify-between">
              <div className="text-secondaryText text-sm">Absent Days</div>
              <div className="text-xl font-medium text-rejectedColor">0</div>
            </div>
          </div>
        </div>
        <div className="h-84 p-4 bg-primaryBackground rounded-md flex flex-col gap-4">
          <div className="flex items-center justify-between gap-2">
            <div className="text-secondaryText text-sm">Today's Worklogs</div>
          </div>
          <div className="w-full border border-b"></div>
          <div>
            <EmployeeTodaysWorklog
              heightOfTextArea="h-72"
              styleSaveButton="absolute right-0 -top-16"
              bottonColor="secondaryBackground"
              textColor="secondaryText"
              paddingInTextArea="px-4"
            />
          </div>
        </div>
        <div className="h-84 p-4 bg-primaryBackground rounded-md flex flex-col gap-4">
          <div className="flex items-center gap-2 justify-between w-full">
            <div className="flex items-center gap-2">
              <div className="text-secondaryText text-sm">Open Task</div>
              <span
                className={`${
                  userContextData.tasks > 0
                    ? " bg-approvedColor"
                    : "bg-pendingColor"
                } text-[12px] text-white bg-approvedColor font-semibold rounded-full flex items-center justify-center  h-5 w-5`}
              >
                {userContextData.tasks}
              </span>
            </div>
            <Link
              to="/employee/tasks"
              className="text-secondaryText text-sm cursor-pointer hover:text-primaryColor"
            >
              View All
            </Link>
          </div>
          <div className="w-full border border-b"></div>
          <div>
            <Spin spinning={loading}>
              <Table
                style={{
                  scrollbarWidth: "thin",
                }}
                className="cursor-pointer h-72 overflow-auto"
                size="middle"
                columns={[
                  {
                    title: <Checkbox />,
                    dataIndex: "check",
                    key: "check",
                  },
                  {
                    title: "Task",
                    dataIndex: "task",
                    key: "task",
                  },
                  {
                    title: "Created By",
                    dataIndex: "created_by",
                    key: "created_by",
                  },
                  {
                    title: "Assigned To",
                    dataIndex: "assigned_to",
                    key: "assigned_to",
                  },
                ]}
                pagination={false}
                dataSource={
                  tasksDetails &&
                  tasksDetails?.map((task: any) => {
                    return {
                      key: task._id,
                      check: <Checkbox />,
                      task: (
                        <div className="clip cursor-pointer text-primaryColor font-semibold">
                          {truncateLongerUsername(task.task_title, 20)}
                        </div>
                      ),
                      created_by: (
                        <ProfilePictureHeadGroup
                          profile={task?.created_by?.profile_picture}
                          username={task?.created_by?.full_name}
                        />
                      ),
                      assigned_to: (
                        <Avatar.Group>
                          {task.assigned_to.map((name: any, index: number) => (
                            <ProfilePictureHeadGroup
                              index={index}
                              profile={name.profile_picture}
                              username={name.full_name}
                            />
                          ))}
                        </Avatar.Group>
                      ),
                    };
                  })
                }
                onRow={(record) => ({
                  onClick: (event: any) => {
                    navigate("/employee/tasks");
                  },
                })}
              />
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeOverview;
