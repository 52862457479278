import EmployeeDashboardLayout from "../../../layouts/employeeDashboardLayout";
import { useNavigate } from "react-router-dom";
import WorklogCalender from "../../../globalComponents/pageComponents/worklog/worklog-calender";

const EmployeeWorkLogHistory = () => {
  const navigate = useNavigate();
  return (
    <EmployeeDashboardLayout>
      <div>
        <div className="pb-6 flex items-center justify-between ">
          <div className="flex items-center gap-1">
            <div
              onClick={() => {
                navigate("/employee/worklog");
              }}
              className="text-secondaryText cursor-pointer"
            >
              Daily Worklog /
            </div>
            <div className="text-2xl font-semibold">Worklog History</div>
          </div>
        </div>
        <WorklogCalender />
      </div>
    </EmployeeDashboardLayout>
  );
};

export default EmployeeWorkLogHistory;
