import { Form, Spin, Table, Tag, Input, Dropdown } from "antd";
import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import { HiDotsVertical } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { userContext } from "../../../context/userContext";
import axiosInstance from "../../../utils/axiosInstance";
import {
  standard_date_only_format_frontend,
  standard_time_only_format_frontend,
} from "../../../utils/dateAndTimeFormatter";
import notificationShow from "../../globalComponents/notificationService";
import PopupModal from "../../globalComponents/popupModal";
import approvedTick from "../../../assets/approved-tick.svg";
import rejectedCross from "../../../assets/rejected-cross.svg";
import truncateLongerUsername from "../../../utils/truncateLongerUsernames";
const { TextArea } = Input;

const ManualEntries = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [manualEntries, setManualEntries] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [manualEntryId, setManualEntryId] = useState<string>("");
  const [error, setError] = useState<string>("");

  const userContextData: any = useContext(userContext);

  useEffect(() => {
    userContextData.getConfig();
  }, []);

  const getManualEntries = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/attendance?is_manual=${true}`);
      setManualEntries(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getManualEntries();
  }, []);

  const approveManualEntry = async () => {
    try {
      const response = await axiosInstance.post(
        "/attendance/manualEntry/approveRejectManualEntry",
        {
          manual_entry_id: manualEntryId,
          action: "Approved",
        }
      );
      getManualEntries();
      notificationShow({
        type: "success",
        content: `Manual Entry request approved successfully!`,
      });
    } catch (error: any) {
      setLoading(false);
    }
  };

  const rejectManualEntry = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const formValues = form.getFieldsValue();
      const response = await axiosInstance.post(
        "/attendance/manualEntry/approveRejectManualEntry",
        {
          manual_entry_id: manualEntryId,
          action: "Rejected",
          rejection_reason: formValues.rejection_reason,
        }
      );
      getManualEntries();
      notificationShow({
        type: "success",
        content: `Manual Entry request rejected successfully!`,
      });
      form.resetFields();
      setManualStatusPopupModal(false);
      setLoading(false);
      setError("");
    } catch (error: any) {
      setLoading(false);
      setError(error?.response?.data?.error);
    }
  };

  const timeOffColumns: any = [
    {
      title: "S.N",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Requested By",
      dataIndex: "requested_by",
      key: "requested_by",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Clock In Time",
      dataIndex: "clock_in_time",
      key: "clock_in_time",
    },
    {
      title: "Clock Out Time",
      dataIndex: "clock_out_time",
      key: "clock_out_time",
    },
    {
      title: "Reason",
      dataIndex: "manual_entry_reason",
      key: "manual_entry_reason",
    },
    {
      title: "Status",
      dataIndex: "approval_status",
      key: "approval_status",
    },
  ];

  const items: any = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            approveManualEntry();
          }}
          className="flex items-center gap-2 px-2"
        >
          <img
            src={approvedTick}
            alt="approvedTick"
            className="bg-approvedColor h-4 w-4 rounded-full p-1"
          />
          <div>Approve</div>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            showModel();
          }}
          className="flex items-center gap-2 px-2"
        >
          <img
            src={rejectedCross}
            alt="rejected"
            className="bg-rejectedColor h-4 w-4 rounded-full p-1"
          />
          <div>Reject</div>
        </div>
      ),
    },
  ];

  const [manualStatusPopupModal, setManualStatusPopupModal] = useState(false);

  const showModel = async () => {
    setManualStatusPopupModal(true);
    setLoading(false);
  };
  const onCancel = () => {
    setManualStatusPopupModal(false);
  };
  const onOkay = () => {
    rejectManualEntry();
  };

  return (
    <div className="text-primaryText">
      <div className="pb-6 flex items-center justify-between ">
        <div className="text-3xl font-medium">Manual Entries</div>
      </div>
      <div className="p-4 rounded-md bg-primaryBackground">
        <Spin spinning={loading} className="text-primaryText">
          <Table
            className="cursor-pointer"
            size="middle"
            columns={timeOffColumns}
            dataSource={
              manualEntries &&
              manualEntries?.map((manual: any, index: number) => {
                return {
                  key: manual?._id,
                  requested_by: (
                    <div className="flex flex-col gap-1">
                      <div
                        onClick={() => {
                          navigate(
                            `/dashboard/team-members/${manual.user_id._id}`
                          );
                        }}
                        className="font-semibold text-primaryColor"
                      >
                        {truncateLongerUsername(manual?.user_id?.full_name, 15)}
                      </div>
                      <div className="text-[10px] text-secondaryText">
                        {dayjs(manual.createdAt).format(
                          standard_date_only_format_frontend
                        )}
                      </div>
                    </div>
                  ),
                  index: index + 1 + ".",
                  date: dayjs(manual?.day_start).format(
                    standard_date_only_format_frontend
                  ),
                  clock_in_time: (
                    <div className="flex flex-col items-start gap-1">
                      <div>
                        {dayjs(manual?.clock_in_time).format(
                          standard_time_only_format_frontend
                        )}
                      </div>
                      {manual.attendance_clockin_status.toLowerCase() ===
                        "on time" && (
                        <Tag className="bg-approvedColor text-whiteColor text-[12px] font-semibold">
                          {manual.attendance_clockin_status}
                        </Tag>
                      )}
                      {manual.attendance_clockin_status.toLowerCase() ===
                        "half day" && (
                        <Tag className="bg-late text-whiteColor text-[12px] font-semibold">
                          {manual.attendance_clockin_status}
                        </Tag>
                      )}
                      {manual.attendance_clockin_status.toLowerCase() ===
                        "late" && (
                        <Tag className="bg-late text-whiteColor text-[12px] font-semibold">
                          {manual.attendance_clockin_status}
                        </Tag>
                      )}
                    </div>
                  ),
                  clock_out_time: (
                    <div className="flex flex-col items-start gap-1">
                      <div>
                        {dayjs(manual?.clock_out_time).format(
                          standard_time_only_format_frontend
                        )}
                      </div>
                      {manual.attendance_clockout_status.toLowerCase() ===
                        "full time" && (
                        <Tag className="bg-fullTime text-whiteColor text-[12px] font-semibold">
                          {manual.attendance_clockout_status}
                        </Tag>
                      )}
                      {manual.attendance_clockout_status.toLowerCase() ===
                        "left early" && (
                        <Tag className="bg-late text-whiteColor text-[12px] font-semibold">
                          {manual.attendance_clockout_status}
                        </Tag>
                      )}
                    </div>
                  ),
                  manual_entry_reason: (
                    <div className="clip max-w-[15vw]">
                      {manual?.manual_entry_reason}
                    </div>
                  ),
                  approval_status: (
                    <div>
                      {manual.approval_status.toLowerCase() === "approved" ||
                      manual.approval_status.toLowerCase() === "rejected" ? (
                        <Dropdown
                          menu={{ items }}
                          placement="bottomRight"
                          arrow
                          trigger={["click"]}
                        >
                          <div>
                            <div className="w-full gap-2 flex items-center justify-between">
                              <Tag
                                color="#"
                                className={`${
                                  manual.approval_status.toLowerCase() ===
                                    "approved" && "bg-approvedColor"
                                } ${
                                  manual.approval_status.toLowerCase() ===
                                    "rejected" && "bg-rejectedColor"
                                } font-semibold`}
                              >
                                {manual.approval_status}
                              </Tag>
                              <HiDotsVertical />
                            </div>

                            {manual.approval_status.toLowerCase() ===
                            "approved" ? (
                              <div className="text-approvedColor font-semibold text-[12px]">
                                Approved By: {manual.approved_by.full_name}
                              </div>
                            ) : (
                              <div className="text-rejectedColor text-[12px] font-semibold">
                                Rejected By: {manual.rejected_by.full_name}
                              </div>
                            )}
                            {manual.approval_status.toLowerCase() ===
                              "rejected" && (
                              <div className="max-w-[15vw] clip text-[12px] text-red-500 font-semibold">
                                {manual.rejection_reason}
                              </div>
                            )}
                          </div>
                        </Dropdown>
                      ) : (
                        <Dropdown
                          menu={{ items }}
                          placement="bottomRight"
                          arrow
                          trigger={["click"]}
                        >
                          <div className="flex items-center justify-between gap-2 w-full">
                            {manual?.approval_status.toLowerCase() ===
                              "pending" && (
                              <Tag
                                color="#"
                                className="font-semibold bg-pendingColor"
                              >
                                {manual.approval_status}
                              </Tag>
                            )}

                            <HiDotsVertical />
                          </div>
                        </Dropdown>
                      )}
                    </div>
                  ),
                };
              })
            }
            pagination={false}
            onRow={(record) => ({
              onClick: () => {
                setManualEntryId(record.key);
              },
            })}
          />
        </Spin>
      </div>
      {manualStatusPopupModal && (
        <PopupModal
          width={500}
          top="20vh"
          open={manualStatusPopupModal}
          onCancel={onCancel}
          onOkay={onOkay}
          content={
            <div className="flex flex-col gap-6 w-full p-2">
              <div className="text-xl font-semibold">
                Reason for Manual Entry Rejection!
              </div>
              <Form layout="vertical" form={form} className="w-full">
                <Form.Item
                  required
                  className="font-medium"
                  label="Reason"
                  name="rejection_reason"
                  rules={[
                    {
                      required: true,
                      message:
                        "Manual Entry request rejection reason is required.",
                    },
                  ]}
                >
                  <TextArea rows={4} />
                </Form.Item>
              </Form>
              {error && (
                <div className="bg-red-50 text-red-500 rounded-md text-sm text-center py-2 w-full">
                  {error}
                </div>
              )}
            </div>
          }
          button1="Cancel"
          button2="Reject"
          hideButton1=""
          hideButton2=""
          button1Color="rejectedColor"
          button2Color="white"
          button1BgColor="secondaryColor"
          button2BgColor="rejectedColor"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}
    </div>
  );
};

export default ManualEntries;
