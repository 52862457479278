import { Form, Input, Spin } from "antd";
import { Link } from "react-router-dom";
import axiosInstance from "../../../utils/axiosInstance";
import React, { useState } from "react";
import NavbarChibiLogo from "../../globalComponents/navbar-chibi-logo";

const RegisterEmail = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const registerEmail = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const formValues = form.getFieldsValue();
      const response = await axiosInstance.post(`/users/sendOTP`, {
        email: formValues.email,
      });
      window.location.href = `/verify-email?email=${formValues.email}`;
      form.resetFields();
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setError(error?.response?.data?.error);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-[100vh] gap-4 bg-secondaryBackground text-primaryText">
      <NavbarChibiLogo />
      <div className="flex items-center justify-center  p-12 rounded-md bg-primaryBackground">
        <div className="w-full md:w-[25vw] flex flex-col">
          <div className="flex flex-col items-center gap-4 text-center mb-8">
            <div className="flex flex-col font-semibold text-2xl md:text-4xl ">
              <span>Get started with</span>
              <span className="text-primaryColor mt-4">chibiHR</span>
            </div>
          </div>
          <Form autoComplete="off" size="large" layout="vertical" form={form}>
            <Form.Item
              required
              name="email"
              label={<div className="font-semibold">Email Address</div>}
              rules={[
                {
                  required: true,
                  message: "Email is required.",
                },
                {
                  pattern:
                    /^(?!.*\.\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Invalid email format.",
                },
              ]}
              className="w-full"
            >
              <Input className="border border-gray-300 rounded-md w-full py-2 px-4" />
            </Form.Item>
          </Form>
          {error && (
            <div className="text-center text-sm text-red-500">{error}</div>
          )}
          <Spin spinning={loading}>
            <button
              onClick={registerEmail}
              className="bg-primaryColor text-white font-semibold px-4 py-2 rounded-md w-full mb-8"
            >
              Get Started
            </button>
          </Spin>

          <div className="text-center">
            Already using chibiHR?{" "}
            <Link to="/sign-in" className="text-primaryColor cursor-pointer">
              Sign in here
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterEmail;
