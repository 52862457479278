import React, { useEffect, useState } from "react";
import axiosInstance from "../../../../utils/axiosInstance";
import TrackingPolicyAddEditDrawer from "../../../globalComponents/pageComponents/trackingPolicy/tracking-policy-add-edit-drawer";
import notificationShow from "../../../globalComponents/notificationService";
import { Spin } from "antd";
import edit from "../../../../assets/edit.svg";
import { IoDuplicateOutline } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import PopupModal from "../../../globalComponents/popupModal";
import ShowLableValueForDetails from "../../../globalComponents/showLabelValueForDetails";

const SettingsTrackingPolicy = () => {
  const [policies, setPolicies] = useState<any>([]);
  const [openPolicyDrawer, setOpenPolicyDrawer] = useState(false);
  const [policyAction, setPolicyAction] = useState<string>("");
  const [singlePolicyId, setSinglePolicyId] = useState<string>("");
  const [policyDetails, setPolicyDetails] = useState<any>([]);
  const [title, setTitle] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const getPolicies = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get("/settings/policies");
      setPolicies(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPolicies();
  }, []);

  // policy drawer
  const showDrawer = () => {
    setOpenPolicyDrawer(true);
  };

  const onClose = () => {
    setOpenPolicyDrawer(false);
  };

  const duplicatePolicy = async (policyId: string) => {
    try {
      setLoading(true);

      const response = await axiosInstance.post(
        `/settings/policy/duplicate/${policyId}`
      );
      getPolicies();
      setTitle("");
      setLoading(false);

      notificationShow({
        type: "success",
        content: "Policy Duplicated Successfully!",
      });
    } catch (error: any) {
      notificationShow({
        type: "error",
        content: error?.response?.data?.error,
      });
      setLoading(false);
    }
  };

  const [
    popupModalForDuplicateConfirmation,
    setPopupModalForDuplicateConfirmation,
  ] = useState(false);

  const showDuplicateConfirmationModal = async () => {
    setPopupModalForDuplicateConfirmation(true);
    setLoading(false);
  };
  const onDuplicateConfirmationModalCancel = () => {
    setPopupModalForDuplicateConfirmation(false);
  };
  const onDuplicateConfirmationModalOkay = () => {
    duplicatePolicy(singlePolicyId);
    setPopupModalForDuplicateConfirmation(false);
  };

  const deletePolicy = async (policyId: string) => {
    try {
      const response = await axiosInstance.delete(
        `/settings/policy/${policyId}`
      );
      notificationShow({
        type: "success",
        message: "Policy has been Deleted Successfully!",
      });
      getPolicies();
    } catch (error: any) {
      notificationShow({
        type: "error",
        content: error?.response?.data?.error,
      });
      setLoading(false);
    }
  };

  const [
    popupModalForPolicyDeletionConfirmation,
    setPopupModalForPolicyDeletionConfirmation,
  ] = useState(false);

  const showPolicyDeletionConfirmationModal = async () => {
    setPopupModalForPolicyDeletionConfirmation(true);
    setLoading(false);
  };
  const onPolicyDeletionConfirmationModalCancel = () => {
    setPopupModalForPolicyDeletionConfirmation(false);
  };
  const onPolicyDeletionConfirmationModalOkay = () => {
    deletePolicy(singlePolicyId);
    setPopupModalForPolicyDeletionConfirmation(false);
  };

  return (
    <div className="gap-8 text-primaryText">
      <div className="pb-6 flex items-center justify-between ">
        <div className="text-3xl font-medium">
          Tracking Policies{" "}
          <span className="text-sm font-normal text-secondaryText">
            {policies.length} total tracking-policies
          </span>
        </div>
        <div
          onClick={() => {
            setPolicyAction("create-new-policy");
            setTitle("Create New Policy");
            showDrawer();
          }}
          className=" px-6 py-2 rounded-md bg-primaryColor border border-primaryColor hover:bg-hoverPrimaryColor hover:border-hoverPrimaryColor transition-all ease-in duration-150 text-white cursor-pointer"
        >
          Create New Policy
        </div>
      </div>
      <Spin spinning={loading} className="min-h-[60vh]">
        <div className="flex flex-col gap-8">
          {policies &&
            policies.map((policy: any, index: number) => {
              return (
                <div
                  key={index}
                  className="flex flex-col gap-8 p-6 bg-primaryBackground rounded-md"
                >
                  <div className="flex flex-col gap-4">
                    <div className="text-lg font-medium">Policy Name</div>
                    <div className="text-md text-secondaryText w-[90%] mx-auto">
                      {policy.policy_name}
                    </div>
                  </div>
                  <div className="flex flex-col gap-4">
                    <div className="text-lg font-medium">
                      Policy Description
                    </div>
                    <div className="text-secondaryText w-[90%] mx-auto">
                      {policy.policy_description}
                    </div>
                  </div>
                  <div className="flex flex-col gap-4">
                    <div className="text-lg font-medium">Policy Details</div>
                    <ShowLableValueForDetails
                      details={[
                        {
                          label: "Daily Start Time",
                          value: policy.daily_start_time,
                        },
                        {
                          label: "Daily End Time",
                          value: policy.daily_end_time,
                        },
                        {
                          label: "Late Arrival Tolerance",
                          value: `${policy.late_clock_minutes} minutes`,
                        },
                        {
                          label: "Half Day Duration",
                          value: `${policy.half_day_clock_minutes} minutes`,
                        },
                        {
                          label: "Attendance Limit",
                          value: `${policy.absent_day_clock_minutes} minutes`,
                        },
                      ]}
                    />
                  </div>
                  <div className="flex items-center gap-6 absolute right-4">
                    <div
                      onClick={() => {
                        setPolicyAction("edit-policy");
                        setPolicyDetails(policy);
                        setTitle(`Edit ${policy.policy_name}`);
                        showDrawer();
                      }}
                      className="h-6 w-6 cursor-pointer"
                    >
                      <img src={edit} alt="edit" className="h-full w-full" />
                    </div>
                    <div
                      onClick={() => {
                        setSinglePolicyId(policy._id);
                        setTitle(`${policy.policy_name}`);
                        showDuplicateConfirmationModal();
                      }}
                      className="h-6 w-6 cursor-pointer"
                    >
                      <IoDuplicateOutline className="h-full w-full text-primaryColor" />
                    </div>
                    <div
                      onClick={() => {
                        setSinglePolicyId(policy._id);
                        setTitle(`${policy.policy_name}`);
                        showPolicyDeletionConfirmationModal();
                      }}
                      className="h-6 w-6 cursor-pointer"
                    >
                      <MdDeleteOutline className="h-full w-full text-primaryColor" />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </Spin>
      <TrackingPolicyAddEditDrawer
        title={title}
        policyAction={policyAction}
        onClose={onClose}
        getPolicies={getPolicies}
        policyDetails={policyDetails}
        openPolicyDrawer={openPolicyDrawer}
        setOpenPolicyDrawer={setOpenPolicyDrawer}
        key={Math.random()}
      />
      {popupModalForDuplicateConfirmation && (
        <PopupModal
          width={500}
          top="20vh"
          open={popupModalForDuplicateConfirmation}
          onCancel={onDuplicateConfirmationModalCancel}
          onOkay={onDuplicateConfirmationModalOkay}
          content={
            <div className="flex flex-col p-2 text-primaryText">
              <div className="text-xl font-semibold">
                Duplication of Existing Policy
              </div>
              <div className="pt-8 pb-6">
                Are you sure you want to duplicate{" "}
                <span className="font-semibold text-primaryColor">{title}</span>{" "}
                ?
              </div>
            </div>
          }
          button1="Cancel"
          button2="Duplicate"
          hideButton1=""
          hideButton2=""
          button1Color="white"
          button2Color="white"
          button1BgColor="rejectedColor"
          button2BgColor="primaryColor"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      {popupModalForPolicyDeletionConfirmation && (
        <PopupModal
          width={500}
          top="20vh"
          open={popupModalForPolicyDeletionConfirmation}
          onCancel={onPolicyDeletionConfirmationModalCancel}
          onOkay={onPolicyDeletionConfirmationModalOkay}
          content={
            <div className="flex flex-col p-2 text-primaryText">
              <div className="text-xl font-semibold">
                Delete Existing Policy
              </div>
              <div className="pt-8 pb-6">
                Are you sure you want to delete -{" "}
                <span className="font-semibold text-primaryColor">{title}</span>{" "}
                ?
              </div>
            </div>
          }
          button1="Cancel"
          button2="Delete"
          hideButton1=""
          hideButton2=""
          button1Color="white"
          button2Color="white"
          button1BgColor="primaryColor"
          button2BgColor="rejectedColor"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}
    </div>
  );
};

export default SettingsTrackingPolicy;
