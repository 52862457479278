import React, { useState, useRef } from "react";
import truncateName from "../../../utils/truncateName";
import { v4 as uuidv4 } from "uuid";
import axiosInstance from "../../../utils/axiosInstance";
import axios from "axios";
import { FaCamera } from "react-icons/fa";

interface ProfilePictureProps {
  userContextData?: any;
  username: string;
  profile: string;
  user_id?: string;
  height: any;
  width: any;
  showUpdate?: any;
  fontSize?: any;
}

const ProfilePictureHead: React.FC<ProfilePictureProps> = ({
  username,
  profile,
  user_id,
  userContextData,
  height,
  width,
  showUpdate,
  fontSize,
}) => {
  const [imageFile, setImageFile] = useState<any>(null);
  const imageInputRef: any = useRef();

  const handleImageChange = async (event: any) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setImageFile(selectedFile);
      const fileExtension = selectedFile.name.split(".");
      const imageURL = `${uuidv4()}.${fileExtension[fileExtension.length - 1]}`;
      const uploadLink = await axiosInstance.get(
        `/misc/getPresignedURL?filename=${imageURL}`
      );

      console.log(imageURL.length);
      console.log(imageURL, "image urllll");

      try {
        const uploadImage = await axios.put(uploadLink.data.url, selectedFile, {
          headers: {
            "Content-Type": selectedFile.type,
          },
        });
        const response = await axiosInstance.patch("/users", {
          user_id: user_id,
          profile_picture: uploadLink.data.business_id + "/" + imageURL,
        });
        userContextData();
        setImageFile(null);
      } catch (error: any) {
        console.log(error);
      }
    }
  };

  return (
    <div
      onClick={() => showUpdate !== "hidden" && imageInputRef.current.click()}
      className="cursor-pointer"
    >
      {imageFile ? (
        <div className={`${height} ${width}`}>
          <img
            src={URL.createObjectURL(imageFile)}
            className={`h-full w-full rounded-full bg-gray-200`}
            alt="profilepicture"
          />
        </div>
      ) : profile ? (
        <div className={`${height} ${width}`}>
          <img
            src={`https://d1y0322yg73vdw.cloudfront.net/business_data/${profile}?alt=media`}
            className={`rounded-full h-full w-full bg-gray-200 object-cover`}
            alt="profilepicture"
          />
        </div>
      ) : (
        <div
          className={`bg-gray-200 rounded-full ${height} ${width} ${fontSize} flex items-center justify-center font-medium`}
        >
          <div>{truncateName(username).slice(0, 2)}</div>
        </div>
      )}
      <div className={`translate-x-20 -translate-y-11 ${showUpdate}`}>
        <FaCamera className="h-6 w-6 p-1 bg-primaryColor text-whiteColor rounded-full" />
      </div>
      <input
        type="file"
        ref={imageInputRef}
        style={{ display: "none" }}
        accept="image/*"
        onChange={handleImageChange}
      />
    </div>
  );
};

export default ProfilePictureHead;
