import React, { useState } from "react";
import PopupModal from "../../../../globalComponents/popupModal";
import axiosInstance from "../../../../../utils/axiosInstance";
import notificationShow from "../../../../globalComponents/notificationService";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, Form, Select } from "antd";
import ProfilePictureHead from "../../../../globalComponents/profilePictureHead/profilePictureHead";
import ProfilePictureHeadGroup from "../../../../globalComponents/profilePictureHead/profilePictureHeadGroup";

const ActionsSingleTeamMembers = ({ userData, getUserData }: any) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<any>([]);
  const [action, setAction] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [archiveTitle, setArchiveTitle] = useState<string>("");
  const [archiveUnarchivePopupModal, setArchiveUnarchivePopupModal] =
    useState<boolean>(false);
  const [deleteUserPopupModal, setDeleteUserPopupModal] =
    useState<boolean>(false);
  const [updateManagersPopupModal, setUpdateManagersPopupModal] =
    useState<boolean>(false);

  const getUsers = async () => {
    try {
      const response = await axiosInstance.get("/users/all");
      setUsers(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const managers = users?.map((member: any) => {
    return { value: member._id, label: member.full_name };
  });

  const archiveUnarchiveUser = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        `settings/team/archiveUnarchive `,
        {
          user_id: userData._id,
          action: action,
        }
      );
      getUserData();
      notificationShow({
        type: "success",
        content: `${userData.full_name} has been ${
          action === "archived" ? "Archived" : "Unarchived"
        }!`,
      });
      setArchiveUnarchivePopupModal(false);
      setLoading(false);
      setError("");
    } catch (error: any) {
      console.log(error);
      setError(error.response.data.error);
      setLoading(false);
    }
  };

  const showArchiveUnarchiveModal = async () => {
    setArchiveUnarchivePopupModal(true);
    setLoading(false);
  };
  const onArchiveUnarchiveModalCancel = () => {
    setArchiveUnarchivePopupModal(false);
    setError("");
  };
  const onArchiveUnarchiveModalOkay = () => {
    archiveUnarchiveUser();
  };

  const updateManagers = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const formValues = form.getFieldsValue();
      const response = await axiosInstance.patch(`/users`, {
        user_id: userData._id,
        manager_ids: formValues.managers,
      });
      getUserData();
      notificationShow({
        type: "success",
        content: `${userData.full_name}'s managers has been updated!`,
      });
      form.resetFields();
      setUpdateManagersPopupModal(false);
      setLoading(false);
      setError("");
    } catch (error: any) {
      console.log(error);
      setError(error.response.data.error);
      setLoading(false);
    }
  };

  const showUpdateManagersModal = async () => {
    setUpdateManagersPopupModal(true);
    setLoading(false);
  };
  const onUpdateManagersModalCancel = () => {
    setUpdateManagersPopupModal(false);
    setError("");
  };
  const onUpdateManagersModalOkay = () => {
    updateManagers();
  };

  const deleteUser = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.delete(
        `settings/team/user/${userData._id}`
      );
      notificationShow({
        type: "success",
        content: `${userData.full_name} had been deleted Permanently!`,
      });
      navigate("/dashboard/team-members");
      setDeleteUserPopupModal(false);
      setLoading(false);
      setError("");
    } catch (error: any) {
      console.log(error);
      setError(error.response.data.error);
      setLoading(false);
    }
  };

  const showDeleteUserModal = async () => {
    setDeleteUserPopupModal(true);
    setLoading(false);
  };
  const onDeleteUserModalCancel = () => {
    setDeleteUserPopupModal(false);
    setError("");
  };
  const onDeleteUserModalOkay = () => {
    deleteUser();
  };

  return (
    <div className="flex flex-col gap-8 p-4 bg-primaryBackground rounded-md text-primaryText">
      <div className="flex flex-col items-start gap-4 min-h-[22vh]">
        <div className="text-xl font-medium">Managers</div>
        <div>
          Managers will receive updates and requests related to this user.
        </div>

        <div className="flex flex-col gap-2">
          <div>Current Manager(s)</div>
          <Avatar.Group>
            {userData.manager_ids.map((manager: any) => {
              return (
                <Link to={`/dashboard/team-members/${manager._id}`}>
                  <ProfilePictureHeadGroup
                    profile={manager.profile_picture}
                    username={manager.full_name}
                  />
                </Link>
              );
            })}
          </Avatar.Group>
        </div>

        <div
          onClick={() => {
            getUsers();
            showUpdateManagersModal();
          }}
          className="px-4 py-2 rounded-md mr-auto text-whiteColor font-semibold bg-primaryColor cursor-pointer"
        >
          Update Mangers
        </div>
      </div>
      <div className="flex flex-col items-start gap-4 min-h-[22vh]">
        <div className="text-xl font-medium">Archive User</div>
        {!userData.archived && (
          <>
            <div className="">
              Archiving a user will deactivate the account and can be unarchived
              at any time. This will not remove any data associated with this
              user. This will not free up member's slots.
            </div>
            {userData._id && (
              <div
                onClick={() => {
                  setAction("archived");
                  setArchiveTitle("Archive Confirmation!");
                  showArchiveUnarchiveModal();
                }}
                className="px-4 py-2 rounded-md mr-auto text-whiteColor font-semibold bg-primaryColor cursor-pointer"
              >
                Archive {userData.full_name}
              </div>
            )}
          </>
        )}
        {userData.archived && (
          <>
            <div className="">
              This user is currently archived and can be unarchived at any time.
            </div>
            {userData._id && (
              <div
                onClick={() => {
                  setAction("unarchived");
                  setArchiveTitle("Unarchive Confirmation!");
                  showArchiveUnarchiveModal();
                }}
                className="px-4 py-2 rounded-md mr-auto text-whiteColor font-semibold bg-approvedColor cursor-pointer"
              >
                Unarchive {userData.full_name}
              </div>
            )}
          </>
        )}
      </div>
      <div className="flex flex-col items-start gap-4 min-h-[22vh]">
        <div className="text-xl font-medium">Permanently Delete User</div>
        <div>
          Deleting this user will permanently remove all the data associated
          with this user and cannot be recovered.
        </div>
        {userData._id && (
          <div
            onClick={() => {
              showDeleteUserModal();
            }}
            className="px-4 py-2 rounded-md mr-auto text-whiteColor font-semibold bg-rejectedColor cursor-pointer"
          >
            Permanently Delete {userData.full_name}
          </div>
        )}
      </div>
      {archiveUnarchivePopupModal && (
        <PopupModal
          width={500}
          top="20vh"
          open={archiveUnarchivePopupModal}
          onCancel={onArchiveUnarchiveModalCancel}
          onOkay={onArchiveUnarchiveModalOkay}
          content={
            <div className="flex flex-col gap-6 p-2">
              <div className="text-xl font-semibold">{archiveTitle}</div>
              <div className="">
                Are you sure you want to{" "}
                {action === "archived" ? "Archive" : "Unarchive"} -{" "}
                <span className=" text-primaryColor font-semibold">
                  {userData.full_name}
                </span>{" "}
                ?
              </div>
              {error && (
                <div className="bg-red-50 rounded-md py-2 text-sm w-full text-center">
                  {error}
                </div>
              )}
            </div>
          }
          button1="Cancel"
          button2={action === "archived" ? "Archive" : "Unarchive"}
          hideButton1=""
          hideButton2=""
          button1Color="primaryColor"
          button2Color="white"
          button1BgColor="secondaryColor"
          button2BgColor={
            action === "archived" ? "rejectedColor" : "approvedColor"
          }
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      {updateManagersPopupModal && (
        <PopupModal
          width={500}
          top="20vh"
          open={updateManagersPopupModal}
          onCancel={onUpdateManagersModalCancel}
          onOkay={onUpdateManagersModalOkay}
          content={
            <div className="flex flex-col gap-6 p-2">
              <div className="text-xl font-semibold">Update Managers!</div>
              <Form
                layout="vertical"
                form={form}
                className="flex flex-col w-full"
              >
                <Form.Item
                  required
                  name="managers"
                  label={<div className="">Manager</div>}
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Manager is required.",
                    },
                  ]}
                  className="font-medium"
                >
                  <Select
                    showSearch
                    mode="multiple"
                    allowClear
                    options={managers}
                    bordered={false}
                    className="border border-gray-300 rounded-md min-h-10 px-1 w-full"
                  />
                </Form.Item>
              </Form>
              {error && (
                <div className="bg-red-50 rounded-md py-2 text-sm w-full text-center">
                  {error}
                </div>
              )}
            </div>
          }
          button1="Cancel"
          button2="Update Managers"
          hideButton1=""
          hideButton2=""
          button1Color="primaryColor"
          button2Color="white"
          button1BgColor="secondaryColor"
          button2BgColor="primaryColor"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      {deleteUserPopupModal && (
        <PopupModal
          width={500}
          top="20vh"
          open={deleteUserPopupModal}
          onCancel={onDeleteUserModalCancel}
          onOkay={onDeleteUserModalOkay}
          content={
            <div className="flex flex-col gap-6 p-2">
              <div className="text-xl font-semibold">
                Delete User Confirmation!
              </div>
              <div className="">
                Are you sure you want to permanently delete -{" "}
                <span className=" text-rejectedColor font-semibold">
                  {userData.full_name}
                </span>{" "}
                ?
              </div>
              {error && (
                <div className="bg-red-50 rounded-md py-2 text-sm w-full text-center">
                  {error}
                </div>
              )}
            </div>
          }
          button1="Cancel"
          button2="Delete User"
          hideButton1=""
          hideButton2=""
          button1Color="primaryColor"
          button2Color="white"
          button1BgColor="secondaryColor"
          button2BgColor="rejectedColor"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}
    </div>
  );
};

export default ActionsSingleTeamMembers;
