import React from "react";
import { Route, Routes } from "react-router-dom";

import EmployeeDashboardLayout from "../components/layouts/employeeDashboardLayout";
import EmployeeOverview from "../components/pageComponents/employeeDashboard/employee-overview";
import EmployeePayslip from "../components/pageComponents/employeeDashboard/employee-payslips";
import EmployeeMyProfile from "../components/pageComponents/employeeDashboard/employee-my-profile";
import EmployeeSettings from "../components/pageComponents/employeeDashboard/employee-settings";
import EmployeeTeamMembers from "../components/pageComponents/employeeDashboard/employee-team-members";
import EmployeeTimeOff from "../components/pageComponents/employeeDashboard/employee-timeoff";
import EmployeeSingleTeamMember from "../components/pageComponents/employeeDashboard/employee-single-team-member";
import EmployeeTasks from "../components/pageComponents/employeeDashboard/employee-tasks";
import EmployeeWorkLog from "../components/pageComponents/employeeDashboard/employee-worklog/employee-worklog";
import AttendanceCalender from "../components/globalComponents/pageComponents/attendance/attendance-calender";

const EmployeeDashboard = () => {
  return (
    <EmployeeDashboardLayout>
      <Routes>
        <Route path="overview" element={<EmployeeOverview />} />
        <Route path="tasks" element={<EmployeeTasks />} />
        <Route path="team-members" element={<EmployeeTeamMembers />} />
        <Route
          path="team-members/:user_id"
          element={<EmployeeSingleTeamMember />}
        />
        <Route path="payslips" element={<EmployeePayslip />} />
        <Route path="attendance" element={<AttendanceCalender />} />
        <Route path="worklog" element={<EmployeeWorkLog />} />
        <Route path="time-off" element={<EmployeeTimeOff />} />
        <Route path="my-profile" element={<EmployeeMyProfile />} />
        <Route path="settings" element={<EmployeeSettings />} />
      </Routes>
    </EmployeeDashboardLayout>
  );
};

export default EmployeeDashboard;
