import React from "react";
import truncateName from "../../../utils/truncateName";
import { Tooltip } from "antd";

interface ProfilePictureHeadGroupProps {
  profile: string;
  username: string;
  index?: number;
}

const ProfilePictureHeadGroup = ({
  profile,
  username,
  index,
}: ProfilePictureHeadGroupProps) => {
  return profile ? (
    <Tooltip color="#663399" title={username} placement="top">
      <img
        src={`https://d1y0322yg73vdw.cloudfront.net/business_data/${profile}?alt=media`}
        className={`h-8 w-8 rounded-full bg-gray-200 object-cover cursor-pointer `}
        alt="profilepicture"
      />
    </Tooltip>
  ) : (
    <Tooltip color="#663399" title={username} placement="top">
      <div
        className={`bg-gray-200 text-primaryText h-8 w-8 text-[12px] rounded-full flex items-center justify-center font-semibold cursor-pointer`}
      >
        {truncateName(username).slice(0, 2)}
      </div>
    </Tooltip>
  );
};

export default ProfilePictureHeadGroup;
