import React from "react";

interface ShowLableValueInterface {
  details: any;
}

const ShowLableValueForDetails = ({ details }: ShowLableValueInterface) => {
  return (
    <div className="w-[90%] mx-auto flex flex-col gap-4 text-primaryText">
      {details.map((details: any, index: number) => {
        return (
          <div key={index} className="flex items-start gap-4 ">
            <div className="w-[15%] text-secondaryText break-all">
              {details.label}
            </div>
            <div className="w-[73%] font-medium break-all">{details.value}</div>
          </div>
        );
      })}
    </div>
  );
};

export default ShowLableValueForDetails;
