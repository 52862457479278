//truncate name, get first initials of name, himal kafle = hk
export default function truncateName(userName: string | undefined): string {
  if (!userName) {
    return "";
  }
  const words = userName.split(" ");
  let initials = "";

  for (let i = 0; i < words.length; i++) {
    const word = words[i].trim();
    if (word.length > 0) {
      initials += word[0].toUpperCase();
    }
  }

  return initials;
}
