import { Form, Input, Spin } from "antd";
import React, { useContext, useEffect, useState } from "react";
import axiosInstance from "../utils/axiosInstance";
import { Link } from "react-router-dom";
import notificationShow from "../components/globalComponents/notificationService";
import { userContext } from "../context/userContext";
import decodeAccessToken from "../utils/decodedAccessToken";
import NavbarChibiLogo from "../components/globalComponents/navbar-chibi-logo";

const SignIn = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const userContextData: any = useContext(userContext);

  useEffect(() => {
    userContextData.getConfig();
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const token: any = decodeAccessToken(accessToken);
      window.location.href = token.is_admin
        ? "/dashboard/overview"
        : "/employee/overview";
    }
  });
  const [error, setError] = useState<string>("");

  const login = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const formValues = form.getFieldsValue();
      const response = await axiosInstance.post("/users/login", {
        email: formValues.email,
        password: formValues.password,
      });
      console.log(response.data.accessToken);
      localStorage.setItem("accessToken", response.data.accessToken);
      const accessToken = localStorage.getItem("accessToken");
      const token: any = decodeAccessToken(accessToken);
      console.log(token.is_admin);
      window.location.href = token.is_admin
        ? "/dashboard/overview"
        : "/employee/overview";

      notificationShow({
        type: "success",
        content: "User logged in Successfully.",
      });
      form.resetFields();
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setError(error?.response?.data?.error);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-[100vh] gap-4 bg-secondaryBackground text-primaryText">
      <NavbarChibiLogo />
      <div className="flex items-center justify-center  p-12 rounded-md bg-primaryBackground">
        <div className="md:w-[25vw] w-full flex flex-col">
          <div className="flex flex-col w-full">
            <div className="mb-8 font-semibold md:text-4xl text-2xl text-center">
              Welcome Back!
            </div>
            <Form
              autoComplete="off"
              size="large"
              layout="vertical"
              form={form}
              className="w-full"
            >
              <Form.Item
                required
                name="email"
                label={<div className="font-semibold">Email Address</div>}
                rules={[
                  {
                    required: true,
                    message: "Email is required.",
                  },
                  {
                    pattern:
                      /^(?!.*\.\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Invalid email format.",
                  },
                ]}
                className="w-full"
              >
                <Input
                  type="email"
                  className="border border-gray-300 rounded-md w-full py-2 px-4"
                />
              </Form.Item>
              <Form.Item
                name="password"
                label={<div className="font-semibold">Password</div>}
                rules={[
                  {
                    required: true,
                    message: "Password is required.",
                  },
                ]}
                className="w-full"
              >
                <Input.Password className="border border-gray-300 rounded-md w-full py-2 px-4" />
              </Form.Item>
            </Form>
            <Link
              to="/forgot-password"
              className="ml-auto text-sm text-primaryColor cursor-pointer"
            >
              Forgot your password?
            </Link>
            <Spin spinning={loading}>
              <button
                onClick={login}
                className="bg-primaryColor text-white font-semibold px-4 py-2 rounded-md w-full my-8"
              >
                Sign in
              </button>
            </Spin>
            {error && (
              <div className="bg-red-50 text-red-500 rounded-md text-sm text-center py-2 mb-4">
                {error}
              </div>
            )}

            <div className="text-center">
              New to chibiHR?{" "}
              <Link to="/sign-up" className="text-primaryColor cursor-pointer">
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="text-[12px] text-center px-4">
        For assistance, contact our support team at{" "}
        <span className="font-semibold text-primaryColor">
          Contact@chibihr.com
        </span>
        .
      </div>
    </div>
  );
};

export default SignIn;
