import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../utils/axiosInstance";
import { Spin, Tabs } from "antd";
import { standard_date_only_format_frontend } from "../../../utils/dateAndTimeFormatter";
import dayjs from "dayjs";
import ProfilePictureHead from "../../globalComponents/profilePictureHead/profilePictureHead";
import TasksComponent from "../../globalComponents/pageComponents/tasks/tasks-component";
import decodeAccessToken from "../../../utils/decodedAccessToken";
import ShowLableValueForDetails from "../../globalComponents/showLabelValueForDetails";

const EmployeeSingleTeamMember = () => {
  const accessToken = localStorage.getItem("accessToken");
  const { _id }: any = decodeAccessToken(accessToken);
  const { user_id } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [tasks, setTasks] = useState<any>([]);
  const [taskStatus, setTaskStatus] = useState<string>("Open");
  const [tasksDetails, setTasksDetails] = useState<any>();

  const getUserData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/users?_id=${user_id}`);
      setUserData(response.data.data[0]);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserData();
  }, [user_id]);

  const getTasks = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/tasks?created_by=${_id}`);
      const filteredTasks = response.data.data.filter((item: any) =>
        item.assigned_to.some((assign: any) => assign._id === user_id)
      );
      setTasks(filteredTasks);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTasks();
  }, []);

  useEffect(() => {
    const myTasks = tasks.filter((task: any) => {
      return task.task_status === taskStatus;
    });
    if (myTasks) {
      setTasksDetails(myTasks);
    }
  }, [tasks, taskStatus]);

  return (
    <Spin spinning={loading}>
      <div className="flex flex-col">
        <div className="flex items-center gap-1 pb-6">
          <div
            onClick={() => {
              navigate("/employee/team-members");
            }}
            className="text-secondaryText cursor-pointer"
          >
            Team-members /
          </div>
          <div className="text-lg font-medium">{userData.full_name}</div>
        </div>
        <div className="p-4 rounded-md bg-primaryBackground flex flex-col gap-4">
          <div className="flex items-center gap-4">
            <ProfilePictureHead
              username={userData.full_name}
              profile={userData?.profile_picture}
              height="h-24"
              width="w-24"
              showUpdate="hidden"
              fontSize="text-xl"
            />

            <div className="flex flex-col gap-1 justify-center">
              <div className="font-semibold text-2xl text-primaryColor">
                {userData.full_name}
              </div>
              <div className=" text-secondaryText">{userData.position}</div>
            </div>
          </div>

          <Tabs
            defaultActiveKey="1"
            tabPosition="top"
            style={{ height: "auto" }}
            items={[
              {
                label: "Personal Info",
                key: "1",
                children: (
                  <div className="w-full flex flex-col gap-8">
                    <div className="flex flex-col gap-4">
                      <div className="text-xl font-medium">
                        Personal Details
                      </div>
                      <ShowLableValueForDetails
                        details={[
                          {
                            label: "Preferred Full Name",
                            value: userData?.full_name
                              ? userData.full_name
                              : "-",
                          },
                          {
                            label: "Gender",
                            value: userData?.gender ? userData?.gender : "-",
                          },
                          {
                            label: "Bio",
                            value: userData?.bio ? userData?.bio : "-",
                          },
                          {
                            label: "Date of Birth",
                            value: userData?.dob
                              ? dayjs(userData?.dob).format(
                                  standard_date_only_format_frontend
                                )
                              : "-",
                          },
                        ]}
                      />
                    </div>
                    <div className="flex flex-col gap-4">
                      <div className="text-xl font-medium">Contact Details</div>
                      <ShowLableValueForDetails
                        details={[
                          {
                            label: "Personal Email",
                            value: userData?.personal_email
                              ? userData?.personal_email
                              : "-",
                          },
                          {
                            label: "Personal Phone",
                            value: userData?.personal_phone
                              ? userData?.personal_phone
                              : "-",
                          },
                          {
                            label: "Current Address",
                            value: userData?.current_address
                              ? userData?.current_address
                              : "-",
                          },
                          {
                            label: "Permanent Address",
                            value: userData?.permanent_address
                              ? userData?.permanent_address
                              : "-",
                          },
                          {
                            label: "ID Type",
                            value: userData?.id_type ? userData?.id_type : "-",
                          },
                          {
                            label: "ID Number",
                            value: userData?.id_number
                              ? userData?.id_number
                              : "-",
                          },
                          {
                            label: "PAN Number",
                            value: userData?.pan_number
                              ? userData?.pan_number
                              : "-",
                          },
                        ]}
                      />
                    </div>
                  </div>
                ),
              },
              {
                label: "Tasks",
                key: "2",
                children: (
                  <TasksComponent
                    showTitle="hidden"
                    showFilterTasks="hidden"
                    getTasks={getTasks}
                    tasks={tasks}
                    setTasks={setTasks}
                    taskStatus={taskStatus}
                    setTaskStatus={setTaskStatus}
                    tasksDetails={tasksDetails}
                    setTasksDetails={setTasksDetails}
                    userName={userData.full_name}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </Spin>
  );
};

export default EmployeeSingleTeamMember;
