// App.js
import { Routes, Route, Navigate } from "react-router-dom";
import SignIn from "./pages/sign-in";
import CreateAccount from "./components/pageComponents/registration/create-account";
import UserContextFunction from "./context/userContext";
import AdminDashboard from "./pages/adminDashboard";
import EmployeeDashboard from "./pages/employeeDashboard";
import decodeAccessToken from "./utils/decodedAccessToken";
import RegisterEmail from "./components/pageComponents/registration/register-email";
import VerfiyEmail from "./components/pageComponents/registration/verify-email";
import AcceptTeamInvitation from "./pages/accept-team-invitation";
import Redirect from "./pages/redirect";
import ForgotPassword from "./pages/forgot-password";
import ResetPassword from "./pages/reset-password";
import EmployeeWorkLogHistory from "./components/pageComponents/employeeDashboard/employee-worklog/employee-worklog-history";

const App = () => {
  const accessToken = localStorage.getItem("accessToken");
  const token: any = decodeAccessToken(accessToken);

  return (
    <UserContextFunction>
      <Routes>
        <Route path="/" element={<Redirect />} />
        <Route path="/sign-up" element={<RegisterEmail />} />
        <Route path="/verify-email" element={<VerfiyEmail />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/accept-invitation" element={<AcceptTeamInvitation />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        {!accessToken && (
          <Route path="*" element={<Navigate to="/sign-in" />} />
        )}
        {accessToken && token.is_admin && (
          <>
            <Route path="/dashboard/*" element={<AdminDashboard />} />
          </>
        )}
        {accessToken && (!token.is_admin || token.is_admin) && (
          <>
            <Route path="/employee/*" element={<EmployeeDashboard />} />
            <Route
              path="/employee/worklog/history"
              element={<EmployeeWorkLogHistory />}
            />
          </>
        )}
      </Routes>
    </UserContextFunction>
  );
};

export default App;
