import { Checkbox, Drawer, Form, Input, Select, Spin } from "antd";
import React, { useState, useEffect, useContext } from "react";
import axiosInstance from "../../../../utils/axiosInstance";
import notificationShow from "../../notificationService";
import { useParams } from "react-router-dom";
import { userContext } from "../../../../context/userContext";
const { TextArea } = Input;

const EditTask = ({
  taskDetails,
  getTasks,
  openEditTaskDrawer,
  setOpenEDitTaskDrawer,
  userName,
  taskType,
}: any) => {
  const userContextData: any = useContext(userContext);
  const [form] = Form.useForm();
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [moreOptionsTab, setMoreOptionsTab] = useState<number[]>([]);

  const { user_id } = useParams();

  useEffect(() => {
    userContextData.getConfig();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      assigned_to: taskDetails?.assigned_to?.map((task: any) => {
        return { value: task?._id, label: task?.full_name };
      }),
      task_title: taskDetails?.task_title,
      task_description: taskDetails?.task_description,
    });
  }, [taskDetails]);

  const editTask = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const formValues = form.getFieldsValue();
      const response = await axiosInstance.patch("/tasks/operations", {
        task_id: taskDetails._id,
        task_title: formValues.task_title,
        task_description: formValues.task_description,
      });

      getTasks(taskType);
      setOpenEDitTaskDrawer(false);
      notificationShow({
        type: "success",
        content: "Task Updated Successfully!",
      });
      form.resetFields();
      setLoading(false);
      setError("");
    } catch (error: any) {
      setLoading(false);
      setError(error?.response?.data?.error);
    }
  };

  return (
    <Drawer
      placement="right"
      destroyOnClose={true}
      onClose={() => {
        setOpenEDitTaskDrawer(false);
      }}
      open={openEditTaskDrawer}
      className="h-full"
      title={
        <div className="py-1 text-xl text-primaryColor font-semibold">
          Edit Task
        </div>
      }
      width={500}
      style={{
        paddingLeft: "0px",
      }}
      footer={
        <div className="flex items-center justify-end gap-4 ml-auto">
          <div
            onClick={() => {
              setOpenEDitTaskDrawer(false);
            }}
            className="text-sm text-center font-semibold border border-gray-300 px-4 py-2 rounded-md cursor-pointer"
          >
            Cancel
          </div>
          <Spin spinning={loading} className="flex-1">
            <div
              onClick={() => {
                editTask();
              }}
              className="text-sm text-center font-semibold bg-primaryColor border-primaryColor border hover:bg-hoverPrimaryColor hover:border-hoverPrimaryColor transition-all duration-150 ease-in text-whiteColor px-4 py-2 rounded-md cursor-pointer"
            >
              Edit Task
            </div>
          </Spin>
        </div>
      }
    >
      <div className="flex flex-col gap-8">
        <Form
          autoComplete="off"
          size="large"
          layout="vertical"
          form={form}
          className="w-full"
        >
          <Form.Item
            required
            name="assigned_to"
            label={<div className="font-semibold">Assigned To</div>}
            className="w-full"
          >
            <Select
              disabled
              bordered={false}
              className="border border-gray-300 rounded-md w-full"
              mode="multiple"
              defaultValue={
                userName ? user_id : userContextData?.myDetails?._id
              }
              allowClear
            />
          </Form.Item>
          <div className="p-2 bg-gray-100 rounded-md">
            <Form.Item
              required
              label={<div className="font-semibold">Task Title</div>}
              className="w-full"
              name="task_title"
              rules={[
                {
                  required: true,
                  message: "Task title is required.",
                },
              ]}
            >
              <Input className="border border-gray-300 rounded-md w-full py-2 px-4" />
            </Form.Item>
            {moreOptionsTab.includes(1) && (
              <Form.Item
                required
                label={<div className="font-semibold">Task Description</div>}
                name="task_description"
              >
                <TextArea
                  rows={10}
                  className="border border-gray-300 rounded-md w-full py-2 px-4"
                />
              </Form.Item>
            )}
            <Checkbox
              onChange={() => {
                if (moreOptionsTab.includes(1)) {
                  setMoreOptionsTab(
                    moreOptionsTab.filter((item: any) => item !== 1)
                  );
                } else {
                  setMoreOptionsTab([...moreOptionsTab, 1]);
                }
              }}
              checked={moreOptionsTab.includes(1)}
            >
              More Options
            </Checkbox>
          </div>
          {error && (
            <div className="py-2 text-sm text-rejectedColor">{error}</div>
          )}
        </Form>
      </div>
    </Drawer>
  );
};

export default EditTask;
