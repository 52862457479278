import { Drawer, Form, Input, Spin, TimePicker } from "antd";
import React, { useState, useEffect } from "react";

import dayjs from "dayjs";
import {
  standard_time_only_format_backend,
  standard_time_only_format_frontend,
} from "../../../../utils/dateAndTimeFormatter";
import notificationShow from "../../notificationService";
import axiosInstance from "../../../../utils/axiosInstance";

const { TextArea } = Input;

const TrackingPolicyAddEditDrawer = ({
  policyDetails,
  title,
  policyAction,
  onClose,
  openPolicyDrawer,
  getPolicies,
  setOpenPolicyDrawer,
}: any) => {
  const [form] = Form.useForm();
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (policyAction === "edit-policy") {
      form.setFieldsValue({
        policy_name: policyDetails.policy_name,
        policy_description: policyDetails.policy_description,
        daily_start_time: dayjs(
          policyDetails.daily_start_time,
          standard_time_only_format_frontend
        ),
        daily_end_time: dayjs(
          policyDetails.daily_end_time,
          standard_time_only_format_frontend
        ),
        late_clock_minutes: policyDetails.late_clock_minutes,
        half_day_clock_minutes: policyDetails.half_day_clock_minutes,
        absent_day_clock_minutes: policyDetails.absent_day_clock_minutes,
      });
    }
    if (policyAction === "create-new-policy") {
      form.resetFields();
    }
  }, [policyAction, form]);

  const createNewPolicy = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const formValues = form.getFieldsValue();
      console.log(formValues);
      const response = await axiosInstance.post("/settings/policy", {
        policy_name: formValues.policy_name,
        policy_description: formValues.policy_description,
        daily_start_time: dayjs(formValues.daily_start_time).format(
          standard_time_only_format_backend
        ),
        daily_end_time: dayjs(formValues.daily_end_time).format(
          standard_time_only_format_backend
        ),
        late_clock_minutes: formValues.late_clock_minutes,
        half_day_clock_minutes: formValues.half_day_clock_minutes,
        absent_day_clock_minutes: formValues.absent_day_clock_minutes,
      });
      getPolicies();
      notificationShow({
        type: "success",
        content: "New Policy Created Successfully!",
      });
      form.resetFields();
      setOpenPolicyDrawer(false);
      setLoading(false);
      setError("");
    } catch (error: any) {
      setLoading(false);
      setError(error?.response?.data?.error);
    }
  };

  const editPolicy = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const formValues = form.getFieldsValue();
      const response = await axiosInstance.patch("/settings/policy", {
        policy_id: policyDetails._id,
        policy_name: formValues.policy_name,
        policy_description: formValues.policy_description,
        daily_start_time: dayjs(formValues.daily_start_time).format(
          standard_time_only_format_backend
        ),
        daily_end_time: dayjs(formValues.daily_end_time).format(
          standard_time_only_format_backend
        ),
        late_clock_minutes: formValues.late_clock_minutes,
        half_day_clock_minutes: formValues.half_day_clock_minutes,
        absent_day_clock_minutes: formValues.absent_day_clock_minutes,
      });
      getPolicies();
      notificationShow({
        type: "success",
        content: "Policy Updated Successfully!",
      });
      form.resetFields();
      setOpenPolicyDrawer(false);
      setLoading(false);
      setError("");
    } catch (error: any) {
      setLoading(false);
      setError(error?.response?.data?.error);
    }
  };

  return (
    <Drawer
      placement="right"
      onClose={onClose}
      open={openPolicyDrawer}
      className="h-full"
      width={500}
      title={
        <div className="py-1 text-xl text-primaryColor font-semibold">
          {title}
        </div>
      }
      style={{
        scrollbarWidth: "thin",
      }}
      footer={
        <div className="flex items-center justify-end gap-4">
          <div
            onClick={onClose}
            className="text-sm text-center font-semibold border border-gray-300 px-4 py-2 rounded-md cursor-pointer"
          >
            Cancel
          </div>
          <Spin spinning={loading} className="flex-1">
            <div
              onClick={() => {
                if (policyAction === "create-new-policy") {
                  createNewPolicy();
                }
                if (policyDetails && policyAction === "edit-policy") {
                  editPolicy();
                }
              }}
              className="text-sm text-center font-semibold bg-primaryColor border-primaryColor border hover:bg-hoverPrimaryColor hover:border-hoverPrimaryColor transition-all duration-150 ease-in text-whiteColor px-4 py-2 rounded-md cursor-pointer"
            >
              {policyAction === "create-new-policy"
                ? "Create New Policy"
                : "Edit Policy"}
            </div>
          </Spin>
        </div>
      }
    >
      <div className="flex flex-col gap-8 relative text-primaryText">
        <Form
          autoComplete="off"
          size="large"
          layout="vertical"
          form={form}
          className="w-full"
        >
          <Form.Item
            required
            name="policy_name"
            label={<div className="font-medium">Policy Name</div>}
            className="font-medium"
            rules={[
              {
                required: true,
                message: "Policy Name is required.",
              },
            ]}
          >
            <Input className="border border-gray-300 rounded-md w-full py-2 px-4" />
          </Form.Item>
          <Form.Item
            required
            name="policy_description"
            label={<div className="font-medium">Policy Description</div>}
            className="font-medium"
            rules={[
              {
                required: true,
                message: "Policy Description is required.",
              },
            ]}
          >
            <TextArea
              maxLength={150}
              rows={3}
              className="border border-gray-300 rounded-md w-full py-2 px-4"
            />
          </Form.Item>
          <div className="flex items-center gap-4 justify between">
            <Form.Item
              required
              name="daily_start_time"
              label={<div className="font-medium">Daily Start Time</div>}
              className="font-medium"
              rules={[
                {
                  required: true,
                  message: "Daily Start Time is required.",
                },
              ]}
            >
              <TimePicker
                className="border border-gray-300 rounded-md w-full py-2 px-4"
                format={standard_time_only_format_frontend}
              />
            </Form.Item>
            <Form.Item
              required
              name="daily_end_time"
              label={<div className="font-medium">Daily End Time</div>}
              className="font-medium"
              rules={[
                {
                  required: true,
                  message: "Daily End Time is required.",
                },
              ]}
            >
              <TimePicker
                className="border border-gray-300 rounded-md w-full py-2 px-4"
                format={standard_time_only_format_frontend}
              />
            </Form.Item>
          </div>
          <Form.Item
            required
            name="late_clock_minutes"
            label={<div className="font-medium">Late Clock Minutes</div>}
            className="font-medium"
            rules={[
              {
                required: true,
                message: "Late Clock Minutes is required.",
              },
            ]}
          >
            <Input
              type="number"
              className="border border-gray-300 rounded-md w-full py-2 px-4"
            />
          </Form.Item>
          <Form.Item
            required
            name="half_day_clock_minutes"
            label={<div className="font-medium">Half Day Clock Minutes</div>}
            className="font-medium"
            rules={[
              {
                required: true,
                message: "Half Day Clock Minute is required.",
              },
            ]}
          >
            <Input
              type="number"
              className="border border-gray-300 rounded-md w-full py-2 px-4"
            />
          </Form.Item>
          <Form.Item
            required
            name="absent_day_clock_minutes"
            label={<div className="font-medium">Absent Day Clock Minutes</div>}
            className="font-medium"
            rules={[
              {
                required: true,
                message: "Absent Day Clock Minutes is required.",
              },
            ]}
          >
            <Input
              type="number"
              className="border border-gray-300 rounded-md w-full py-2 px-4"
            />
          </Form.Item>

          {error && (
            <div className="bg-red-50 text-red-500 rounded-md text-sm text-center py-2">
              {error}
            </div>
          )}
        </Form>
      </div>
    </Drawer>
  );
};

export default TrackingPolicyAddEditDrawer;
