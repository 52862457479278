import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PiUserSwitchFill } from "react-icons/pi";
import PopupModal from "../globalComponents/popupModal";
import { AiOutlineCaretDown } from "react-icons/ai";

import tasks from "../../assets/menuIcons/tasks.svg";
import tasksActive from "../../assets/menuIcons/tasks-active.svg";
import timeoff from "../../assets/menuIcons/timeoff.svg";
import timeoffActive from "../../assets/menuIcons/timeoff-active.svg";
import payslips from "../../assets/menuIcons/payslip.svg";
import payslipsActive from "../../assets/menuIcons/payslip-active.svg";
import people from "../../assets/menuIcons/team.svg";
import peopleActive from "../../assets/menuIcons/team-active.svg";
import attendance from "../../assets/menuIcons/attendance.svg";
import attendaceActive from "../../assets/menuIcons/attendance-active.svg";
import overview from "../../assets/menuIcons/overview.svg";
import overviewActive from "../../assets/menuIcons/overview-active.svg";
import approved from "../../assets/approved-tick.svg";
import rejected from "../../assets/rejected-cross.svg";
import general from "../../assets/chibi-head.svg";
import logo from "../../assets/chibi-main-logo.svg";
import worklog from "../../assets/menuIcons/daily-worklog.svg";
import worklogActive from "../../assets/menuIcons/daily-worklog-active.svg";
import notification from "../../assets/Notification.svg";
import logoutIcon from "../../assets/icons/log-out.svg";
import settingsIcon from "../../assets/icons/settings.svg";

import { Dropdown, Menu, Spin } from "antd";
import axiosInstance from "../../utils/axiosInstance";
import { userContext } from "../../context/userContext";
import decodeAccessToken from "../../utils/decodedAccessToken";
import truncateLongerUsername from "../../utils/truncateLongerUsernames";
import ProfilePictureHead from "../globalComponents/profilePictureHead/profilePictureHead";

const EmployeeDashboardLayout = ({ children }: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const { is_admin }: any = decodeAccessToken(accessToken);
  const [loading, setLoading] = useState<boolean>(false);
  const userContextData: any = useContext(userContext);
  const [allNotifications, setAllNotifications] = useState<any>([]);

  useEffect(() => {
    userContextData.getConfig();
  }, []);

  const navigate = useNavigate();

  const { pathname } = useLocation();
  const path = pathname.split("/");

  const [popupModal, setPopupModal] = useState(false);

  const showModel = async () => {
    setPopupModal(true);
    setLoading(false);
  };
  const onCancel = () => {
    setPopupModal(false);
  };
  const onOkay = () => {
    localStorage.removeItem("accessToken");
    window.location.href = "/sign-in";
    setPopupModal(false);
  };

  const items: any = [
    {
      key: "1",
      label: (
        <div className="flex items-center cursor-pointer gap-2 border-b-[1px] pb-2">
          <ProfilePictureHead
            userContextData={userContextData}
            username={userContextData.myDetails.full_name}
            profile={userContextData.myDetails.profile_picture}
            user_id={userContextData.myDetails._id}
            height="h-12"
            width="w-12"
            showUpdate="hidden"
          />
          <div className="flex flex-col gap-1">
            <div className="text-lg font-medium">
              {truncateLongerUsername(userContextData.myDetails.full_name, 20)}
            </div>
            <Link
              to="/employee/my-profile"
              className="text-black hover:text-primaryColor"
            >
              View Profile
            </Link>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <Link
          to="/employee/settings"
          className="flex items-center gap-2 cursor-pointer"
        >
          <img src={settingsIcon} alt="settings" className="h-6 w-6" />
          <span>Settings</span>
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <div
          onClick={() => {
            showModel();
          }}
          className="flex items-center gap-2 cursor-pointer"
        >
          <img src={logoutIcon} alt="logout" className="h-6 w-6" />
          <span>Sign out</span>
        </div>
      ),
    },
  ];

  const getNotification = async () => {
    try {
      setLoading(true);
      const reponse = await axiosInstance.get("/users/notifications");
      setAllNotifications(reponse.data.data);
      userContextData.getConfig();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const items1 =
    allNotifications &&
    allNotifications.map((notification: any, index: any) => ({
      key: index.toString(),
      label: (
        <div
          key={index}
          className={`${
            !notification.seen && "text-primaryColor"
          } flex items-center justify-between w-full rounded-md my-1`}
        >
          <div className="w-[10%] flex items-center justify-center">
            {notification.notification_type.toLowerCase() ===
              "timeoff_approved" && (
              <div className="w-8 h-8 rounded-full bg-approvedColor p-1 flex items-center justify-center">
                <img className="h-4 w-4" src={approved} alt="approved" />
              </div>
            )}
            {notification.notification_type.toLowerCase() ===
              "timeoff_rejected" && (
              <div className="h-8 w-8 rounded-full bg-rejectedColor p-1 flex items-center justify-center">
                <img className="h-4 w-4" src={rejected} alt="rejected" />
              </div>
            )}
            {notification.notification_type.toLowerCase() === "general" && (
              <img className="h-8 w-8" src={general} alt="general" />
            )}
          </div>
          <div className="w-[86%] break-all">{notification.text}</div>
        </div>
      ),
    }));

  const dropdownMenu = (
    <div>
      <div className="bg-whiteColor text-xl font-semibold px-6 py-2 border-b-[1px]">
        Notifications
      </div>
      <div className="min-h-[60vh] bg-whiteColor flex flex-col">
        {loading ? (
          <Spin spinning={true} className="mt-[29vh] self-center" />
        ) : (
          <Menu>
            {items1.map((item: any) => (
              <Menu.Item key={item.key}>{item.label}</Menu.Item>
            ))}
          </Menu>
        )}
      </div>
    </div>
  );

  return (
    <div className="w-full flex flex-col  text-primaryText">
      <div className="h-[10vh] bg-primaryBackground border-b-[1px] flex items-center w-full">
        <div className="w-[18vw]">
          <div className="w-full  flex items-center justify-center ">
            <img
              onClick={() => {
                navigate("/employee/overview");
              }}
              src={logo}
              alt="logo"
              className="w-[55%] cursor-pointer"
            />
          </div>
        </div>
        <div className="flex items-center justify-between w-[85vw] px-6">
          {is_admin && (
            <div
              onClick={() => navigate("/dashboard/overview")}
              className="text-sm font-semibold bg-approvedColor text-whiteColor py-2 px-4 rounded-full cursor-pointer flex items-center gap-2"
            >
              <PiUserSwitchFill className="h-6 w-6" />{" "}
              <span>Switch to Admin View</span>
            </div>
          )}
          <div className="ml-auto flex items-center gap-8">
            <Dropdown
              overlayStyle={{
                top: "10vh",
                height: "70vh",
                width: "25vw",
                overflowY: "auto",
                padding: "0px",
                scrollbarWidth: "thin",
              }}
              overlay={dropdownMenu}
              className="notification-dropdown"
              placement="bottomRight"
              arrow
              trigger={["click"]}
            >
              <div
                onClick={() => {
                  getNotification();
                }}
                className="flex items-center relative cursor-pointer"
              >
                <div className="cursor-pointer h-7 w-7">
                  <img
                    src={notification}
                    alt="notification"
                    className="h-full w-full"
                  />
                </div>
                <div
                  className={`${
                    userContextData.notifications > 0
                      ? "bg-rejectedColor"
                      : "bg-pendingColor"
                  } absolute left-4 top-0 flex items-center justify-center h-4 w-4  text-white font-semibold rounded-full text-[10px]`}
                >
                  {userContextData.notifications}
                </div>
              </div>
            </Dropdown>
            <Dropdown
              menu={{ items }}
              placement="bottomRight"
              arrow
              trigger={["click"]}
            >
              <div className="flex items-center cursor-pointer rounded-full bg-secondaryBackground">
                <ProfilePictureHead
                  userContextData={userContextData.getConfig}
                  username={userContextData.myDetails.full_name}
                  profile={userContextData.myDetails.profile_picture}
                  user_id={userContextData.myDetails._id}
                  height="h-10"
                  width="w-10"
                  showUpdate="hidden"
                />
                <div className="flex items-center gap-1 px-2">
                  <div className="font-medium">
                    {truncateLongerUsername(
                      userContextData?.myDetails?.full_name,
                      12
                    )}
                  </div>
                  <AiOutlineCaretDown />
                </div>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>

      <div className="flex">
        <div className="w-[18vw] bg-primaryBackground border-r-[1px] p-6 h-full overflow-auto flex flex-col">
          <Link
            to="/employee/overview"
            className={`flex items-center gap-2 px-2 py-2 ${
              path[2] === "overview" &&
              "text-primaryColor bg-navbarActiveBackground"
            } hover:text-primaryColor transition-all duration-150 ease-in `}
          >
            <div>
              <img
                src={path[2] === "overview" ? overviewActive : overview}
                alt="overveiw"
              />
            </div>
            <span>Overview</span>
          </Link>

          <Link
            to="/employee/tasks"
            className={`flex items-center gap-2 px-2 py-2 ${
              path[2] === "tasks" &&
              "text-primaryColor bg-navbarActiveBackground"
            } hover:text-primaryColor transition-all duration-150 ease-in `}
          >
            <div>
              <img
                src={path[2] === "tasks" ? tasksActive : tasks}
                alt="tasks"
              />
            </div>
            <span>Tasks </span>
            <span
              className={`${
                userContextData.tasks > 0
                  ? " bg-approvedColor"
                  : "bg-pendingColor"
              } text-[12px] text-white bg-approvedColor font-semibold rounded-full flex items-center justify-center  h-5 w-5`}
            >
              {userContextData.tasks}
            </span>
          </Link>
          <Link
            to="/employee/payslips"
            className={`flex items-center gap-2 px-2 py-2 ${
              path[2] === "payslips" &&
              "text-primaryColor bg-navbarActiveBackground"
            } hover:text-primaryColor transition-all duration-150 ease-in `}
          >
            <div>
              <img
                src={path[2] === "payslips" ? payslipsActive : payslips}
                alt="payslips"
              />
            </div>
            <span>Payslips</span>
          </Link>
          <Link
            to="/employee/team-members"
            className={`flex items-center gap-2 px-2 py-2 ${
              path[2] === "team-members" &&
              "text-primaryColor bg-navbarActiveBackground"
            } hover:text-primaryColor transition-all duration-150 ease-in `}
          >
            <div>
              <img
                src={path[2] === "team-members" ? peopleActive : people}
                alt="people"
              />
            </div>
            <span>Team Members</span>
          </Link>

          <Link
            to="/employee/worklog"
            className={`flex items-center gap-2 px-2 py-2 ${
              path[2] === "worklog" &&
              "text-primaryColor bg-navbarActiveBackground"
            } hover:text-primaryColor transition-all duration-150 ease-in `}
          >
            <div>
              <img
                src={path[2] === "worklog" ? worklogActive : worklog}
                alt="people"
              />
            </div>
            <span>Daily WorkLogs</span>
          </Link>

          <Link
            to="/employee/attendance"
            className={`flex items-center gap-2 px-2 py-2 ${
              path[2] === "attendance" &&
              "text-primaryColor bg-navbarActiveBackground"
            } hover:text-primaryColor transition-all duration-150 ease-in `}
          >
            <div>
              <img
                src={path[2] === "attendance" ? attendaceActive : attendance}
                alt="attandence"
              />
            </div>
            <span>Attendance</span>
          </Link>

          <Link
            to="/employee/time-off"
            className={`flex items-center gap-2 px-2 py-2 ${
              path[2] === "time-off" &&
              "text-primaryColor bg-navbarActiveBackground"
            } hover:text-primaryColor transition-all duration-150 ease-in `}
          >
            <div>
              <img
                src={path[2] === "time-off" ? timeoffActive : timeoff}
                alt="time-off"
              />
            </div>
            <span>Timeoff</span>
          </Link>
        </div>
        <div
          style={{
            scrollbarWidth: "thin",
          }}
          className="w-[83vw] h-[90vh] overflow-auto p-6 bg-secondaryBackground  text-primaryText"
        >
          {children}
        </div>
        {popupModal && (
          <PopupModal
            width={500}
            top="20vh"
            open={popupModal}
            onCancel={onCancel}
            onOkay={onOkay}
            content={
              <div className="flex flex-col p-2 text-primaryText">
                <div className="text-xl font-semibold">
                  Sign out Confirmation!
                </div>
                <div className="py-6">Are you sure you want to signout ?</div>
              </div>
            }
            button1="Cancel"
            button2="Signout"
            hideButton1=""
            hideButton2=""
            button1Color="white"
            button2Color="white"
            button1BgColor="primaryColor"
            button2BgColor="rejectedColor"
            icon={"ok"}
            setLoading={setLoading}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
};

export default EmployeeDashboardLayout;
