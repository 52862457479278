import axios from "axios";

const axiosInstance = axios.create({
  // baseURL: "http://192.168.101.6:4000/api/v1",
  baseURL: "https://api.chibihr.com/api/v1",
  // baseURL: "http://localhost:4000/api/v1",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("accessToken"),
  },
});

export default axiosInstance;
