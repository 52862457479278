import { Form, Input, Spin } from "antd";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import axiosInstance from "../utils/axiosInstance";
import { IoIosCheckmarkCircle } from "react-icons/io";
import NavbarChibiLogo from "../components/globalComponents/navbar-chibi-logo";

const ForgotPassword = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [emailVerified, setEmailVerified] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const registerEmail = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const formValues = form.getFieldsValue();
      setEmail(formValues.email);
      const response = await axiosInstance.post(`/users/forgotPassword`, {
        email: formValues.email,
      });
      setEmailVerified(true);
      form.resetFields();
      setLoading(false);
    } catch (error: any) {
      setError(error?.response?.data?.error);
      setEmailVerified(false);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-[100vh] gap-4 bg-secondaryBackground  text-primaryText">
      <NavbarChibiLogo />
      <div className="flex items-center justify-center  p-12 rounded-md bg-primaryBackground">
        {emailVerified ? (
          <div className="w-[40vw] flex flex-col items-center justify-center gap-6">
            <IoIosCheckmarkCircle className="h-16 w-16 text-green-500" />
            <div className="text-center md:text-xl text-green-500">
              Reset password link has been sent to your email address -{" "}
              <span className="md:text-2xl text-xl  font-semibold">
                {email}
              </span>
            </div>
            <Link
              to="/sign-in"
              className="bg-primaryColor text-white font-semibold px-4 py-2 rounded-md"
            >
              Back to Login
            </Link>
          </div>
        ) : (
          <div className="w-full md:w-[25vw] flex flex-col">
            <div className="flex flex-col items-center gap-4 text-center mb-8">
              <div className="font-semibold text-2xl md:text-4xl ">
                Reset Your Password
              </div>
              <div className="text-center">
                Please enter your email address. We'll send you a password reset
                link.{" "}
              </div>
            </div>
            <Form autoComplete="off" size="large" layout="vertical" form={form}>
              <Form.Item
                required
                name="email"
                label={<div className="font-semibold">Email Address</div>}
                rules={[
                  {
                    required: true,
                    message: "Email is required.",
                  },
                  {
                    pattern:
                      /^(?!.*\.\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Invalid email format.",
                  },
                ]}
                className="w-full"
              >
                <Input className="border border-gray-300 rounded-md w-full py-2 px-4" />
              </Form.Item>
            </Form>
            {error && (
              <div className="bg-red-50 text-red-500 rounded-md text-sm text-center py-2 mb-4">
                {error}
              </div>
            )}
            <Spin spinning={loading}>
              <button
                onClick={registerEmail}
                className="bg-primaryColor text-white font-semibold px-4 py-2 rounded-md w-full"
              >
                Reset Password
              </button>
            </Spin>
            <div className="text-center mt-8">
              Already using chibiHR?{" "}
              <Link to="/sign-in" className="text-primaryColor cursor-pointer">
                Sign in here
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
