import { Form, Input, Spin } from "antd";
import React, { useState } from "react";
import axiosInstance from "../utils/axiosInstance";
import notificationShow from "../components/globalComponents/notificationService";
import NavbarChibiLogo from "../components/globalComponents/navbar-chibi-logo";

const ResetPassword = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const urlParams = new URLSearchParams(window.location.search);
  const emailToken: any = urlParams.get("token");

  const resetPassword = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const formValues = form.getFieldsValue();
      const response = await axiosInstance.post("users/resetPassword", {
        email_token: emailToken,
        password: formValues.password,
        confirm_password: formValues.confirm_password,
      });
      notificationShow({
        type: "success",
        content: "Password reset Successfully.",
      });
      window.location.href = "/sign-in";
      form.resetFields();
      setLoading(false);
    } catch (error: any) {
      setError(error?.response?.data?.error);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-[100vh] gap-4 bg-secondaryBackground text-primaryText">
      <NavbarChibiLogo />
      <div className="flex items-center justify-center  p-12 rounded-md bg-primaryBackground">
        <div className="w-full md:w-[25vw] flex flex-col">
          <div className="text-center text-2xl md:text-4xl font-semibold mb-8">
            Reset Your Password
          </div>
          <Form autoComplete="off" size="large" layout="vertical" form={form}>
            <Form.Item
              name="password"
              label={<div className="font-semibold">Password</div>}
              className="w-full"
              rules={[
                {
                  required: true,
                  message: "Password is required.",
                },
              ]}
            >
              <Input.Password
                type="password"
                className="border border-gray-300 rounded-md w-full py-2 px-4"
              />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              label={<div className="font-semibold">Confirm Password</div>}
              className="w-full"
              rules={[
                {
                  required: true,
                  message: "Confirm Password is required.",
                },
              ]}
            >
              <Input.Password
                type="password"
                className="border border-gray-300 rounded-md w-full py-2 px-4"
              />
            </Form.Item>
          </Form>
          {error && (
            <div className="bg-red-50  mb-4 text-red-500 rounded-md text-sm text-center py-2">
              {error}
            </div>
          )}
          <Spin spinning={loading}>
            <button
              onClick={resetPassword}
              className="bg-primaryColor text-white font-semibold px-4 py-2 rounded-md w-full"
            >
              Reset Password
            </button>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
