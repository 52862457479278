import React, { useState, useEffect, useContext, useRef } from "react";
import { Table, Checkbox, Spin, Tag, Select, Avatar } from "antd";
import { Radio } from "antd";
import { RiDeleteBin2Fill } from "react-icons/ri";
import dayjs from "dayjs";
import { userContext } from "../../../../context/userContext";
import axiosInstance from "../../../../utils/axiosInstance";
import notificationShow from "../../notificationService";
import truncateLongerUsername from "../../../../utils/truncateLongerUsernames";
import { standard_date_only_format_frontend } from "../../../../utils/dateAndTimeFormatter";
import ProfilePictureHeadGroup from "../../profilePictureHead/profilePictureHeadGroup";
import CreateNewTask from "./create-new-task";
import TaskDetailsDrawer from "./task-details-drawer";

interface TaskComponentInterface {
  showTitle?: string;
  showFilterTasks?: string;
  users?: any;
  getTasks?: any;
  tasks?: any;
  setTasks?: any;
  taskType?: any;
  setTaskType?: any;
  taskStatus?: any;
  setTaskStatus?: any;
  tasksDetails?: any;
  setTasksDetails?: any;
  userName?: string;
  showTaskInfo?: string;
}

const TasksComponent = ({
  showTitle,
  showFilterTasks,
  users,
  getTasks,
  tasks,
  taskType,
  setTaskType,
  taskStatus,
  setTaskStatus,
  tasksDetails,
  userName,
  showTaskInfo,
}: TaskComponentInterface) => {
  const userContextData: any = useContext(userContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTasks, setSelectedTasks] = useState<string[]>([]);
  const [allTasksChecked, setAllTasksChecked] = useState<boolean>(false);
  const [openCreateNewTaskDrawer, setOpenCreateNewTaskDrawer] = useState(false);
  const [openTaskDetailsDrawer, setOpenTaskDetailsDrawer] = useState(false);
  const [clickedRowTaskId, setClickedRowTaskId] = useState<string>("");
  const tableRef: any = useRef(null);

  const onChange = (e: any) => {
    setTaskType(e.target.value);
  };

  useEffect(() => {
    setAllTasksChecked(selectedTasks.length === tasks.length);
  }, [selectedTasks, tasks]);

  const checkAllTasks = () => {
    if (allTasksChecked) {
      setSelectedTasks([]);
    } else {
      setSelectedTasks(tasks.map((task: any) => task._id));
    }
  };

  const handleSingleTaskCheckboxChange = (taskId: string) => {
    if (selectedTasks.includes(taskId)) {
      setSelectedTasks((prevSelected) =>
        prevSelected.filter((id) => id !== taskId)
      );
    } else {
      setSelectedTasks([...selectedTasks, taskId]);
    }
  };

  const showTaskDetalsDrawer = () => {
    setOpenTaskDetailsDrawer(true);
  };

  const markTaskAsClosed = async (selectedTasks: any) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        `tasks/operations/markAsClose`,
        { task_ids: selectedTasks }
      );
      userContextData.getConfig();
      getTasks(taskType);
      setOpenTaskDetailsDrawer(false);
      setSelectedTasks([]);
      setLoading(false);
      notificationShow({
        type: "success",
        content: "Task(s) marked as Closed!",
      });
    } catch (error: any) {
      setLoading(false);
      notificationShow({
        type: "error",
        content: error?.response?.data?.error,
      });
    }
  };

  const markTaskAsOpen = async (selectedTasks: any) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(`tasks/operations/markAsOpen`, {
        task_ids: selectedTasks,
      });
      userContextData.getConfig();
      getTasks(taskType);
      setSelectedTasks([]);
      setOpenTaskDetailsDrawer(false);

      setLoading(false);
      notificationShow({
        type: "success",
        content: "Task(s) marked as Open!",
      });
    } catch (error: any) {
      setLoading(false);
      notificationShow({
        type: "error",
        content: error?.response?.data?.error,
      });
    }
  };

  const deleteTask = async (selectedTasks: any) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(`tasks/operations/delete`, {
        task_ids: selectedTasks,
      });
      userContextData.getConfig();
      getTasks(taskType);
      setSelectedTasks([]);
      setLoading(false);
      notificationShow({
        type: "success",
        content: "Task(s) deleted Successfully!",
      });
    } catch (error: any) {
      setLoading(false);
      notificationShow({
        type: "error",
        content: error?.response?.data?.error,
      });
    }
  };

  return (
    <div>
      <div className={`${showTitle} pb-6 flex items-center justify-between`}>
        <div className="text-3xl font-medium ">Tasks</div>
      </div>
      <div className="flex flex-col gap-4 bg-primaryBackground p-4 rounded-md">
        <div className="flex flex-col gap-4 w-full">
          <div className="flex items-center justify-between">
            <div className={` flex items-center gap-8`}>
              <div className={`${showFilterTasks}`}>
                <Radio.Group onChange={onChange} value={taskType}>
                  <Radio value="assigned_to">My Tasks</Radio>
                  <Radio value="created_by">Assigned by Me</Radio>
                </Radio.Group>
              </div>
              <Select
                bordered={false}
                className="border border-gray-300 rounded-md w-24"
                defaultValue="Open"
                allowClear
                showSearch={true}
                onChange={(value: any) => {
                  setSelectedTasks([]);
                  setTaskStatus(value);
                }}
                options={[
                  {
                    value: "Open",
                    label: "Open",
                  },
                  {
                    value: "Closed",
                    label: "Closed",
                  },
                ]}
              />
              <div
                className={`${showTaskInfo} bg-late px-2 py-1 text-sm text-white rounded-full`}
              >
                Only tasks created by you for this user will be visible here.
              </div>
            </div>
            <div className="ml-auto flex items-center gap-2">
              {selectedTasks.length > 0 && (
                <div className="flex items-center gap-2 text-sm">
                  <div className="text-primaryText font-semibold">
                    Bulk Actions
                  </div>
                  <Spin spinning={loading}>
                    <div
                      onClick={() => {
                        if (taskStatus === "Open") {
                          markTaskAsClosed(selectedTasks);
                        }
                        if (taskStatus === "Closed") {
                          markTaskAsOpen(selectedTasks);
                        }
                      }}
                      className={`
                        bg-approvedColor
                     font-semibold text-white px-4 py-2 rounded-md cursor-pointer`}
                    >
                      {taskStatus === "Open" && "Mark as Closed"}{" "}
                      {taskStatus === "Closed" && "Mark as Open"}
                    </div>
                  </Spin>

                  <Spin spinning={loading}>
                    <div
                      onClick={() => {
                        deleteTask(selectedTasks);
                      }}
                      className={`text-rejectedColor h-6 w-6 cursor-pointer`}
                    >
                      <RiDeleteBin2Fill className="h-full w-full" />
                    </div>
                  </Spin>
                </div>
              )}
              <div
                onClick={() => {
                  setOpenCreateNewTaskDrawer(true);
                }}
                className=" text-sm font-semibold bg-primaryColor border border-primaryColor hover:bg-hoverPrimaryColor hover:border-hoverPrimaryColor transition-all duration-150 ease-in text-white px-4 py-2 rounded-md cursor-pointer"
              >
                Create New Task
              </div>
            </div>
          </div>
        </div>
        <Spin spinning={loading}>
          <Table
            ref={tableRef}
            size="middle"
            columns={[
              {
                title: (
                  <Checkbox
                    onChange={checkAllTasks}
                    checked={allTasksChecked}
                  />
                ),
                dataIndex: "check",
                key: "check",
              },
              {
                title: "Task",
                dataIndex: "task",
                key: "task",
                width: "30%",
              },

              {
                title: "Assigned To",
                dataIndex: "assigned_to",
                key: "assigned_to",
              },
              {
                title: "Created By",
                dataIndex: "created_by",
                key: "created_by",
              },
              {
                title: "Created At",
                dataIndex: "created_at",
                key: "created_at",
              },
              {
                title: "Status",
                dataIndex: "status",
                key: "status",
              },
            ]}
            pagination={false}
            onRow={(record) => ({
              onClick: (event: any) => {
                const isCheckbox = event.target.closest(".ant-checkbox-input");
                if (!isCheckbox) {
                  setClickedRowTaskId(record.key);
                  showTaskDetalsDrawer();
                }
              },
            })}
            dataSource={
              tasksDetails &&
              tasksDetails?.map((task: any) => {
                return {
                  key: task._id,
                  check: (
                    <Checkbox
                      checked={selectedTasks.includes(task._id)}
                      onChange={() => handleSingleTaskCheckboxChange(task._id)}
                    />
                  ),
                  task: (
                    <div className="clip cursor-pointer text-primaryColor font-semibold">
                      {truncateLongerUsername(task.task_title, 40)}
                    </div>
                  ),

                  assigned_to: (
                    <Avatar.Group>
                      {task.assigned_to.map((name: any, index: number) => (
                        <ProfilePictureHeadGroup
                          index={index}
                          profile={name.profile_picture}
                          username={name.full_name}
                        />
                      ))}
                    </Avatar.Group>
                  ),
                  created_by: (
                    <ProfilePictureHeadGroup
                      profile={task?.created_by?.profile_picture}
                      username={task?.created_by?.full_name}
                    />
                  ),
                  created_at: dayjs(task.createdAt).format(
                    standard_date_only_format_frontend
                  ),
                  status: (
                    <div>
                      {(task.task_status === "Open" && (
                        <Tag
                          color=""
                          className="text-whiteColor bg-approvedColor font-semibold cursor-pointer"
                        >
                          {task.task_status}
                        </Tag>
                      )) ||
                        (task.task_status === "Closed" && (
                          <Tag
                            color=""
                            className="bg-rejectedColor text-whiteColor font-semibold cursor-pointer"
                          >
                            {task.task_status}
                          </Tag>
                        ))}
                    </div>
                  ),
                };
              })
            }
          />
        </Spin>
      </div>
      <CreateNewTask
        users={users}
        openCreateTaskDrawer={openCreateNewTaskDrawer}
        setOpenCreateTaskDrawer={setOpenCreateNewTaskDrawer}
        getTasks={getTasks}
        taskType={taskType}
        userName={userName}
      />

      <TaskDetailsDrawer
        taskId={clickedRowTaskId}
        markTaskAsOpen={markTaskAsOpen}
        markTaskAsClosed={markTaskAsClosed}
        openTaskDetailsDrawer={openTaskDetailsDrawer}
        setOpenTaskDetailsDrawer={setOpenTaskDetailsDrawer}
        getTasks={getTasks}
        userName={userName}
        taskType={taskType}
      />
    </div>
  );
};

export default TasksComponent;
