import { Form, Input, Select, Spin } from "antd";
import React, { useState } from "react";
import axiosInstance from "../../../utils/axiosInstance";
import { Link } from "react-router-dom";
import notificationShow from "../../globalComponents/notificationService";
import decodeAccessToken from "../../../utils/decodedAccessToken";
import NavbarChibiLogo from "../../globalComponents/navbar-chibi-logo";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
const moment = require("moment-timezone");

const CreateAccount = () => {
  const [form] = Form.useForm();
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const urlParams = new URLSearchParams(window.location.search);
  const email: any = urlParams.get("email");
  const code: any = urlParams.get("code");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const register = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const formValues = form.getFieldsValue();
      const response = await axiosInstance.post("/users/register", {
        first_name: formValues.first_name,
        last_name: formValues.last_name,
        email: email,
        email_verification_code: code,
        business_name: formValues.business_name,
        password: formValues.password,
        timezone: formValues.timezone,
      });
      localStorage.setItem("accessToken", response.data.accessToken);
      const accessToken = localStorage.getItem("accessToken");
      const token: any = decodeAccessToken(accessToken);
      console.log(token.is_admin);
      window.location.href = token.is_admin
        ? "/dashboard/overview"
        : "/employee/overview";

      notificationShow({
        type: "success",
        content: "Business Registered Successfully.",
      });
      setLoading(false);
      form.resetFields();
    } catch (error: any) {
      console.log(error);
      setError(error?.response?.data?.error);
      setLoading(false);
    }
  };

  const timeZones = moment.tz.names();
  const timeZoneOptions = timeZones.map((timeZone: any) => ({
    value: timeZone,
    label: timeZone,
  }));

  const timezoneguess = dayjs.tz.guess();
  return (
    <div className="flex flex-col items-center justify-center min-h-[100vh] gap-4 bg-secondaryBackground  text-primaryText py-12">
      <NavbarChibiLogo />
      <div className="flex items-center justify-center  p-12 rounded-md bg-primaryBackground">
        <div className="w-full md:w-[35vw] flex flex-col">
          <div className="flex flex-col items-center gap-4 text-center mb-8">
            <div className="font-semibold text-2xl md:text-4xl ">
              Get started with chibiHR
            </div>
            <div className="text-center">
              Manage all your employees from one single platfrom
            </div>
          </div>
          <Form autoComplete="off" size="large" layout="vertical" form={form}>
            <div className="flex items-center md:flex-row flex-col gap-2 md:gap-8">
              <Form.Item
                required
                name="first_name"
                label={<div className="font-semibold">First Name</div>}
                rules={[
                  {
                    required: true,
                    message: "First name is required.",
                  },
                ]}
                className="w-full"
              >
                <Input className="border border-gray-300 rounded-md w-full py-2 px-4" />
              </Form.Item>
              <Form.Item
                required
                name="last_name"
                label={<div className="font-semibold">Last Name</div>}
                rules={[
                  {
                    required: true,
                    message: "Last name is required.",
                  },
                ]}
                className="w-full"
              >
                <Input className="border border-gray-300 rounded-md w-full py-2 px-4" />
              </Form.Item>
            </div>
            <Form.Item
              label={<div className="font-semibold">Email Address</div>}
              className="w-full"
            >
              <Input
                defaultValue={email}
                disabled
                className="border border-gray-300 rounded-md w-full py-2 px-4"
              />
            </Form.Item>

            <Form.Item
              required
              name="business_name"
              label={<div className="font-semibold">Business Name</div>}
              rules={[
                {
                  required: true,
                  message: "Business name is required.",
                },
              ]}
              className="w-full"
            >
              <Input className="border border-gray-300 rounded-md w-full py-2 px-4" />
            </Form.Item>

            <Form.Item
              name="password"
              label={<div className="font-semibold">Password</div>}
              rules={[
                {
                  required: true,
                  message: "Password is required.",
                },
              ]}
              className="w-full"
            >
              <Input.Password
                type="password"
                className="border border-gray-300 rounded-md w-full py-2 px-4"
              />
            </Form.Item>
            <Form.Item
              required
              label="Timezone"
              name="timezone"
              className="custom-select-border"
              rules={[
                {
                  required: true,
                  message: "Timezone is required.",
                },
              ]}
              initialValue={timezoneguess ? timezoneguess : "Asia/Kathmandu"}
            >
              <Select
                bordered={false}
                className="border border-gray-300 rounded-md h-10 px-1 w-full"
                showSearch
                defaultValue={timezoneguess ? timezoneguess : "Asia/Kathmandu"}
                options={timeZoneOptions}
              />
            </Form.Item>

            {error && (
              <div className="bg-red-50 text-red-500 rounded-md text-sm text-center py-2">
                {error}
              </div>
            )}
          </Form>
          <Spin spinning={loading}>
            <button
              onClick={register}
              className="bg-primaryColor text-white font-semibold px-4 py-2 rounded-md w-full"
            >
              Get Started
            </button>
          </Spin>
          <div className="text-center mt-8">
            Already using chibiHR?{" "}
            <Link to="/sign-in" className="text-primaryColor cursor-pointer">
              Sign in here
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
