import { Form, Spin, Table, Tag, Input, Dropdown } from "antd";
import React, { useState, useEffect } from "react";
import axiosInstance from "../../../../../utils/axiosInstance";
import { standard_date_only_format_frontend } from "../../../../../utils/dateAndTimeFormatter";
import dayjs from "dayjs";
import { HiDotsVertical } from "react-icons/hi";
import notificationShow from "../../../../globalComponents/notificationService";
import PopupModal from "../../../../globalComponents/popupModal";
import { useNavigate } from "react-router-dom";
import approvedTick from "../../../../../assets/approved-tick.svg";
import rejectedCross from "../../../../../assets/rejected-cross.svg";
import truncateLongerUsername from "../../../../../utils/truncateLongerUsernames";
const { TextArea } = Input;

const TimeoffRequests = ({ user_id }: any) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [timeoffs, setTimeOffs] = useState<any>([]);
  const [timeoffId, setTimeoffId] = useState<string>("");

  const [error, setError] = useState<string>("");

  const getTimeOffs = async () => {
    try {
      setLoading(true);
      const response = user_id
        ? await axiosInstance.get(`/attendance/timeoffs?user_id=${user_id}`)
        : await axiosInstance.get(`/attendance/timeoffs`);
      setTimeOffs(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching time offs:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTimeOffs();
  }, []);

  const approveTimeoffRequest = async () => {
    try {
      const response = await axiosInstance.post(
        "/attendance/timeoffs/approveRejectTimeOff",
        {
          timeoff_id: timeoffId,
          action: "Approved",
        }
      );
      getTimeOffs();
      notificationShow({
        type: "success",
        content: `Timeoff request approved successfully!`,
      });
      setPopupModal(false);
    } catch (error: any) {
      console.log(error);
    }
  };
  const rejecteTimeoffRequest = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const formValues = form.getFieldsValue();
      const response = await axiosInstance.post(
        "/attendance/timeoffs/approveRejectTimeOff",
        {
          timeoff_id: timeoffId,
          action: "Rejected",
          rejection_reason: formValues.rejection_reason,
        }
      );
      getTimeOffs();
      notificationShow({
        type: "success",
        content: `Timeoff request rejected successfully!`,
      });
      form.resetFields();
      setPopupModal(false);
      setLoading(false);
      setError("");
    } catch (error: any) {
      setLoading(false);
      setError(error?.response?.data?.error);
    }
  };

  const timeOffColumns: any = [
    {
      title: "S.N",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Requested By",
      dataIndex: "requested_by",
      key: "requested_by",
    },
    {
      title: "Type",
      dataIndex: "timeoff_type",
      key: "timeoff_type",
    },
    {
      title: "Starts From",
      dataIndex: "from_date",
      key: "from_date",
    },
    {
      title: "Ends On",
      dataIndex: "to_date",
      key: "to_date",
    },
    {
      title: "No. Of Days",
      dataIndex: "no_of_days",
      key: "no_of_days",
    },

    {
      title: "Reason",
      dataIndex: "timeoff_reason",
      key: "timeoff_reason",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  const items: any = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            approveTimeoffRequest();
          }}
          className="flex items-center gap-2 px-2"
        >
          <img
            src={approvedTick}
            alt="approvedTick"
            className="bg-approvedColor h-4 w-4 rounded-full p-1"
          />
          <div>Approve</div>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            showModel();
          }}
          className="flex items-center gap-2 px-2"
        >
          <img
            src={rejectedCross}
            alt="rejected"
            className="bg-rejectedColor h-4 w-4 rounded-full p-1"
          />
          <div>Reject</div>
        </div>
      ),
    },
  ];

  const [popupModal, setPopupModal] = useState(false);

  const showModel = async () => {
    setPopupModal(true);
    setLoading(false);
  };
  const onCancel = () => {
    setPopupModal(false);
  };
  const onOkay = () => {
    rejecteTimeoffRequest();
  };

  return (
    <div className="p-4 bg-primaryBackground rounded-md text-primaryText">
      <div>
        <Spin spinning={loading} className="text-primaryText">
          <Table
            className="cursor-pointer"
            size="middle"
            columns={timeOffColumns}
            dataSource={
              timeoffs &&
              timeoffs?.map((timeoff: any, index: number) => {
                return {
                  key: timeoff._id,
                  requested_by: (
                    <div className="flex flex-col gap-1">
                      <div
                        onClick={() => {
                          navigate(
                            `/dashboard/team-members/${timeoff.user_id._id}`
                          );
                        }}
                        className="font-semibold text-primaryColor"
                      >
                        {truncateLongerUsername(
                          timeoff?.user_id?.full_name,
                          20
                        )}
                      </div>
                      <div className="text-[10px] text-secondaryText">
                        {dayjs(timeoff.createdAt).format(
                          standard_date_only_format_frontend
                        )}
                      </div>
                    </div>
                  ),
                  index: index + 1 + ".",
                  from_date: dayjs(timeoff?.from_date).format(
                    standard_date_only_format_frontend
                  ),
                  to_date: dayjs(timeoff?.to_date).format(
                    standard_date_only_format_frontend
                  ),

                  no_of_days: timeoff?.number_of_days,
                  timeoff_type: (
                    <div className="flex flex-col gap-1">
                      <div>{timeoff?.timeoff_type}</div>
                      {timeoff?.timeoff_type.toLowerCase() !==
                        "late arrival request" &&
                      timeoff?.timeoff_type.toLowerCase() !==
                        "early leave request" ? (
                        <div className="text-[10px] font-medium text-secondaryText">
                          {timeoff?.is_paid_leave ? (
                            <div>Paid Leave</div>
                          ) : (
                            <div>Unpaid Leave</div>
                          )}
                        </div>
                      ) : (
                        <div className="text-[10px] text-secondaryText">
                          <span>Expected Time-</span>
                          <span className="font-medium">
                            {timeoff?.expected_time}
                          </span>
                        </div>
                      )}
                    </div>
                  ),
                  timeoff_reason: (
                    <div className="clip max-w-[15vw]">
                      {timeoff?.timeoff_reason}
                    </div>
                  ),
                  status: (
                    <div>
                      {timeoff.status.toLowerCase() === "approved" ||
                      timeoff.status.toLowerCase() === "rejected" ? (
                        <Dropdown
                          menu={{ items }}
                          placement="bottomRight"
                          arrow
                          trigger={["click"]}
                        >
                          <div>
                            <div className="w-full flex items-center justify-between gap-2">
                              <Tag
                                color="#"
                                className={`${
                                  timeoff.status.toLowerCase() === "approved" &&
                                  "bg-approvedColor"
                                } ${
                                  timeoff.status.toLowerCase() === "rejected" &&
                                  "bg-rejectedColor"
                                } font-semibold`}
                              >
                                {timeoff.status}
                              </Tag>

                              <HiDotsVertical />
                            </div>
                            {timeoff.status.toLowerCase() === "approved" ? (
                              <div className="text-approvedColor font-semibold text-[12px]">
                                Approved By:{" "}
                                {truncateLongerUsername(
                                  timeoff.approved_by.full_name,
                                  15
                                )}
                              </div>
                            ) : (
                              <div className="text-rejectedColor text-[12px] font-semibold text-clip">
                                Rejected By:{" "}
                                {truncateLongerUsername(
                                  timeoff.rejected_by.full_name,
                                  15
                                )}
                              </div>
                            )}
                            {timeoff.status.toLowerCase() === "rejected" && (
                              <div className="clip max-w-[15vw] text-[12px] text-rejectedColor font-semibold">
                                {timeoff.rejection_reason}
                              </div>
                            )}
                          </div>
                        </Dropdown>
                      ) : (
                        <Dropdown
                          menu={{ items }}
                          placement="bottomRight"
                          arrow
                          trigger={["click"]}
                        >
                          <div className="flex items-center justify-between gap-2">
                            {timeoff?.status.toLowerCase() === "pending" && (
                              <Tag
                                color="#"
                                className="font-semibold bg-pendingColor"
                              >
                                {timeoff.status}
                              </Tag>
                            )}
                            <HiDotsVertical />
                          </div>
                        </Dropdown>
                      )}
                    </div>
                  ),
                };
              })
            }
            pagination={false}
            onRow={(record) => ({
              onClick: () => {
                setTimeoffId(record.key);
              },
            })}
          />
        </Spin>
      </div>
      {popupModal && (
        <PopupModal
          width={500}
          top="20vh"
          open={popupModal}
          onCancel={onCancel}
          onOkay={onOkay}
          content={
            <div className="flex flex-col gap-6 w-full p-2">
              <div className="text-xl font-semibold">
                Reason for Timeoff Rejection!
              </div>
              <Form layout="vertical" form={form} className="w-full">
                <Form.Item
                  required
                  className="font-medium"
                  label="Reason"
                  name="rejection_reason"
                  rules={[
                    {
                      required: true,
                      message: "Timeoff request rejection reason is required.",
                    },
                  ]}
                >
                  <TextArea rows={4} />
                </Form.Item>
              </Form>
              {error && (
                <div className="bg-red-50 text-red-500 rounded-md text-sm text-center py-2 w-full">
                  {error}
                </div>
              )}
            </div>
          }
          button1="Cancel"
          button2="Reject"
          hideButton1=""
          hideButton2=""
          button1Color="rejectedColor"
          button2Color="white"
          button1BgColor="secondaryColor"
          button2BgColor="rejectedColor"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}
    </div>
  );
};

export default TimeoffRequests;
