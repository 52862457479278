import React, { useEffect, useState } from "react";
import axiosInstance from "../../../../../utils/axiosInstance";
import { Form, Select, Spin } from "antd";
import PopupModal from "../../../../globalComponents/popupModal";
import notificationShow from "../../../../globalComponents/notificationService";
import ShowLableValueForDetails from "../../../../globalComponents/showLabelValueForDetails";

const SingleMemberPolicyDetails = ({ getUserData, userId, policyId }: any) => {
  const [allPolicies, setAllPolicies] = useState<any>([]);
  const [policyDetails, setPolicyDetails] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [membersTrackingPolicyLoading, setMembersTrackingPolicyLoading] =
    useState<boolean>(false);
  const [form] = Form.useForm();
  const [membersTrackingPolicyModal, setMembersTrackingPolicyModal] =
    useState(false);

  const getAllPolicies = async () => {
    try {
      const response = await axiosInstance.get("/settings/policies");
      setAllPolicies(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const nameOfAllPolicies = allPolicies.map((policy: any) => {
    return {
      value: policy._id,
      label: policy.policy_name,
    };
  });

  const getPolicyDetails = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `/settings/policies?_id=${policyId}`
      );
      setPolicyDetails(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPolicyDetails();
  }, [policyId]);

  const changeSingleMembersTrackingPolicy = async () => {
    try {
      setMembersTrackingPolicyLoading(true);
      await form.validateFields();
      const formValues = form.getFieldsValue();
      const response = await axiosInstance.patch("/users", {
        user_id: userId,
        policy_id: formValues.tracking_policy,
      });
      getUserData();
      notificationShow({
        type: "success",
        content: `Tracking policy has been changed successfully!`,
      });
      form.resetFields();
      setMembersTrackingPolicyModal(false);
      setMembersTrackingPolicyLoading(false);
    } catch (error) {
      console.log(error);
      setMembersTrackingPolicyLoading(false);
    }
  };

  const showMembersTrackingPolicyModal = async () => {
    getAllPolicies();
    setMembersTrackingPolicyModal(true);
  };
  const onMembersTrackingPolicyModalCancel = () => {
    setMembersTrackingPolicyModal(false);
  };
  const onMembersTrackingPolicyModalOkay = () => {
    changeSingleMembersTrackingPolicy();
  };

  return (
    <div className="flex flex-col text-primaryText">
      {loading ? (
        <Spin className="self-center mt-[25vh]" />
      ) : (
        <div className="flex flex-col gap-6 w-full">
          <div
            onClick={() => {
              showMembersTrackingPolicyModal();
            }}
            className="px-4 py-2 rounded-md bg-primaryColor text-white ml-auto cursor-pointer"
          >
            Change Tracking Policy
          </div>
          {policyDetails.map((policy: any, index: number) => {
            return (
              <div key={index} className="flex flex-col gap-8">
                <div className="flex flex-col gap-2">
                  <div className="text-lg font-medium">Policy Name</div>
                  <div className="text-md text-secondaryText w-[90%] mx-auto">
                    {policy.policy_name}
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="text-lg font-medium">Policy Description</div>
                  <div className="text-secondaryText w-[90%] mx-auto">
                    {policy.policy_description}
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="text-lg font-medium">Policy Details</div>
                  <ShowLableValueForDetails
                    details={[
                      {
                        label: "Daily Start Time",
                        value: policy.daily_start_time,
                      },
                      {
                        label: "Daily End Time",
                        value: policy.daily_end_time,
                      },
                      {
                        label: "Late Arrival Tolerance",
                        value: `${policy.late_clock_minutes} minutes`,
                      },
                      {
                        label: "Half Day Duration",
                        value: `${policy.half_day_clock_minutes} minutes`,
                      },
                      {
                        label: "Attendance Limit",
                        value: `${policy.absent_day_clock_minutes} minutes`,
                      },
                    ]}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
      {membersTrackingPolicyModal && (
        <PopupModal
          width={500}
          top="20vh"
          open={membersTrackingPolicyModal}
          onCancel={onMembersTrackingPolicyModalCancel}
          onOkay={onMembersTrackingPolicyModalOkay}
          content={
            <div className="flex flex-col gap-6 w-full p-2">
              <div className="text-xl font-semibold">
                Update Tracking Policy!
              </div>
              <Form layout="vertical" form={form} className="w-full">
                <Form.Item
                  required
                  name="tracking_policy"
                  label={<div className="">Tracking Policy</div>}
                  className="font-medium"
                  rules={[
                    {
                      required: true,
                      message: "Tracking Policy is required.",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    options={nameOfAllPolicies}
                    bordered={false}
                    className="border border-gray-300 rounded-md h-10 px-1 w-full"
                  />
                </Form.Item>
              </Form>
            </div>
          }
          button1="Cancel"
          button2="Update"
          hideButton1=""
          hideButton2=""
          button1Color="rejectedColor"
          button2Color="white"
          button1BgColor="secondaryColor"
          button2BgColor="primaryColor"
          icon={"ok"}
          setLoading={setMembersTrackingPolicyLoading}
          loading={membersTrackingPolicyLoading}
        />
      )}
    </div>
  );
};

export default SingleMemberPolicyDetails;
