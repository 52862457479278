import { Modal, Spin } from "antd";

const PopupModal = ({
  top,
  width,
  open,
  onCancel,
  onOkay,
  content,
  button1,
  button2,
  button1Color,
  button2Color,
  button1BgColor,
  button2BgColor,
  hideButton1,
  hideButton2,
  icon,
  setLoading,
  loading,
}: any) => {
  const buttonWidth = `${width / 2 - 72}px`;

  return (
    <Modal
      open={open}
      onOk={onOkay}
      onCancel={onCancel}
      width={width}
      closeIcon={true}
      style={{
        padding: "20px",
        top: top,
      }}
      className="form-modal"
      footer={
        <div className="flex justify-between px-2 pb-2">
          <Spin spinning={false}>
            <button
              onClick={() => {
                onCancel();
                setLoading(false);
              }}
              style={{ width: buttonWidth }}
              className={`py-2 border border-gray-300 text-black font-semibold rounded-md ${hideButton1}`}
            >
              {button1}
            </button>
          </Spin>
          <Spin spinning={loading}>
            <button
              onClick={() => {
                onOkay();
                setLoading(true);
              }}
              style={{ width: buttonWidth }}
              className={`${
                button2BgColor === "rejectedColor"
                  ? "bg-rejectedColor border border-rejectedColor"
                  : "bg-primaryColor hover:bg-hoverPrimaryColor border border-primaryColor hover:border-hoverPrimaryColor"
              } py-2 transition-all duration-150 ease-in text-${button2Color} font-semibold rounded-md ${hideButton2}`}
            >
              {button2}
            </button>
          </Spin>
        </div>
      }
    >
      {content}
    </Modal>
  );
};

export default PopupModal;
