import { Drawer, Dropdown } from "antd";
import React, { useState, useEffect } from "react";
import axiosInstance from "../../../../utils/axiosInstance";
import { standard_date_only_format_frontend } from "../../../../utils/dateAndTimeFormatter";
import dayjs from "dayjs";
import { HiDotsVertical } from "react-icons/hi";
import ProfilePictureHeadGroup from "../../profilePictureHead/profilePictureHeadGroup";
import edit from "../../../../assets/edit-gray.svg";
import PopupModal from "../../popupModal";
import notificationShow from "../../notificationService";
import EditTask from "./edit-task";
import { AiOutlineDelete } from "react-icons/ai";

interface TaskDetailsInterface {
  taskId?: any;
  markTaskAsClosed?: any;
  markTaskAsOpen?: any;
  openTaskDetailsDrawer?: any;
  getTasks?: any;
  userName?: any;
  taskType?: any;
  setOpenTaskDetailsDrawer?: any;
}

const TaskDetailsDrawer = ({
  taskId,
  markTaskAsClosed,
  markTaskAsOpen,
  openTaskDetailsDrawer,
  getTasks,
  setOpenTaskDetailsDrawer,
  userName,
  taskType,
}: TaskDetailsInterface) => {
  const [taskDetails, setTaskDetails] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getTaskDetails = async () => {
    try {
      const response = await axiosInstance.get(`/tasks?_id=${taskId}`);
      setTaskDetails(response.data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (taskId) {
      getTaskDetails();
    }
  }, [taskId]);

  const deleteTask = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post("tasks/operations/delete", {
        task_ids: [taskId],
      });
      getTasks(taskType);
      setOpenTaskDetailsDrawer(false);
      setPopupDeleteTaskModal(false);
      notificationShow({
        type: "success",
        content: `Task - ${taskDetails.task_title} is deleted Successfully!`,
      });
      setLoading(false);
    } catch (error: any) {
      setOpenTaskDetailsDrawer(false);
      setPopupDeleteTaskModal(false);
      notificationShow({
        type: "success",
        content: `${error?.response?.data?.error}`,
      });
      setLoading(false);
    }
  };

  const [popupDeleteTaskModal, setPopupDeleteTaskModal] = useState(false);

  const showDeleteTaskModal = () => {
    setPopupDeleteTaskModal(true);
    setLoading(false);
  };

  const onDeleteTaskModalCancel = () => {
    setPopupDeleteTaskModal(false);
  };

  const onDeleteTaskModalOkay = () => {
    taskId && deleteTask();
  };

  const [openTaskEditDrawer, setOpenTaskEditDrawer] = useState<boolean>(false);

  const items: any = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            setOpenTaskDetailsDrawer(false);
            setOpenTaskEditDrawer(true);
          }}
          className="flex items-center gap-2"
        >
          <img src={edit} alt="edit" className="h-5 w-5" />
          <div>Edit Task</div>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            showDeleteTaskModal();
          }}
          className="flex items-center gap-2"
        >
          <AiOutlineDelete className="h-5 w-5 text-black" />
          <div>Delete Task</div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Drawer
        placement="right"
        onClose={() => {
          setOpenTaskDetailsDrawer(false);
        }}
        open={openTaskDetailsDrawer}
        destroyOnClose={true}
        title={
          <div className="w-full flex items-center justify-between">
            <div className="text-2xl text-primaryColor font-semibold">
              Task Details
            </div>
            <Dropdown
              overlayStyle={{
                background: "#F4F4F1",
              }}
              menu={{ items }}
              placement="bottomRight"
              arrow
              trigger={["click"]}
            >
              <HiDotsVertical className="cursor-pointer" />
            </Dropdown>
          </div>
        }
        className="h-full"
        width={500}
        style={{
          scrollbarWidth: "thin",
        }}
        footer={
          <div className="flex items-center justify-end gap-4 w-full">
            <div
              onClick={() => {
                if (taskDetails.task_status === "Open") {
                  markTaskAsClosed([taskDetails._id]);
                }
                if (taskDetails.task_status === "Closed") {
                  markTaskAsOpen([taskDetails._id]);
                }
              }}
              className={`${
                taskDetails.task_status === "Open"
                  ? "bg-approvedColor border-approvedColor"
                  : "bg-primaryColor border-primaryColor"
              } w-[70%] text-sm text-center border font-semibold text-whiteColor px-4 py-2 rounded-md cursor-pointer`}
            >
              {taskDetails.task_status === "Open" && "Mark As Closed"}

              {taskDetails.task_status === "Closed" && "Mark As Open"}
            </div>
            <div
              onClick={() => {
                setOpenTaskDetailsDrawer(false);
              }}
              className="w-[25%] text-sm text-center font-semibold border border-gray-300 px-4 py-2 rounded-md cursor-pointer"
            >
              Cancel
            </div>
          </div>
        }
      >
        <div className="flex flex-col gap-8 relative">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <div className="text-lg font-medium clip">Task Title</div>
              <div className="text-secondaryText">
                {" "}
                {taskDetails.task_title}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="text-lg font-medium">Description</div>
              <div className="text-secondaryText clip">
                {taskDetails?.task_description
                  ? taskDetails?.task_description
                  : "-"}
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <div className="text-lg font-medium">Created By</div>
              <div className="flex items-center gap-2">
                <ProfilePictureHeadGroup
                  profile={taskDetails?.created_by?.profile_picture}
                  username={taskDetails?.created_by?.full_name}
                />
                <div className="text-secondaryText">
                  {taskDetails?.created_by?.full_name}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="text-lg font-medium">Assigned To</div>
              <div className="flex flex-col gap-2">
                {taskDetails?.assigned_to?.map(
                  (assignedto: any, index: any) => (
                    <div key={index} className="flex items-center gap-2">
                      <ProfilePictureHeadGroup
                        profile={assignedto?.profile_picture}
                        username={assignedto?.full_name}
                      />
                      <div className="text-secondaryText">
                        {assignedto?.full_name}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="text-lg font-medium">Created Date</div>
              <div className="text-secondaryText">
                {dayjs(taskDetails?.createdAt).format(
                  standard_date_only_format_frontend
                )}
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      {popupDeleteTaskModal && (
        <PopupModal
          top="20vh"
          width={500}
          open={popupDeleteTaskModal}
          onCancel={onDeleteTaskModalCancel}
          onOkay={onDeleteTaskModalOkay}
          content={
            <div className="flex flex-col items-start gap-6 w-full p-2">
              <div className="text-xl font-semibold">Delete Task!</div>
              <div className="flex flex-col gap-2 items-center">
                <div>
                  Are you sure you want to delete this task -
                  <span className=" text-primaryColor font-medium">
                    {" "}
                    {taskDetails.task_title}
                  </span>{" "}
                  ?
                </div>
              </div>
            </div>
          }
          button1="Cancel"
          button2="Delete Task"
          hideButton1=""
          hideButton2=""
          button1Color="primaryColor"
          button2Color="white"
          button1BgColor="secondaryColor"
          button2BgColor="rejectedColor"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}

      <EditTask
        taskDetails={taskDetails}
        getTasks={getTasks}
        openEditTaskDrawer={openTaskEditDrawer}
        setOpenEDitTaskDrawer={setOpenTaskEditDrawer}
        userName={userName}
        taskType={taskType}
      />
    </>
  );
};

export default TaskDetailsDrawer;
