import React, { useContext, useEffect } from "react";
import { userContext } from "../context/userContext";
import decodeAccessToken from "../utils/decodedAccessToken";

const Redirect = () => {
  const userContextData: any = useContext(userContext);

  useEffect(() => {
    userContextData.getConfig();
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const token: any = decodeAccessToken(accessToken);
      window.location.href = token.is_admin
        ? "/dashboard/overview"
        : "/employee/overview";
    } else {
      window.location.href = "/sign-in";
    }
  });
  return <div></div>;
};

export default Redirect;
