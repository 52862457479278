import { Form, Input, Spin } from "antd";
import React, { useState, useEffect } from "react";
import axiosInstance from "../utils/axiosInstance";
import decodeAccessToken from "../utils/decodedAccessToken";
import notificationShow from "../components/globalComponents/notificationService";
import NavbarChibiLogo from "../components/globalComponents/navbar-chibi-logo";
import invalid from "../assets/invalid-invite.png";
import { Link } from "react-router-dom";

const AcceptTeamInvitation = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [businessDetails, setBusinessDetails] = useState<any>([]);
  const [error, setError] = useState<string>("");

  const urlParams = new URLSearchParams(window.location.search);
  const emailToken: any = urlParams.get("token");

  const getBusinessDetails = async () => {
    try {
      const response = await axiosInstance.get(
        `/users/getInvitationDetail?email_token=${emailToken}`
      );
      setBusinessDetails(response.data.business);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBusinessDetails();
  }, [emailToken]);

  const acceptInvitation = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const formValues = form.getFieldsValue();
      const response = await axiosInstance.post("users/acceptInvitation", {
        email_token: emailToken,
        first_name: formValues.first_name,
        last_name: formValues.last_name,
        password: formValues.password,
      });
      localStorage.setItem("accessToken", response.data.accessToken);
      const accessToken = localStorage.getItem("accessToken");
      const token: any = decodeAccessToken(accessToken);
      window.location.href = token.is_admin
        ? "/dashboard/overview"
        : "/employee/overview";

      notificationShow({
        type: "success",
        content: "Account Created Successfully.",
      });
      form.resetFields();
      setLoading(false);
    } catch (error: any) {
      setError(error?.response?.data?.error);

      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-[100vh] gap-4 bg-secondaryBackground text-primaryText">
      <NavbarChibiLogo />
      <div className="flex items-center justify-center  p-12 rounded-md bg-primaryBackground">
        {businessDetails && businessDetails.business_name ? (
          <div className="w-full md:w-[30vw] flex flex-col">
            <div className="text-center mb-8">
              You have been invited to join: <br />
              <span className="text-2xl font-semibold text-primaryColor">
                {businessDetails.business_name}
              </span>
            </div>
            <div className="text-center text-xl font-semibold mb-8">
              Create Your Account
            </div>
            <Form autoComplete="off" size="large" layout="vertical" form={form}>
              <div className="flex items-center w-full md:flex-row flex-col gap-0 md:gap-4">
                <Form.Item
                  required
                  name="first_name"
                  label={<div className="font-semibold">First Name</div>}
                  rules={[
                    {
                      required: true,
                      message: "First Name is required.",
                    },
                  ]}
                  className="w-full"
                >
                  <Input className="border border-gray-600 rounded-md w-full py-2 px-4" />
                </Form.Item>
                <Form.Item
                  required
                  name="last_name"
                  label={<div className="font-semibold">Last Name</div>}
                  rules={[
                    {
                      required: true,
                      message: "Last Name is required.",
                    },
                  ]}
                  className="w-full"
                >
                  <Input className="border border-gray-600 rounded-md w-full py-2 px-4" />
                </Form.Item>
              </div>
              <Form.Item
                name="password"
                label={<div className="font-semibold">Password</div>}
                rules={[
                  {
                    required: true,
                    message: "Password is required.",
                  },
                ]}
                className="w-full"
              >
                <Input
                  type="password"
                  className="border border-gray-600 rounded-md w-full py-2 px-4"
                />
              </Form.Item>
            </Form>
            {error && (
              <div className="bg-red-50 text-red-500 rounded-md text-sm text-center py-2 mb-4">
                {error}
              </div>
            )}
            <Spin spinning={loading}>
              <button
                onClick={acceptInvitation}
                className="bg-primaryColor text-white font-semibold px-4 py-2 rounded-md w-full"
              >
                Create Account
              </button>
            </Spin>
          </div>
        ) : (
          <div className="w-full md:w-[30vw] flex flex-col items-center justify-center gap-8">
            <img src={invalid} alt="invalid" className="h-24 w-24" />
            <div className="text-2xl text-center font-semibold text-secondaryText">
              Invitation expired/invalid.
              <br /> Check you Email!
            </div>
            <div className="text-center">
              Already using chibiHR?{" "}
              <Link to="/sign-in" className="text-primaryColor cursor-pointer">
                Sign in here
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AcceptTeamInvitation;
