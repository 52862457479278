import React, { useState, useEffect } from "react";
import axiosInstance from "../../../utils/axiosInstance";
import dayjs from "dayjs";
import {
  standard_date_only_format_backend,
  standard_date_only_format_frontend,
} from "../../../utils/dateAndTimeFormatter";
import { Drawer, Form, Input, Select, Spin, Table, Tag } from "antd";

import PopupModal from "../../globalComponents/popupModal";
import notificationShow from "../../globalComponents/notificationService";
import { HiDotsVertical } from "react-icons/hi";
import truncateLongerUsername from "../../../utils/truncateLongerUsernames";
import { useNavigate } from "react-router-dom";
const { TextArea } = Input;

const Payslip = () => {
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [payslips, setPayslips] = useState([]);
  const [payslipDetails, setPayslipDetails] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  useState(false);
  const [popupChangePayslipStatusModal, setPopupChangePayslipStatusModal] =
    useState(false);
  const [openSalaryCalculationDrawer, setOpenSalaryCalculationDrawer] =
    useState(false);
  const [payslipId, setPayslipId] = useState<string>("");
  const [salaryDetails, setSalaryDetails] = useState<any>([]);
  const [totalPay, setTotalPay] = useState<number>(0);
  const [viewDetailsLoading, setViewDetailsLoading] = useState<boolean>(false);
  const [payslipStartDate, setPayslipStartDate] = useState<string>("");
  const [payslipEndDate, setPayslipEndDate] = useState<string>("");
  const navigate = useNavigate();

  const getPayslipsOfAllUsers = async () => {
    try {
      setLoading(true);
      const url = `/payslip`;
      const response = await axiosInstance.get(url);
      setPayslips(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getPayslipOfSingleUser = async (userid: string) => {
    try {
      setLoading(true);
      const url = `/payslip?user_id=${userid}`;
      const response = await axiosInstance.get(url);
      setPayslips(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getUsers = async () => {
    try {
      const response = await axiosInstance.get("/users");
      setUsers(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPayslipsOfAllUsers();
    getUsers();
  }, []);

  const payslipTableColumns: any = [
    {
      title: "Payslip No.",
      dataIndex: "payslip_number",
      key: "payslip_number",
    },
    {
      title: "Username",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Range",
      dataIndex: "range",
      key: "range",
    },
    {
      title: "Pay Amount",
      dataIndex: "pay_amount",
      key: "pay_amount",
    },
    {
      title: "Payslip Status",
      dataIndex: "payslip_status",
      key: "payslip_status",
    },
  ];

  useEffect(() => {
    const payslip = payslips?.map((payslip: any) => {
      return {
        key: payslip._id,
        name: (
          <div
            onClick={() => {
              navigate(`/dashboard/team-members/${payslip.user_id._id}`);
            }}
            className="font-semibold text-primaryColor"
          >
            {truncateLongerUsername(payslip.user_id.full_name, 25)}
          </div>
        ),
        payslip_number: payslip.payslip_number,
        range: `${dayjs(payslip?.start_date_of_generation).format(
          standard_date_only_format_frontend
        )} - ${dayjs(payslip?.end_date_of_generatioin).format(
          standard_date_only_format_frontend
        )}`,
        startdate: payslip?.start_date_of_generation,
        enddate: payslip?.end_date_of_generation,
        user_id: payslip?.user_id._id,
        pay_amount: payslip.pay_amount.toFixed(2),
        payslip_status:
          payslip.payslip_status.toLowerCase() === "complete" ||
          payslip.payslip_status.toLowerCase() === "void" ? (
            <div>
              <div className="w-full flex items-center justify-between gap-2">
                <Tag
                  color="#"
                  className={`${
                    payslip.payslip_status.toLowerCase() === "complete" &&
                    "bg-approvedColor"
                  } ${
                    payslip.payslip_status.toLowerCase() === "void" &&
                    "bg-rejectedColor"
                  } font-semibold`}
                >
                  {payslip.payslip_status}
                </Tag>
              </div>
              {payslip.payslip_status.toLowerCase() === "complete" ? (
                <span className="text-approvedColor font-semibold text-[12px]">
                  Completed By: {payslip.completed_by.full_name}
                </span>
              ) : (
                <span className="text-rejectedColor text-[12px] font-semibold">
                  Void By: {payslip.void_by.full_name}
                </span>
              )}
              <br />
              {payslip.payslip_status.toLowerCase() === "void" && (
                <div className="max-w-[20vw] clip text-[12px] text-rejectedColor font-semibold">
                  {payslip.void_reason}
                </div>
              )}
            </div>
          ) : (
            <div
              onClick={() => {
                setPayslipId(payslip._id);
                showChangePayslipStatusModal();
              }}
              className="flex items-center gap-2"
            >
              {payslip?.payslip_status.toLowerCase() === "pending" && (
                <Tag color="#" className="font-semibold bg-pendingColor">
                  {payslip.payslip_status}
                </Tag>
              )}
              <HiDotsVertical />
            </div>
          ),
      };
    });
    setPayslipDetails(payslip);
  }, [payslips]);

  const employeesList = users.map((user: any) => ({
    value: user._id,
    label: user.full_name,
  }));

  const [payslipStatus, setPayslipStatus] = useState<string>("");

  const markPayslipAsCompleted = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const response = await axiosInstance.post(
        `payslip/markPaySlipAsComplete`,
        {
          payslip_id: payslipId,
        }
      );
      getPayslipsOfAllUsers();
      form.resetFields();
      setPayslipStatus("");
      notificationShow({
        type: "success",
        content: "Payslip status changed!",
      });
      setPopupChangePayslipStatusModal(false);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      notificationShow({
        type: "error",
        message: error?.response?.data?.error,
      });
    }
  };

  const markPayslipAsVoid = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const formValues = form.getFieldsValue();
      const response = await axiosInstance.post(`payslip/markPaySlipAsVoid`, {
        payslip_id: payslipId,
        void_reason: formValues.void_reason,
      });
      getPayslipsOfAllUsers();
      form.resetFields();
      setPayslipStatus("");
      notificationShow({
        type: "success",
        content: "Payslip status changed!",
      });
      setPopupChangePayslipStatusModal(false);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      notificationShow({
        type: "error",
        content: error?.response?.data?.error,
      });
    }
  };

  const handlePayslipStatusChange = (value: any) => {
    setPayslipStatus(value);
  };

  const showChangePayslipStatusModal = () => {
    setPopupChangePayslipStatusModal(true);
    setLoading(false);
  };

  const onChangePayslipStatusModalCancel = () => {
    setPopupChangePayslipStatusModal(false);
  };

  const onChangePayslipStatusModalOkay = () => {
    if (payslipStatus === "Complete" && payslipStatus) {
      markPayslipAsCompleted();
    }
    if (payslipStatus === "Void" && payslipStatus) {
      markPayslipAsVoid();
    }
  };

  const calculateSalary = async (
    user_id: any,
    startdate: any,
    enddate: any
  ) => {
    try {
      setViewDetailsLoading(true);
      const response = await axiosInstance.post("payroll/salaryCalculate", {
        user_id: user_id,
        start_date_of_generation: dayjs(startdate).format(
          standard_date_only_format_backend
        ),
        end_date_of_generation: dayjs(enddate).format(
          standard_date_only_format_backend
        ),
      });
      setSalaryDetails(response.data.all_payrolls);
      setTotalPay(response.data.total_amount);
      setViewDetailsLoading(false);
    } catch (error: any) {
      console.log(error);
      setViewDetailsLoading(false);
    }
  };

  const showSalaryCalculationDrawer = (
    user_id: any,
    startdate: any,
    enddate: any
  ) => {
    calculateSalary(user_id, startdate, enddate);
    setOpenSalaryCalculationDrawer(true);
  };

  const onClose = () => {
    setSalaryDetails([]);
    setOpenSalaryCalculationDrawer(false);
  };

  return (
    <div className="text-primaryText">
      <div className="pb-6 flex items-center justify-between">
        <div className="text-3xl font-medium ">Payslips</div>
        <div className="flex items-center gap-4">
          <Select
            placeholder="Employee name..."
            allowClear
            bordered={false}
            className="border border-gray-300 bg-white rounded-md h-10 px-1 w-full"
            style={{ width: 300 }}
            showSearch={true}
            filterOption={(input: any, option: any) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(value: string, option: any) => {
              getPayslipOfSingleUser(value);
            }}
            options={employeesList}
          />
        </div>
      </div>
      <div className="p-4 rounded-md bg-primaryBackground">
        <Spin spinning={loading}>
          <Table
            className="cursor-pointer  text-primaryText"
            size="middle"
            columns={payslipTableColumns}
            dataSource={payslipDetails}
            pagination={false}
            onRow={(record) => ({
              onClick: () => {
                const payslip = payslipDetails.find(
                  (p: any) => p.key === record.key
                );

                if (payslip) {
                  setPayslipStartDate(payslip.startdate);
                  setPayslipEndDate(payslip.enddate);
                  showSalaryCalculationDrawer(
                    payslip?.user_id,
                    payslip.startdate,
                    payslip.enddate
                  );
                }
              },
            })}
          />
        </Spin>
      </div>

      {popupChangePayslipStatusModal && (
        <PopupModal
          top="20vh"
          width={500}
          open={popupChangePayslipStatusModal}
          onCancel={onChangePayslipStatusModalCancel}
          onOkay={onChangePayslipStatusModalOkay}
          content={
            <div className="flex flex-col items-start gap-6 w-full p-2  text-primaryText">
              <div className="text-xl font-semibold">
                Change Payslip status!
              </div>
              <Form layout="vertical" form={form} className="w-full">
                <Form.Item
                  required
                  className="font-medium"
                  label="Payslip Status"
                  name="payslip_status"
                >
                  <Select
                    showSearch
                    options={[
                      {
                        value: "Complete",
                        label: "Complete",
                      },

                      {
                        value: "Void",
                        label: "Void",
                      },
                    ]}
                    bordered={false}
                    className="border border-gray-300 rounded-md h-10 px-1 w-full"
                    onChange={handlePayslipStatusChange}
                  />
                </Form.Item>
                {payslipStatus && payslipStatus === "Void" && (
                  <Form.Item required label="Void Reason" name="void_reason">
                    <TextArea
                      maxLength={100}
                      rows={2}
                      className="border border-gray-300 rounded-md w-full py-2 px-4"
                    />
                  </Form.Item>
                )}
              </Form>
            </div>
          }
          button1="Cancel"
          button2="Update Status"
          hideButton1=""
          hideButton2=""
          button1Color="primaryColor"
          button2Color="white"
          button1BgColor="secondaryColor"
          button2BgColor="primaryColor"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      {openSalaryCalculationDrawer && (
        <Drawer
          placement="right"
          closeIcon={false}
          onClose={onClose}
          open={openSalaryCalculationDrawer}
          className="h-full"
          width={600}
          style={{
            scrollbarWidth: "thin",
          }}
        >
          <div className="flex flex-col gap-8 w-full">
            <div className="text-2xl text-primaryColor font-semibold">
              Payslip Details
            </div>
            <div className="flex flex-col">
              {salaryDetails.length > 0 ? (
                <div className="flex flex-col gap-8 w-full">
                  <div>
                    Payslip Calculated from{" "}
                    <span className="text-xl font-semibold text-primaryColor">
                      {dayjs(payslipStartDate).format(
                        standard_date_only_format_frontend
                      )}
                    </span>{" "}
                    to{" "}
                    <span className="text-xl font-semibold text-primaryColor">
                      {dayjs(payslipEndDate).format(
                        standard_date_only_format_frontend
                      )}
                    </span>
                  </div>
                  <div className="flex flex-col gap-8 w-full">
                    {salaryDetails.map((salary: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className=" p-6 w-full flex flex-col gap-3 text-md border-2 border-primaryColor border-dashed"
                        >
                          <div className="text-center font-semibold text-xl pb-2">
                            Payslip #{index + 1}
                          </div>
                          <div className="flex items-center gap-4 justify-between w-full">
                            <div className="">
                              <span className="text-secondaryText">
                                Payroll Start Date:{" "}
                              </span>
                              <span className="text-primaryColor font-semibold">
                                {dayjs(salary.payroll_start_date).format(
                                  standard_date_only_format_frontend
                                )}
                              </span>
                            </div>
                            <div className="">
                              <span className="text-secondaryText">
                                {" "}
                                Payroll End Date :{" "}
                              </span>
                              <span className="text-red-500 font-semibold">
                                {salary.payroll_end_date === "Ongoing" ? (
                                  <Tag color="green">Ongoing</Tag>
                                ) : (
                                  dayjs(salary.payroll_end_date).format(
                                    standard_date_only_format_frontend
                                  )
                                )}
                              </span>
                            </div>
                          </div>
                          <div>
                            <span className="text-secondaryText">
                              Monthly Pay Amount :{" "}
                            </span>
                            <span className="font-semibold">
                              {salary.pay_amount}
                            </span>
                          </div>
                          <div>
                            <span className="text-secondaryText">
                              Pay Frequency :{" "}
                            </span>
                            <span className="font-semibold">Monthly</span>
                          </div>
                          <div>
                            <span className="text-secondaryText">
                              Pay Per Day :{" "}
                            </span>

                            <span className="font-semibold">
                              {salary?.per_day_pay.toFixed(2)}
                            </span>
                          </div>
                          <div>
                            <span className="text-secondaryText">
                              Payable Days :{" "}
                            </span>
                            <span className="font-semibold">
                              {salary.payable_days}
                            </span>
                          </div>
                          <div>
                            <span className="text-secondaryText">
                              Paid Leave Days :{" "}
                            </span>
                            <span className="font-semibold">
                              {salary.paid_leave_days}
                            </span>
                          </div>
                          <div>
                            <span className="text-secondaryText">
                              Paid Leave Amount :{" "}
                            </span>

                            <span className="font-semibold">
                              {salary.paid_leave_amount.toFixed(2)}
                            </span>
                          </div>
                          <div>
                            <span className="text-secondaryText">
                              Holidays :{" "}
                            </span>
                            <span className="font-semibold">
                              {salary.holidays}
                            </span>
                          </div>
                          <div>
                            <span className="text-secondaryText">
                              Holiday Amount :{" "}
                            </span>

                            <span className="font-semibold">
                              {salary.holiday_amount.toFixed(2)}
                            </span>
                          </div>
                          <div>
                            <span className="text-secondaryText">
                              Late Early Present :{" "}
                            </span>

                            <span className="font-semibold">
                              {salary.late_early_present}
                            </span>
                          </div>
                          <div>
                            <span className="text-secondaryText">
                              Pay Late Early Present :{" "}
                            </span>

                            <span className="font-semibold">
                              {salary.pay_late_or_early.toFixed(2)}
                            </span>
                          </div>
                          <div>
                            <span className="text-secondaryText">
                              Half Day Present :{" "}
                            </span>

                            <span className="font-semibold">
                              {salary.half_day_present}
                            </span>
                          </div>
                          <div>
                            <span className="text-secondaryText">
                              Pay Half Day Present :{" "}
                            </span>

                            <span className="font-semibold">
                              {salary.pay_half_day.toFixed(2)}
                            </span>
                          </div>
                          <div>
                            <span className="text-secondaryText">
                              Total Pay amount :{" "}
                            </span>

                            <span className="font-semibold text-green-500">
                              {salary.amount.toFixed(2)}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="border-b-2 border-primaryColor"></div>
                  <div className="flex items-center justify-between w-full gap-4">
                    <div className="ml-auto text-xl font-semibold">
                      Total Pay Amount :{" "}
                      <span className="text-green-500">
                        {totalPay.toFixed(2).toString().toLocaleString()}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <Spin
                  className="self-center mt-[40vh]"
                  spinning={viewDetailsLoading}
                ></Spin>
              )}
            </div>
          </div>
        </Drawer>
      )}
    </div>
  );
};

export default Payslip;
