import React, { useState, useContext, useEffect } from "react";
import { userContext } from "../../../../context/userContext";
import PopupModal from "../../../globalComponents/popupModal";
import edit from "../../../../assets/edit.svg";
import { Form, Input, Switch } from "antd";
import axiosInstance from "../../../../utils/axiosInstance";
import notificationShow from "../../../globalComponents/notificationService";
import info from "../../../../assets/Info.svg";
import ShowLableValueForDetails from "../../../globalComponents/showLabelValueForDetails";

const SettingsGeneral = () => {
  const userContextData: any = useContext(userContext);
  useEffect(() => {
    userContextData.getConfig();
  }, []);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [updateBusinessDetailsModal, setUpdateBusinessDetailsModal] =
    useState(false);

  const showUpdateBusinessDetailsModal = () => {
    setLoading(false);
    setUpdateBusinessDetailsModal(true);
    form.setFieldsValue({
      business_name: userContextData.allDetails.business.business_name,
      business_address: userContextData.allDetails.business.business_address,
      business_contact: userContextData.allDetails.business.business_contact,
      business_email: userContextData.allDetails.business.business_email,
      website: userContextData.allDetails.business.website,
    });
  };

  const updateBusinessDetails = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const formValues = form.getFieldsValue();
      const response = await axiosInstance.patch(`/settings/business`, {
        business_name: formValues.business_name,
        business_address: formValues.business_address,
        business_contact: formValues.business_contact,
        business_email: formValues.business_email,
        website: formValues.website,
      });
      userContextData.getConfig();
      setUpdateBusinessDetailsModal(false);
      notificationShow({
        type: "success",
        content: "Business details updated successfully!",
      });
      setLoading(false);
      form.resetFields();
    } catch (error: any) {
      setLoading(false);
      setError(error?.response?.data?.error);
    }
  };

  const onUpdateBusinessDetailsCancel = () => {
    setUpdateBusinessDetailsModal(false);
  };
  const onUpdateBusinessDetailsOkay = () => {
    updateBusinessDetails();
  };

  const handleGPSRequireOnClockinClockout = async (checked: boolean) => {
    try {
      const response = await axiosInstance.patch("/settings/business", {
        always_require_gps_on_clockin_clockout: checked,
      });
      userContextData.getConfig();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="text-primaryText">
      <div className="pb-6 flex items-center justify-between ">
        <div className="text-3xl font-medium">General</div>
      </div>
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-6 p-4 bg-primaryBackground rounded-md">
          <div className="flex items-center justify-between w-full">
            <div className="text-xl font-medium">Business Details</div>
            <div
              onClick={() => {
                showUpdateBusinessDetailsModal();
              }}
              className="flex items-center gap-1 cursor-pointer"
            >
              <img src={edit} alt="edit" />
              <div className="text-primaryColor">Edit</div>
            </div>
          </div>
          {userContextData?.allDetails && (
            <ShowLableValueForDetails
              details={[
                {
                  label: "Business Name",
                  value: userContextData?.allDetails?.business?.business_name
                    ? userContextData?.allDetails?.business?.business_name
                    : "-",
                },
                {
                  label: "Business Address",
                  value: userContextData?.allDetails?.business?.business_address
                    ? userContextData?.allDetails?.business?.business_address
                    : "-",
                },
                {
                  label: "Business Contact",
                  value: userContextData?.allDetails?.business?.business_contact
                    ? userContextData?.allDetails?.business?.business_contact
                    : "-",
                },
                {
                  label: "Business Email",
                  value: userContextData?.allDetails?.business?.business_email
                    ? userContextData?.allDetails?.business?.business_email
                    : "-",
                },

                {
                  label: "Webiste",
                  value: userContextData?.allDetails?.business?.website
                    ? userContextData?.allDetails?.business?.website
                    : "-",
                },
                {
                  label: "Currency",
                  value: userContextData?.allDetails?.business?.currency
                    ? userContextData?.allDetails?.business?.currency
                    : "Nepali(NPR)",
                },
                {
                  label: "Time Zone",
                  value: userContextData?.allDetails?.business?.timezone
                    ? userContextData?.allDetails?.business?.timezone
                    : "-",
                },
              ]}
            />
          )}
        </div>
        <div className="flex flex-col gap-6 p-4 bg-primaryBackground rounded-md">
          <div className="text-xl font-medium">GPS Tracking</div>
          <div className="w-[90%] mx-auto flex items-center gap-4">
            <div className="font-medium">
              Always require GPS on clockin and clockout
            </div>
            <Switch
              defaultChecked={
                userContextData?.allDetails?.business
                  ?.always_require_gps_on_clockin_clockout
              }
              onChange={handleGPSRequireOnClockinClockout}
            />
          </div>
          <div className="flex items-center gap-2 w-[90%] mx-auto">
            <img src={info} alt="info" />
            <div className="text-secondaryText text-sm">
              Enabling this option will only allow clockin and clockout from
              mobile devices.
            </div>
          </div>
        </div>
      </div>

      {updateBusinessDetailsModal && (
        <PopupModal
          top="4vh"
          width={550}
          open={updateBusinessDetailsModal}
          onCancel={onUpdateBusinessDetailsCancel}
          onOkay={onUpdateBusinessDetailsOkay}
          content={
            <div className="flex flex-col items-start gap-6 w-full p-2 text-primaryText">
              <div className="text-xl font-semibold">
                Update Business Details!
              </div>
              <div className="w-full">
                <Form
                  layout="vertical"
                  form={form}
                  className="flex flex-col w-full"
                  initialValues={{
                    business_name:
                      userContextData.allDetails.business.business_name,
                    business_address:
                      userContextData.allDetails.business.business_address,
                    business_contact:
                      userContextData.allDetails.business.business_contact,
                    business_email:
                      userContextData.allDetails.business.business_email,
                    website: userContextData.allDetails.business.website,
                  }}
                >
                  <Form.Item
                    required
                    className="font-medium"
                    label="Business Name"
                    name="business_name"
                    rules={[
                      {
                        required: true,
                        message: "Business Name is required.",
                      },
                    ]}
                  >
                    <Input className="border border-gray-300 rounded-md w-full py-2 px-4" />
                  </Form.Item>
                  <Form.Item
                    className="font-medium"
                    label="Business Address"
                    name="business_address"
                  >
                    <Input className="border border-gray-300 rounded-md w-full py-2 px-4" />
                  </Form.Item>
                  <Form.Item
                    className="font-medium"
                    label="Business Contact"
                    name="business_contact"
                  >
                    <Input className="border border-gray-300 rounded-md w-full py-2 px-4" />
                  </Form.Item>

                  <Form.Item
                    className="font-medium"
                    label="Business Email"
                    name="business_email"
                  >
                    <Input
                      type="email"
                      className="border border-gray-300 rounded-md w-full py-2 px-4"
                    />
                  </Form.Item>
                  <Form.Item
                    className="font-medium"
                    label="Website"
                    name="website"
                  >
                    <Input className="border border-gray-300 rounded-md w-full py-2 px-4" />
                  </Form.Item>
                  {error && (
                    <div className="bg-red-50 text-red-500 rounded-md text-sm text-center py-2">
                      {error}
                    </div>
                  )}
                </Form>
              </div>
            </div>
          }
          button1="Cancel"
          button2="Update Details"
          hideButton1=""
          hideButton2=""
          button1Color="white"
          button2Color="white"
          button1BgColor="rejectedColor"
          button2BgColor="primaryColor"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}
    </div>
  );
};

export default SettingsGeneral;
