import React, { useContext, useEffect, useState } from "react";

import edit from "../../../../assets/edit.svg";
import { userContext } from "../../../../context/userContext";
import PopupModal from "../../../globalComponents/popupModal";
import axiosInstance from "../../../../utils/axiosInstance";
import { Form, Input } from "antd";
import ProfilePictureHead from "../../../globalComponents/profilePictureHead/profilePictureHead";
import notificationShow from "../../../globalComponents/notificationService";
import ShowLableValueForDetails from "../../../globalComponents/showLabelValueForDetails";

const SettingsProfile = () => {
  const userContextData: any = useContext(userContext);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    userContextData.getConfig();
  }, []);

  const [updateProfilePopUpModal, setUpdateProfilePopUpModal] = useState(false);
  const [changePasswordPopupModal, setChangePasswordPopupModal] =
    useState(false);

  const updateProfile = async () => {
    try {
      await form.validateFields();
      const formValues = form.getFieldsValue();
      const response = await axiosInstance.patch("/users", {
        user_id: userContextData.myDetails._id,
        full_name: formValues.first_name + " " + formValues.last_name,
      });
      userContextData.getConfig();
      notificationShow({
        type: "success",
        content: "User Profile updated successfully!",
      });
      setUpdateProfilePopUpModal(false);
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading(false);
    }
  };
  const showUpdateProfileModal = () => {
    setLoading(false);
    setUpdateProfilePopUpModal(true);
  };
  const onUpdateProfileModalCancel = () => {
    setUpdateProfilePopUpModal(false);
  };
  const onUpdateProfileModalOkay = () => {
    updateProfile();
  };

  const showChangePasswordModal = () => {
    setLoading(false);
    setChangePasswordPopupModal(true);
  };
  const onChangePasswordModalCancel = () => {
    setChangePasswordPopupModal(false);
  };
  const onChangePasswordModalOkay = () => {
    setChangePasswordPopupModal(false);
  };

  return (
    <div className="text-primaryText">
      <div className="text-3xl pb-6">Account</div>
      <div className="flex flex-col gap-8 p-4 rounded-md bg-primaryBackground">
        <div className="flex items-center justify-between gap-4">
          <div className="text-xl font-medium">Account Details</div>
          <div
            onClick={showUpdateProfileModal}
            className="flex items-center gap-1 cursor-pointer"
          >
            <img src={edit} alt="edit" />
            <div className="text-primaryColor">Edit</div>
          </div>
        </div>
        {userContextData && (
          <div>
            <div className="w-[90%] mx-auto">
              <ProfilePictureHead
                userContextData={userContextData.getConfig}
                username={userContextData.myDetails.full_name}
                profile={userContextData.myDetails.profile_picture}
                user_id={userContextData.myDetails._id}
                height="h-24"
                width="w-24"
                fontSize="text-xl"
              />
            </div>
            <ShowLableValueForDetails
              details={[
                {
                  label: "Preferred Full Name",
                  value: userContextData?.myDetails?.full_name
                    ? userContextData?.myDetails.full_name
                    : "-",
                },
              ]}
            />
          </div>
        )}
      </div>
      {userContextData && (
        <div className="rounded-md p-4 bg-primaryBackground flex flex-col gap-8 my-8">
          <div className="flex items-center justify-between gap-4">
            <div className="text-xl font-medium">Email Address</div>
          </div>
          <ShowLableValueForDetails
            details={[
              {
                label: "Email Address",
                value: userContextData?.myDetails?.email
                  ? userContextData?.myDetails.email
                  : "-",
              },
            ]}
          />
        </div>
      )}
      <div
        onClick={showChangePasswordModal}
        className="flex items-center cursor-pointer text-lg gap-1 font-medium"
      >
        <img src={edit} alt="edit" />
        <div className="text-primaryColor">Change Password</div>
      </div>

      {updateProfilePopUpModal && (
        <PopupModal
          top="15vh"
          width={500}
          open={updateProfilePopUpModal}
          onCancel={onUpdateProfileModalCancel}
          onOkay={onUpdateProfileModalOkay}
          content={
            <div className="flex flex-col items-start gap-6 w-full p-2 text-primaryText">
              <div className="text-xl font-semibold">Update Profile!</div>
              <div className="w-full">
                <Form
                  layout="vertical"
                  form={form}
                  className="flex flex-col w-full"
                  initialValues={{
                    first_name:
                      userContextData?.myDetails?.full_name.split(" ")[0],
                    last_name:
                      userContextData?.myDetails?.full_name.split(" ")[1],
                  }}
                >
                  <Form.Item
                    className="font-medium"
                    required
                    label="First Name"
                    name="first_name"
                    rules={[
                      {
                        required: true,
                        message: "First Name is required.",
                      },
                    ]}
                  >
                    <Input className="border border-gray-600 rounded-md w-full py-2 px-4" />
                  </Form.Item>

                  <Form.Item
                    required
                    className="font-medium"
                    label="Last Name"
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: "Last Name is required.",
                      },
                    ]}
                  >
                    <Input className="border border-gray-600 rounded-md w-full py-2 px-4" />
                  </Form.Item>
                </Form>
              </div>
            </div>
          }
          button1="Cancel"
          button2="Update Profile"
          hideButton1=""
          hideButton2=""
          button1Color="primaryColor"
          button2Color="white"
          button1BgColor="secondaryColor"
          button2BgColor="primaryColor"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      {changePasswordPopupModal && (
        <PopupModal
          top="15vh"
          width={550}
          open={changePasswordPopupModal}
          onCancel={onChangePasswordModalCancel}
          onOkay={onChangePasswordModalOkay}
          content={
            <div className="flex flex-col items-start gap-6 w-full p-2 text-primaryText">
              <div className="text-xl font-semibold">Change Password!</div>
              <div className="w-full">
                <Form layout="vertical" form={form} className="font-medium">
                  <Form.Item
                    required
                    className="font-medium"
                    label="Old Password"
                    name="old_password"
                    rules={[
                      {
                        required: true,
                        message: "Old Password is required.",
                      },
                    ]}
                  >
                    <Input.Password
                      autoComplete="false"
                      className="border border-gray-600 rounded-md w-full py-2 px-4"
                    />
                  </Form.Item>
                  <Form.Item
                    required
                    label="New Password"
                    name="new_password"
                    className="font-medium"
                    rules={[
                      {
                        required: true,
                        message: "New Password is required.",
                      },
                    ]}
                  >
                    <Input.Password className="border border-gray-600 rounded-md w-full py-2 px-4" />
                  </Form.Item>
                  <Form.Item
                    required
                    label="Confirm Password"
                    name="confirm_password"
                    className="font-medium"
                    rules={[
                      {
                        required: true,
                        message: "Confirm Password is required.",
                      },
                    ]}
                  >
                    <Input.Password className="border border-gray-600 rounded-md w-full py-2 px-4" />
                  </Form.Item>
                </Form>
              </div>
            </div>
          }
          button1="Cancel"
          button2="Change Password"
          hideButton1=""
          hideButton2=""
          button1Color="primaryColor"
          button2Color="white"
          button1BgColor="secondaryColor"
          button2BgColor="primaryColor"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}
    </div>
  );
};

export default SettingsProfile;
