import React, { useContext, useEffect, useState } from "react";
import { userContext } from "../../../context/userContext";
import edit from "../../../assets/edit.svg";
import axiosInstance from "../../../utils/axiosInstance";
import PopupModal from "../../globalComponents/popupModal";
import { DatePicker, Form, Input, Select } from "antd";
import {
  standard_date_only_format_backend,
  standard_date_only_format_frontend,
} from "../../../utils/dateAndTimeFormatter";
import dayjs from "dayjs";
import notificationShow from "../../globalComponents/notificationService";
import { BanksInNepal } from "../../../constants/banks-in-nepal";
import { TypesOfIds } from "../../../constants/types-of-ids-of-person";
import ProfilePictureHead from "../../globalComponents/profilePictureHead/profilePictureHead";
import ShowLableValueForDetails from "../../globalComponents/showLabelValueForDetails";
const { TextArea } = Input;

const EmployeeMyProfile = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const userContextData: any = useContext(userContext);

  useEffect(() => {
    userContextData.getConfig();
  }, []);

  const [updatePersonalDetailsModal, setUpdatePersonalDetailsModal] =
    useState(false);
  const [updateBankDetailsModal, setUpdateBankDetailsModal] = useState(false);
  const [updateContactDetailsModal, setUpdateContactDetailsModal] =
    useState(false);

  const updatePersonalDetails = async () => {
    try {
      await form.validateFields();
      const formValues = form.getFieldsValue();
      const response = await axiosInstance.patch(`/users`, {
        user_id: userContextData.myDetails._id,
        full_name: formValues.first_name + " " + formValues.last_name,
        gender: formValues.gender,
        bio: formValues.bio,
        dob: dayjs(formValues.dob).format(standard_date_only_format_backend),
      });
      userContextData.getConfig();
      setUpdatePersonalDetailsModal(false);
      notificationShow({
        type: "success",
        content: "Personal details updated successfully!",
      });
      setLoading(false);
      form.resetFields();
    } catch (error: any) {
      setLoading(false);
    }
  };
  const showUpdatePersonalDetailsModal = () => {
    setLoading(false);
    setUpdatePersonalDetailsModal(true);
    form.setFieldsValue({
      first_name: userContextData.myDetails.full_name.split(" ")[0],
      last_name: userContextData.myDetails.full_name.split(" ")[1],
      gender: userContextData.myDetails.gender,
      bio: userContextData.myDetails.bio,
      dob: dayjs(userContextData.myDetails.dob),
    });
  };
  const onUpdatePersonalDetailsCancel = () => {
    setUpdatePersonalDetailsModal(false);
  };
  const onUpdatePersonalDetailsOkay = () => {
    updatePersonalDetails();
  };

  const updateBankDetails = async () => {
    try {
      await form.validateFields();
      const formValues = form.getFieldsValue();
      const response = await axiosInstance.patch(`/users`, {
        user_id: userContextData.myDetails._id,
        bank_name: formValues.bank_name,
        bank_branch: formValues.bank_branch,
        bank_account_name: formValues.bank_account_name,
        bank_account_number: formValues.bank_account_number,
      });
      userContextData.getConfig();
      setUpdateBankDetailsModal(false);
      notificationShow({
        type: "success",
        content: "Bank details updated successfully!",
      });
      setLoading(false);
      form.resetFields();
    } catch (error: any) {
      setLoading(false);
      setUpdateBankDetailsModal(false);
      notificationShow({
        type: "error",
        content: error?.response?.data?.error,
      });
    }
  };
  const showUpdateBankDetailsModal = () => {
    setLoading(false);
    setUpdateBankDetailsModal(true);
    form.setFieldsValue({
      bank_name: userContextData.myDetails.bank_name,
      bank_branch: userContextData.myDetails.bank_branch,
      bank_account_name: userContextData.myDetails.bank_account_name,
      bank_account_number: userContextData.myDetails.bank_account_number,
    });
  };

  const onUpdateBankDetailsCancel = () => {
    setUpdateBankDetailsModal(false);
  };
  const onUpdateBankDetailsOkay = () => {
    updateBankDetails();
  };

  const updateContactDetails = async () => {
    try {
      await form.validateFields();
      const formValues = form.getFieldsValue();
      const response = await axiosInstance.patch(`/users`, {
        user_id: userContextData.myDetails._id,
        personal_email: formValues.personal_email,
        personal_phone: formValues.personal_phone,
        current_address: formValues.current_address,
        permanent_address: formValues.permanent_address,
        id_type: formValues.id_type,
        id_number: formValues.id_number,
        pan_number: formValues.pan_number,
      });
      userContextData.getConfig();
      form.resetFields();
      setUpdateContactDetailsModal(false);
      notificationShow({
        type: "success",
        content: "Contact details updated successfully!",
      });
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setUpdateContactDetailsModal(false);
    }
  };
  const showUpdateContactDetailsModal = () => {
    setLoading(false);
    setUpdateContactDetailsModal(true);
    form.setFieldsValue({
      personal_email: userContextData.myDetails.personal_email,
      personal_phone: userContextData.myDetails.personal_phone,
      current_address: userContextData.myDetails.current_address,
      permanent_address: userContextData.myDetails.permanent_address,
      id_type: userContextData.myDetails.id_type,
      id_number: userContextData.myDetails.id_number,
      pan_number: userContextData.myDetails.pan_number,
    });
  };

  const onUpdateContactDetailsCancel = () => {
    setUpdateContactDetailsModal(false);
  };
  const onUpdateContactDetailsOkay = () => {
    updateContactDetails();
  };

  return (
    <div>
      <div className="pb-6 flex items-center justify-between">
        <div className="text-3xl font-medium ">My Profile</div>
      </div>
      <div className="flex flex-col gap-8">
        <div className="p-4 rounded-md bg-primaryBackground flex flex-col gap-8">
          <div className="flex items-center justify-between w-full">
            <div className="text-xl font-medium">Personal Details</div>
            <div
              onClick={showUpdatePersonalDetailsModal}
              className="flex items-center gap-1 cursor-pointer"
            >
              <img src={edit} alt="edit" />
              <div className="text-primaryColor">Edit</div>
            </div>
          </div>
          <div>
            <div className="w-[90%] mx-auto">
              <ProfilePictureHead
                userContextData={userContextData.getConfig}
                username={userContextData.myDetails.full_name}
                profile={userContextData.myDetails.profile_picture}
                user_id={userContextData.myDetails._id}
                height="h-24"
                width="w-24"
                fontSize="text-xl"
              />
            </div>
            <ShowLableValueForDetails
              details={[
                {
                  label: "Preferred Full Name",
                  value: userContextData?.myDetails?.full_name
                    ? userContextData?.myDetails.full_name
                    : "-",
                },
                {
                  label: "Gender",
                  value: userContextData?.myDetails?.gender
                    ? userContextData?.myDetails?.gender
                    : "-",
                },
                {
                  label: "Bio",
                  value: userContextData?.myDetails?.bio
                    ? userContextData?.myDetails?.bio
                    : "-",
                },
                {
                  label: "Date of Birth",
                  value: userContextData?.myDetails?.dob
                    ? dayjs(userContextData?.myDetails?.dob).format(
                        standard_date_only_format_frontend
                      )
                    : "-",
                },
              ]}
            />
          </div>
        </div>
        <div className="p-4 rounded-md bg-primaryBackground flex flex-col gap-8">
          <div className="flex items-center justify-between w-full">
            <div className="text-xl font-medium">Bank Details</div>
            <div
              onClick={showUpdateBankDetailsModal}
              className="flex items-center gap-1 cursor-pointer"
            >
              <img src={edit} alt="edit" />
              <div className="text-primaryColor">Edit</div>
            </div>
          </div>
          <div className="w-full min-h-[20vh] bg-gray-100 py-4 rounded-md">
            {userContextData?.myDetails?.bank_name ||
            userContextData?.myDetails?.bank_branch ||
            userContextData?.myDetails?.bank_account_name ||
            userContextData?.myDetails?.bank_account_number ? (
              <ShowLableValueForDetails
                details={[
                  {
                    label: "Bank Name",
                    value: userContextData?.myDetails?.bank_name
                      ? userContextData?.myDetails?.bank_name
                      : "-",
                  },
                  {
                    label: "Branch Name",
                    value: userContextData?.myDetails?.bank_branch
                      ? userContextData?.myDetails?.bank_branch
                      : "-",
                  },
                  {
                    label: "Account Name",
                    value: userContextData?.myDetails?.bank_account_name
                      ? userContextData?.myDetails?.bank_account_name
                      : "-",
                  },
                  {
                    label: "Account Number",
                    value: userContextData?.myDetails?.bank_account_number
                      ? userContextData?.myDetails?.bank_account_number
                      : "-",
                  },
                ]}
              />
            ) : (
              <div className="h-[20vh] flex items-center justify-center">
                No Bank Details
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-8 bg-primaryBackground p-4 rounded-md">
          <div className="text-xl font-medium">Employment Details</div>
          <ShowLableValueForDetails
            details={[
              {
                label: "Company Name",
                value: userContextData?.allDetails?.business?.business_name
                  ? userContextData?.allDetails?.business?.business_name
                  : "-",
              },
              {
                label: "Employment Type",
                value: userContextData?.allDetails?.business?.employment_type
                  ? userContextData?.allDetails?.business?.employment_type
                  : "-",
              },
              {
                label: "Start Date",
                value: userContextData?.allDetails?.business?.start_date
                  ? userContextData?.allDetails?.business?.start_date
                  : "-",
              },
            ]}
          />
        </div>
        <div className="flex flex-col gap-8 p-4 rounded-md bg-primaryBackground">
          <div className="flex items-center justify-between w-full">
            <div className="text-xl font-medium">Contact Details</div>
            <div
              onClick={showUpdateContactDetailsModal}
              className="flex items-center gap-1 cursor-pointer"
            >
              <img src={edit} alt="edit" />
              <div className="text-primaryColor">Edit</div>
            </div>
          </div>
          <ShowLableValueForDetails
            details={[
              {
                label: "Personal Email",
                value: userContextData?.myDetails?.personal_email
                  ? userContextData?.myDetails?.personal_email
                  : "-",
              },
              {
                label: "Personal Phone",
                value: userContextData?.myDetails?.personal_phone
                  ? userContextData?.myDetails?.personal_phone
                  : "-",
              },
              {
                label: "Current Address",
                value: userContextData?.myDetails?.current_address
                  ? userContextData?.myDetails?.current_address
                  : "-",
              },
              {
                label: "Permanent Address",
                value: userContextData?.myDetails?.permanent_address
                  ? userContextData?.myDetails?.permanent_address
                  : "-",
              },
              {
                label: "ID Type",
                value: userContextData?.myDetails?.id_type
                  ? userContextData?.myDetails?.id_type
                  : "-",
              },
              {
                label: "ID Number",
                value: userContextData?.myDetails?.id_number
                  ? userContextData?.myDetails?.id_number
                  : "-",
              },
              {
                label: "PAN Number",
                value: userContextData?.myDetails?.pan_number
                  ? userContextData?.myDetails?.pan_number
                  : "-",
              },
            ]}
          />
        </div>
      </div>
      {updatePersonalDetailsModal && (
        <PopupModal
          top="10vh"
          width={550}
          open={updatePersonalDetailsModal}
          onCancel={onUpdatePersonalDetailsCancel}
          onOkay={onUpdatePersonalDetailsOkay}
          content={
            <div className="flex flex-col items-start gap-6 w-full p-2">
              <div className="text-xl font-semibold">
                Update Personal Details!
              </div>
              <div className="w-full">
                <Form
                  layout="vertical"
                  form={form}
                  className="flex flex-col w-full"
                  initialValues={{
                    first_name:
                      userContextData?.myDetails?.full_name.split(" ")[0],
                    last_name:
                      userContextData?.myDetails?.full_name.split(" ")[1],
                    gender: userContextData?.myDetails?.gender,
                    bio: userContextData?.myDetails?.bio,
                    dob: dayjs(userContextData?.myDetails?.dob),
                  }}
                >
                  <div className="flex items-center w-full justify-between gap-4">
                    <Form.Item
                      required
                      label="First Name"
                      name="first_name"
                      className="font-medium"
                      rules={[
                        {
                          required: true,
                          message: "First Name is required.",
                        },
                      ]}
                    >
                      <Input className="border border-gray-300 rounded-md w-full py-2 px-4" />
                    </Form.Item>
                    <Form.Item
                      required
                      className="font-medium"
                      label="Last Name"
                      name="last_name"
                      rules={[
                        {
                          required: true,
                          message: "Last Name is required.",
                        },
                      ]}
                    >
                      <Input className="border border-gray-300 rounded-md w-full py-2 px-4" />
                    </Form.Item>
                  </div>
                  <Form.Item
                    className="font-medium"
                    label="Gender"
                    name="gender"
                  >
                    <Select
                      bordered={false}
                      showSearch
                      className="border border-gray-300 rounded-md h-10 px-1"
                      placeholder="select gender"
                      options={[
                        { value: "Male", label: "Male" },
                        { value: "Female", label: "Female" },
                        { value: "Others", label: "Others" },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item label="Bio" name="bio">
                    <TextArea
                      className="border border-gray-300 rounded-md w-full py-2 px-4"
                      placeholder="introduce yourself"
                      maxLength={200}
                      rows={2}
                    />
                  </Form.Item>
                  <Form.Item label="Date of Birth" name="dob">
                    <DatePicker
                      className="border border-gray-300 rounded-md w-full py-2 px-4"
                      placeholder="select your date of birth"
                      format={standard_date_only_format_frontend}
                    />
                  </Form.Item>
                </Form>
              </div>
            </div>
          }
          button1="Cancel"
          button2="Update Personal Details"
          hideButton1=""
          hideButton2=""
          button1Color="white"
          button2Color="white"
          button1BgColor="rejectedColor"
          button2BgColor="primaryColor"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      {updateBankDetailsModal && (
        <PopupModal
          top="10vh"
          width={550}
          open={updateBankDetailsModal}
          onCancel={onUpdateBankDetailsCancel}
          onOkay={onUpdateBankDetailsOkay}
          content={
            <div className="flex flex-col items-start gap-6 w-full p-2">
              <div className="text-xl font-semibold">Update Bank Details!</div>
              <div className="w-full">
                <Form
                  layout="vertical"
                  form={form}
                  className="flex flex-col w-full"
                  initialValues={{
                    bank_name: userContextData?.myDetails?.bank_name,
                    bank_branch: userContextData?.myDetails?.bank_branch,
                    bank_account_name:
                      userContextData?.myDetails?.bank_account_name,
                    bank_account_number:
                      userContextData?.myDetails?.bank_account_number,
                  }}
                >
                  <Form.Item
                    required
                    label="Bank Name"
                    name="bank_name"
                    className="font-medium"
                    rules={[
                      {
                        required: true,
                        message: "Bank Name is required.",
                      },
                    ]}
                  >
                    <Select
                      bordered={false}
                      className="border border-gray-300 rounded-md h-10 px-1"
                      showSearch
                    >
                      {BanksInNepal.map((bank: any, index: number) => (
                        <Select.Option key={index} value={bank.bank}>
                          {bank.bank}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    required
                    label="Bank Branch"
                    name="bank_branch"
                    className="font-medium"
                    rules={[
                      {
                        required: true,
                        message: "Bank Branch is required.",
                      },
                    ]}
                  >
                    <Input className="border border-gray-300 rounded-md w-full py-2 px-4" />
                  </Form.Item>
                  <Form.Item
                    required
                    label="Bank Account Name"
                    name="bank_account_name"
                    className="font-medium"
                    rules={[
                      {
                        required: true,
                        message: "Bank Account Name is required.",
                      },
                    ]}
                  >
                    <Input className="border border-gray-300 rounded-md w-full py-2 px-4" />
                  </Form.Item>
                  <Form.Item
                    required
                    label="Bank Account Number"
                    name="bank_account_number"
                    className="font-medium"
                    rules={[
                      {
                        required: true,
                        message: "Bank Account Number is required.",
                      },
                    ]}
                  >
                    <Input className="border border-gray-300 rounded-md w-full py-2 px-4" />
                  </Form.Item>
                </Form>
              </div>
            </div>
          }
          button1="Cancel"
          button2="Update Bank Details"
          hideButton1=""
          hideButton2=""
          button1Color="white"
          button2Color="white"
          button1BgColor="rejectedColor"
          button2BgColor="primaryColor"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      {updateContactDetailsModal && (
        <PopupModal
          top="4vh"
          width={550}
          open={updateContactDetailsModal}
          onCancel={onUpdateContactDetailsCancel}
          onOkay={onUpdateContactDetailsOkay}
          content={
            <div className="flex flex-col items-start gap-6 w-full p-2">
              <div className="text-xl font-semibold">
                Update Contact Details!
              </div>
              <div className="w-full">
                <Form
                  layout="vertical"
                  form={form}
                  className="flex flex-col w-full"
                  initialValues={{
                    personal_email: userContextData?.myDetails?.personal_email,
                    personal_phone: userContextData?.myDetails?.personal_phone,
                    current_address:
                      userContextData?.myDetails?.current_address,
                    permanent_address:
                      userContextData?.myDetails?.permanent_address,
                    id_type: userContextData.myDetails.id_type,
                    id_number: userContextData.myDetails.id_number,
                    pan_number: userContextData.myDetails.pan_number,
                  }}
                >
                  <div className="flex items-center gap-4 w-full">
                    <Form.Item
                      required
                      label="Personal Email"
                      name="personal_email"
                      rules={[
                        {
                          required: true,
                          message: "Personal Email is required.",
                        },
                      ]}
                      className="font-medium w-full"
                    >
                      <Input
                        type="email"
                        className="border border-gray-300 rounded-md w-full py-2 px-4"
                      />
                    </Form.Item>
                    <Form.Item
                      required
                      label="Personal Phone"
                      name="personal_phone"
                      rules={[
                        {
                          required: true,
                          message: "Personal Phone is required.",
                        },
                      ]}
                      className="font-medium w-full"
                    >
                      <Input className="border border-gray-300 rounded-md w-full py-2 px-4" />
                    </Form.Item>
                  </div>
                  <Form.Item
                    required
                    label="Current Address"
                    name="current_address"
                    className="font-medium"
                    rules={[
                      {
                        required: true,
                        message: "Current Address is required.",
                      },
                    ]}
                  >
                    <Input className="border border-gray-300 rounded-md w-full py-2 px-4" />
                  </Form.Item>
                  <Form.Item
                    required
                    label="Permanent Address"
                    name="permanent_address"
                    className="font-medium"
                    rules={[
                      {
                        required: true,
                        message: "Permanent Address is required.",
                      },
                    ]}
                  >
                    <Input className="border border-gray-300 rounded-md w-full py-2 px-4" />
                  </Form.Item>
                  <Form.Item
                    required
                    label="ID Type"
                    name="id_type"
                    className="font-medium"
                    rules={[
                      {
                        required: true,
                        message: "ID Type is required.",
                      },
                    ]}
                  >
                    <Select
                      bordered={false}
                      className="border border-gray-300 rounded-md h-10 px-1"
                      showSearch={true}
                    >
                      {TypesOfIds.map((id: any, index: number) => (
                        <Select.Option key={index} value={id.id_type}>
                          {id.type}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div className="flex items-center gap-4">
                    <Form.Item
                      required
                      label="ID Number"
                      name="id_number"
                      className="font-medium"
                      rules={[
                        {
                          required: true,
                          message: "ID Number is required.",
                        },
                      ]}
                    >
                      <Input className="border border-gray-300 rounded-md w-full py-2 px-4" />
                    </Form.Item>
                    <Form.Item
                      required
                      label="PAN Number"
                      name="pan_number"
                      className="flex-1"
                      rules={[
                        {
                          required: true,
                          message: "PAN Number is required.",
                        },
                      ]}
                    >
                      <Input className="border border-gray-300 rounded-md w-full py-2 px-4" />
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </div>
          }
          button1="Cancel"
          button2="Update Contact Details"
          hideButton1=""
          hideButton2=""
          button1Color="white"
          button2Color="white"
          button1BgColor="rejectedColor"
          button2BgColor="primaryColor"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}
    </div>
  );
};

export default EmployeeMyProfile;
