import React, { useState, useEffect } from "react";
import axiosInstance from "../../../utils/axiosInstance";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import ProfilePictureHead from "../../globalComponents/profilePictureHead/profilePictureHead";

const EmployeeTeamMembers = () => {
  const navigate = useNavigate();
  const [employess, setEmployess] = useState<any>([]);

  const getEmployess = async () => {
    try {
      const response = await axiosInstance.get("/users");
      setEmployess(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEmployess();
  }, []);
  return (
    <div>
      <div className="pb-6 flex items-center gap-4">
        <div className="text-3xl font-medium ">Team Members</div>
        <div className="text-secondaryText">
          {employess.length} total members
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4 h-64">
        {employess && employess.length > 0 ? (
          employess.map((employee: any, index: any) => {
            return (
              <div
                onClick={() => {
                  navigate(`/employee/team-members/${employee._id}`);
                }}
                key={index}
                className="flex flex-col items-center justify-center gap-4 p-4 rounded-md bg-primaryBackground cursor-pointer"
              >
                <ProfilePictureHead
                  username={employee.full_name}
                  profile={employee?.profile_picture}
                  height="h-24"
                  width="w-24"
                  showUpdate="hidden"
                  fontSize="text-xl"
                />

                <div className="flex flex-col gap-4 items-center">
                  <div className="text-xl text-center font-semibold hover:text-primaryColor transition-all duration-150 ease-in">
                    {employee.full_name}
                  </div>
                  <div className=" text-secondaryText">{employee.position}</div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="flex items-center justify-center h-[30vh]">
            <Spin>
              <div className="content" />
            </Spin>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeTeamMembers;
