import React, { useEffect, useState } from "react";
import axiosInstance from "../../../utils/axiosInstance";
import dayjs from "dayjs";
import {
  standard_date_only_format_frontend,
  standard_time_only_format_frontend,
} from "../../../utils/dateAndTimeFormatter";
import { useNavigate } from "react-router-dom";
import { Spin, Table, Tag } from "antd";
import truncateLongerUsername from "../../../utils/truncateLongerUsernames";
import ProfilePictureHeadGroup from "../../globalComponents/profilePictureHead/profilePictureHeadGroup";
import greet from "../../../utils/greetAccordingToTime";
import decodeAccessToken from "../../../utils/decodedAccessToken";

const Overview = () => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");
  const { name }: any = decodeAccessToken(accessToken);
  const firstName = name.split(" ", 1);
  const [loading, setLoading] = useState<boolean>(false);
  const [overviews, setOverviews] = useState<any>([]);
  const [pendingTimeofffs, setPendingTimeoffs] = useState<any>([]);
  const [pendingTimeoffsDetails, setPendingTimeoffsDetails] = useState<any>([]);
  const [todaysAttendance, setTodaysAttendance] = useState<any>([]);
  const [todaysAttendanceDetails, setTodaysAttendanceDetails] = useState<any>(
    []
  );
  const [todaysWorklogs, setTodayWorklogs] = useState<any>([]);
  const [todaysWorklogsDetails, setTodayWorklogsDetails] = useState<any>([]);

  const getAdminOverviews = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get("/reports/admin/overview");
      setOverviews(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getAdminPendingTimeoffs = async () => {
    try {
      const response = await axiosInstance.get(
        "/reports/admin/adminPendingTimeoffs"
      );
      setPendingTimeoffs(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getTodaysAttendance = async () => {
    try {
      const response = await axiosInstance.get(
        "/reports/admin/adminGetAttendanceToday"
      );
      setTodaysAttendance(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getTodaysWorklogs = async () => {
    try {
      const response = await axiosInstance.get(
        "/reports/admin/adminGetWorklogToday"
      );
      setTodayWorklogs(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAdminOverviews();
    getAdminPendingTimeoffs();
    getTodaysAttendance();
    getTodaysWorklogs();
  }, []);

  const pendingTimeoffsColumn: any = [
    {
      title: "Requested By",
      dataIndex: "requested_by",
      key: "requested_by",
    },
    {
      title: "Timeoff Type",
      dataIndex: "timeoff_type",
      key: "timeoff_type",
    },
    {
      title: "Timeoff Reason",
      dataIndex: "timeoff_reason",
      key: "timeoff_reason",
      width: "40%",
    },
  ];

  useEffect(() => {
    const timeoff = pendingTimeofffs?.map((timeoff: any, index: number) => {
      return {
        key: timeoff?._id,
        requested_by: (
          <div
            onClick={() => {
              navigate(`/dashboard/team-members/${timeoff?.user_id._id}`);
            }}
          >
            <ProfilePictureHeadGroup
              profile={timeoff.user_id.profile_picture}
              username={timeoff.user_id.full_name}
            />
          </div>
        ),
        timeoff_type: <div>{timeoff.timeoff_type}</div>,
        timeoff_reason: (
          <div>{truncateLongerUsername(timeoff.timeoff_reason, 50)}</div>
        ),
      };
    });
    setPendingTimeoffsDetails(timeoff);
  }, [pendingTimeofffs]);

  const todaysAttendanceColumn: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Clock-In",
      dataIndex: "clockin_time",
      key: "clockin_time",
    },
    {
      title: "Clock-Out",
      dataIndex: "clockout_time",
      key: "clockout_time",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  useEffect(() => {
    const attendances = todaysAttendance?.map((attendance: any) => {
      return {
        key: attendance?._id,
        name: (
          <div
            onClick={() => {
              navigate(`/dashboard/team-members/${attendance?._id}`);
            }}
          >
            <ProfilePictureHeadGroup
              profile={attendance.profile_picture}
              username={attendance.full_name}
            />
          </div>
        ),

        clockin_time: (
          <div className="flex flex-col">
            <div>
              {attendance?.clock_data?.clock_in_time
                ? dayjs(attendance?.clock_data?.clock_in_time).format(
                    standard_time_only_format_frontend
                  )
                : "-"}
            </div>
            <div>
              {(attendance?.clock_data?.attendance_clockin_status ===
                "On Time" && (
                <Tag
                  color="#"
                  className="bg-approvedColor font-semibold text-[10px] w-18 text-center"
                >
                  {attendance?.clock_data?.attendance_clockin_status}
                </Tag>
              )) ||
                (attendance?.clock_data?.attendance_clockin_status ===
                  "Half Day" && (
                  <Tag
                    color="#"
                    className="bg-late font-semibold text-[10px] w-18 text-center"
                  >
                    {attendance?.clock_data?.attendance_clockin_status}
                  </Tag>
                )) ||
                (attendance?.clock_data?.attendance_clockin_status ===
                  "Late" && (
                  <Tag
                    color="#"
                    className="bg-late font-semibold text-[10px] w-18 text-center"
                  >
                    {attendance?.clock_data?.attendance_clockin_status}
                  </Tag>
                ))}
            </div>
          </div>
        ),
        clockout_time: (
          <div className="flex flex-col">
            <div>
              {attendance?.clock_data?.clock_out_time
                ? dayjs(attendance?.clock_data?.clock_out_time).format(
                    standard_time_only_format_frontend
                  )
                : "-"}
            </div>
            <div>
              {(attendance?.clock_data?.attendance_clockout_status ===
                "Full Time" && (
                <Tag
                  color="#"
                  className="font-semibold bg-approvedColor text-[10px] w-18 text-center"
                >
                  {attendance?.clock_data?.attendance_clockout_status}
                </Tag>
              )) ||
                (attendance?.clock_data?.attendance_clockout_status ===
                  "Left Early" && (
                  <Tag
                    color="#"
                    className="font-semibold bg-late text-[10px] w-18 text-center"
                  >
                    {attendance?.clock_data?.attendance_clockout_status}
                  </Tag>
                ))}
            </div>
          </div>
        ),
        status: attendance?.clock_data?.approval_status
          ? (attendance?.clock_data?.approval_status.toLowerCase() ===
              "approved" && (
              <Tag
                color="#"
                className="font-semibold bg-approvedColor text-[10px] w-18 text-center"
              >
                {attendance?.clock_data?.approval_status}
              </Tag>
            )) ||
            (attendance?.clock_data?.approval_status.toLowerCase() ===
              "pending" && (
              <Tag
                color="#"
                className="font-semibold bg-pendingColor text-[10px] w-18 text-center"
              >
                {attendance?.clock_data?.approval_status}
              </Tag>
            ))
          : "-",
      };
    });
    setTodaysAttendanceDetails(attendances);
  }, [todaysAttendance]);

  const todaysWorklogsColumn: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Today's Worklogs",
      dataIndex: "todays_worklog",
      key: "todays_workog",
    },
  ];

  useEffect(() => {
    const worklog = todaysWorklogs?.map((worklog: any) => {
      return {
        key: worklog?._id,
        name: (
          <div
            onClick={() => {
              navigate(`/dashboard/team-members/${worklog?._id}`);
            }}
          >
            <ProfilePictureHeadGroup
              profile={worklog.profile_picture}
              username={worklog.full_name}
            />
          </div>
        ),
        todays_worklog: (
          <div>
            {worklog?.worklog_data?.log ? (
              truncateLongerUsername(worklog?.worklog_data?.log, 80)
            ) : (
              <Tag
                color="#"
                className="bg-rejectedColor font-semibold text-[10px] w-18 text-center"
              >
                No Worklog
              </Tag>
            )}
          </div>
        ),
      };
    });
    setTodayWorklogsDetails(worklog);
  }, [todaysWorklogs]);

  return (
    <Spin spinning={loading}>
      <div className="text-primaryText">
        <div className="pb-6 flex items-center justify-between gap-4">
          <div className="text-3xl font-medium">
            {greet()},{firstName}
          </div>
          <div className="flex flex-col items-end">
            <div className="text-xl font-medium">
              {dayjs().format(standard_time_only_format_frontend)}
            </div>
            <div className="text-sm text-secondaryText">
              {dayjs().format("MMMM D, YYYY")}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="grid grid-cols-4 gap-4 h-24">
            <div className="p-4 bg-primaryBackground rounded-md flex flex-col gap-2 text-secondaryText">
              <div>Present Today</div>
              <div className="text-2xl font-semibold text-approvedColor">
                {overviews.present_today}
              </div>
            </div>
            <div className="p-4 bg-primaryBackground rounded-md flex flex-col  gap-2 text-secondaryText">
              <div>Absent Today</div>
              <div className="text-2xl font-semibold text-rejectedColor">
                {overviews.absent_today}
              </div>
            </div>
            <div className="p-4 bg-primaryBackground rounded-md flex flex-col  gap-2 text-secondaryText">
              <div>Timeoffs Requests</div>
              <div className="text-2xl font-semibold text-primaryText">
                {overviews.pending_timeoff_requests}
              </div>
            </div>
            <div className="p-4 bg-primaryBackground rounded-md flex flex-col  gap-2 text-secondaryText">
              <div>Leave Requests</div>
              <div className="text-2xl font-semibold text-primaryText">
                {overviews.pending_leave_requests}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 w-full">
            <div className="p-4 bg-primaryBackground rounded-md flex flex-col gap-4">
              <div className="text-secondaryText">Pending Approvals</div>
              <div className="w-full border border-b"></div>
              <Table
                style={{
                  scrollbarWidth: "thin",
                }}
                className="cursor-pointer h-72 overflow-auto"
                size="small"
                columns={pendingTimeoffsColumn}
                dataSource={pendingTimeoffsDetails}
                pagination={false}
              />
            </div>
            <div className="p-4 bg-primaryBackground rounded-md flex flex-col gap-4">
              <div className="flex items-center justify-between gap-4 text-secondaryText w-full">
                <div className="">Today's Attendances</div>
              </div>
              <div className="w-full border border-b"></div>
              <Table
                style={{
                  scrollbarWidth: "thin",
                }}
                className="cursor-pointer h-72 overflow-auto"
                size="small"
                columns={todaysAttendanceColumn}
                dataSource={todaysAttendanceDetails}
                pagination={false}
              />
            </div>
            <div className="p-4 bg-primaryBackground rounded-md flex flex-col gap-4">
              <div className="flex items-center gap-2">
                <div className="text-secondaryText">Today's Worklogs</div>
                <span className="text-[10px] text-primaryColor">
                  (
                  {dayjs()
                    .startOf("day")
                    .format(standard_date_only_format_frontend)}
                  )
                </span>
              </div>
              <div className="w-full border border-b"></div>
              <Table
                style={{
                  scrollbarWidth: "thin",
                }}
                className="cursor-pointer h-72 overflow-auto"
                size="small"
                columns={todaysWorklogsColumn}
                dataSource={todaysWorklogsDetails}
                pagination={false}
              />
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default Overview;
