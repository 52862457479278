import React from "react";
import { Route, Routes } from "react-router-dom";

import Overview from "../components/pageComponents/adminDashboard/overview";
import SettingsGeneral from "../components/pageComponents/adminDashboard/settings/settings-general";
import SettingsProfile from "../components/pageComponents/adminDashboard/settings/settings-profile";
import SettingsHoliday from "../components/pageComponents/adminDashboard/settings/settings-holiday";
import SettingsTrackingPolicies from "../components/pageComponents/adminDashboard/settings/settings--tracking-policies";
import AdminDashboardLayout from "../components/layouts/adminDashboardLayout";
import AllTeamMembers from "../components/pageComponents/adminDashboard/admin-team-members/all-team-members";
import SingleTeamMember from "../components/pageComponents/adminDashboard/admin-team-members/single-team-member/single-team-member";
import Payslip from "../components/pageComponents/adminDashboard/payslip";
import Timeoffs from "../components/pageComponents/adminDashboard/timeoffs";
import ManualEntries from "../components/pageComponents/adminDashboard/manual-entries";

const AdminDashboard = () => {
  return (
    <AdminDashboardLayout>
      <Routes>
        <Route path="overview" element={<Overview />} />
        <Route path="team-members" element={<AllTeamMembers />} />
        <Route path="timeoffs" element={<Timeoffs />} />
        <Route path="manual-entries" element={<ManualEntries />} />
        <Route path="payslips" element={<Payslip />} />
        <Route path="team-members/:user_id" element={<SingleTeamMember />} />
        <Route path="settings/holiday" element={<SettingsHoliday />} />
        <Route
          path="settings/tracking-policies"
          element={<SettingsTrackingPolicies />}
        />
        <Route path="settings/general" element={<SettingsGeneral />} />
        <Route path="profile" element={<SettingsProfile />} />
      </Routes>
    </AdminDashboardLayout>
  );
};

export default AdminDashboard;
