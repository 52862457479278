import React, { useState } from "react";
import axiosInstance from "../utils/axiosInstance";

interface IUserContext {
  allDetails: any[];
  myDetails: any[];
  getConfig: any;
  tasks: number;
  notifications: number;
}

export const userContext = React.createContext<IUserContext>({
  allDetails: [],
  myDetails: [],
  getConfig: () => {},
  tasks: 0,
  notifications: 0,
});

const UserContextFunction = ({ children }: any) => {
  const [allDetails, setAllDetails] = useState<any>({});
  const [myDetails, setMyDetails] = useState<any>({});
  const [tasks, setTasks] = useState<number>(0);
  const [notifications, setNotifications] = useState<number>(0);

  const getConfig = async () => {
    if (localStorage.getItem("accessToken")) {
      try {
        const response = await axiosInstance.get("users/config");
        setAllDetails(response.data);
        setMyDetails(response.data.data[0]);
        setTasks(response.data.tasks);
        setNotifications(response.data.notifications);
      } catch (error: any) {
        console.error(error);
      }
    }
  };

  return (
    <userContext.Provider
      value={{ allDetails, myDetails, getConfig, tasks, notifications }}
    >
      {children}
    </userContext.Provider>
  );
};

export default UserContextFunction;
