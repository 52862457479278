import TimeoffRequests from "./admin-team-members/single-team-member/timeoff-request";

const Timeoffs = () => {
  return (
    <div className="text-primaryText">
      <div className="pb-6 flex items-center justify-between ">
        <div className="text-3xl font-medium">Timeoffs</div>
      </div>
      <TimeoffRequests />
    </div>
  );
};

export default Timeoffs;
