import {
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Spin,
  Table,
  Tag,
  TimePicker,
} from "antd";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../utils/axiosInstance";
import moment from "moment";
import {
  standard_date_only_format_backend,
  standard_date_only_format_frontend,
  standard_time_only_format_backend,
  standard_time_only_format_frontend,
} from "../../../utils/dateAndTimeFormatter";
import deleteIcon from "../../../assets/delete.svg";
import PopupModal from "../../globalComponents/popupModal";
import notificationShow from "../../globalComponents/notificationService";
import dayjs from "dayjs";
import decodeAccessToken from "../../../utils/decodedAccessToken";
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const EmployeeTimeOff = () => {
  const accessToken = localStorage.getItem("accessToken");
  const { _id }: any = decodeAccessToken(accessToken);

  const [form] = Form.useForm();
  const [timeOffs, setTimeOffs] = useState<any>([]);
  const [leaveTypes, setLeaveTypes] = useState<any>([]);
  const [timeOffDetails, setTimeOffDetails] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [popupRequestTimeOff, setPopupRequestTimeoff] = useState(false);
  const [timeoffRequestId, setTimeoffRequestId] = useState<string>("");
  const [timeoffStart, setTimeoffStart] = useState<string>("");
  const [timeoffEnd, setTimeoffEnd] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [showLeaveType, setShowLeaveType] = useState<boolean>(false);
  const [showExpectedTime, setShowExpectedTime] = useState<boolean>(false);

  const getTimeOffs = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `/attendance/timeoffs?user_id=${_id}`
      );
      setTimeOffs(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTimeOffs();
  }, []);

  const timeOffColumns: any = [
    {
      title: "S.N",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Starts From",
      dataIndex: "from_date",
      key: "from_date",
    },
    {
      title: "Ends On",
      dataIndex: "to_date",
      key: "to_date",
    },
    {
      title: "No. Of Days",
      dataIndex: "no_of_days",
      key: "no_of_days",
    },
    {
      title: "Type",
      dataIndex: "timeoff_type",
      key: "timeoff_type",
    },
    {
      title: "Reason",
      dataIndex: "timeoff_reason",
      key: "timeoff_reason",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  useEffect(() => {
    const timeoff = timeOffs?.map((timeoff: any, index: number) => {
      return {
        key: timeoff?._id,
        index: index + 1 + ".",
        from_date: moment(timeoff?.from_date).format(
          standard_date_only_format_frontend
        ),
        to_date: moment(timeoff?.to_date).format(
          standard_date_only_format_frontend
        ),
        no_of_days: timeoff?.number_of_days,
        timeoff_type: timeoff?.timeoff_type,
        timeoff_reason: (
          <div className="max-w-[15vw] clip">{timeoff?.timeoff_reason}</div>
        ),
        status:
          timeoff.status.toLowerCase() === "approved" ||
          timeoff.status.toLowerCase() === "rejected" ? (
            <>
              <Tag
                color="#"
                className={`${
                  timeoff.status.toLowerCase() === "approved" &&
                  "bg-approvedColor"
                } ${
                  timeoff.status.toLowerCase() === "rejected" &&
                  "bg-rejectedColor"
                } font-semibold`}
              >
                {timeoff.status}
              </Tag>
              <br />
              {timeoff.status.toLowerCase() === "approved" ? (
                <span className="text-approvedColor font-semibold text-[12px]">
                  Approved By: {timeoff.approved_by.full_name}
                </span>
              ) : (
                <span className="text-rejectedColor text-[12px] font-semibold">
                  Rejected By: {timeoff.rejected_by.full_name}
                </span>
              )}
              <br />
              {timeoff.status.toLowerCase() === "rejected" && (
                <div className="max-w-[15vw] text-[12px] clip text-rejectedColor font-semibold">
                  {timeoff.rejection_reason}
                </div>
              )}
            </>
          ) : (
            <div className="flex items-center gap-2">
              {timeoff?.status.toLowerCase() === "pending" && (
                <Tag
                  onClick={showDeleteTimeoffRequestModal}
                  color="#"
                  className="font-semibold bg-pendingColor"
                >
                  {timeoff.status}
                </Tag>
              )}
              {timeoff.status.toLowerCase() === "pending" && (
                <img
                  onClick={() => {
                    setTimeoffRequestId(timeoff._id);
                    setTimeoffStart(
                      moment(timeoff?.from_date).format(
                        standard_date_only_format_frontend
                      )
                    );
                    setTimeoffEnd(
                      moment(timeoff?.to_date).format(
                        standard_date_only_format_frontend
                      )
                    );
                    showDeleteTimeoffRequestModal();
                  }}
                  src={deleteIcon}
                  alt="delete"
                  className="h-4 w-4 cursor-pointer"
                />
              )}
            </div>
          ),
      };
    });
    setTimeOffDetails(timeoff);
  }, [timeOffs]);

  const getLeaveTypes = async () => {
    try {
      const response = await axiosInstance.get("settings/leave-types");
      setLeaveTypes(response.data.data);
      setShowLeaveType(true);
    } catch (error) {
      console.log(error);
    }
  };

  const showLeaveOptions = (value: string) => {
    if (value === "Leave Request") {
      getLeaveTypes();
      setShowExpectedTime(false);
    } else {
      setShowLeaveType(false);
      setShowExpectedTime(true);
    }
  };

  const requestTimeoff = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const formValues = form.getFieldsValue();
      const response = await axiosInstance.post("attendance/applyForTimeOff", {
        from_date: dayjs(formValues.timeoff_period[0]).format(
          standard_date_only_format_backend
        ),
        to_date: dayjs(formValues.timeoff_period[1]).format(
          standard_date_only_format_backend
        ),
        timeoff_type: formValues.timeoff_type,
        leave_type_id: formValues.leave_type,
        expected_time: dayjs(formValues.expected_time).format(
          standard_time_only_format_backend
        ),
        timeoff_reason: formValues.timeoff_reason,
      });
      getTimeOffs();
      notificationShow({
        type: "success",
        content: "Timeoff requested Successfully!",
      });
      form.resetFields();
      setPopupRequestTimeoff(false);
      setLoading(false);
      setShowExpectedTime(false);
      setShowLeaveType(false);
      setError("");
    } catch (error: any) {
      setLoading(false);
      setError(error?.response?.data?.error);
    }
  };

  const showRequestTimeOffModal = () => {
    setPopupRequestTimeoff(true);
    setLoading(false);
  };

  const onRequestTimeOffCancel = () => {
    setPopupRequestTimeoff(false);
  };

  const onRequestTimeOffOkay = () => {
    requestTimeoff();
  };

  const deleteTimeoffRequest = async () => {
    try {
      const response = await axiosInstance.delete(
        `/attendance/${timeoffRequestId}`
      );
      getTimeOffs();
      notificationShow({
        type: "success",
        content: `Timeoof request of ${timeoffStart} - ${timeoffEnd} deleted!`,
      });
      setPopupDeleteTimeoffRequestModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const [popupDeleteTimeoffRequestModal, setPopupDeleteTimeoffRequestModal] =
    useState(false);

  const showDeleteTimeoffRequestModal = () => {
    setPopupDeleteTimeoffRequestModal(true);
    setLoading(false);
  };

  const onDeleteTimeoffRequestCancel = () => {
    setPopupDeleteTimeoffRequestModal(false);
  };

  const onDeleteTimeoffRequestOkay = () => {
    deleteTimeoffRequest();
  };

  return (
    <div>
      <div className="pb-6 flex items-center justify-between">
        <div className="text-3xl font-medium ">Timeoffs</div>
        <div
          onClick={showRequestTimeOffModal}
          className="px-6 py-2 rounded-md border border-primaryColor hover:bg-hoverPrimaryColor transition-all duration-150 ease-in hover:border-hoverPrimaryColor bg-primaryColor text-white cursor-pointer"
        >
          Request Timeoff
        </div>
      </div>
      <Spin spinning={loading}>
        <Table
          className="cursor-pointer p-4 rounded-md bg-primaryBackground"
          size="middle"
          columns={timeOffColumns}
          dataSource={timeOffDetails}
          pagination={false}
        />
      </Spin>

      {popupRequestTimeOff && leaveTypes && (
        <PopupModal
          top="8vh"
          width={550}
          open={popupRequestTimeOff}
          onCancel={onRequestTimeOffCancel}
          onOkay={onRequestTimeOffOkay}
          content={
            <div className="flex flex-col items-start w-full p-2">
              <div className="text-xl font-semibold mb-6">
                Request Timeoff !
              </div>
              <Form layout="vertical" form={form} className="w-full">
                <Form.Item
                  required
                  label="Timeoff Period"
                  name="timeoff_period"
                  className="font-medium"
                  rules={[
                    {
                      required: true,
                      message: "Timeoff end date is required.",
                    },
                  ]}
                >
                  <RangePicker
                    className="border border-gray-300 rounded-md w-full py-2 px-4"
                    format={standard_date_only_format_frontend}
                  />
                </Form.Item>
                <Form.Item
                  required
                  name="timeoff_type"
                  className="font-medium"
                  label={<div className="">Timeoff Type</div>}
                  rules={[
                    {
                      required: true,
                      message: "Timeoff type is required.",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    bordered={false}
                    className="border border-gray-300 rounded-md h-10 px-1"
                    onChange={showLeaveOptions}
                    placeholder="Select timeoff type"
                    options={[
                      {
                        value: "Leave Request",
                        label: "Leave Request",
                      },
                      {
                        value: "Late Arrival Request",
                        label: "Late Arrival Request",
                      },
                      {
                        value: "Early Leave Request",
                        label: "Early Leave Request",
                      },
                    ]}
                  />
                </Form.Item>
                {showLeaveType && (
                  <Form.Item required name="leave_type" className="w-full">
                    <Radio.Group>
                      {leaveTypes.map((leave: any) => (
                        <Radio className="font-medium" value={leave._id}>
                          {leave.leave_type}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                )}
                {showExpectedTime && (
                  <Form.Item
                    className="font-medium"
                    required
                    label="Expected Time"
                    name="expected_time"
                  >
                    <TimePicker
                      className="border border-gray-300 rounded-md w-full py-2 px-4"
                      format={standard_time_only_format_frontend}
                    />
                  </Form.Item>
                )}
                <Form.Item
                  required
                  label="Reason"
                  className="font-medium"
                  name="timeoff_reason"
                  rules={[
                    {
                      required: true,
                      message: "Timeoff reason is required.",
                    },
                  ]}
                >
                  <TextArea
                    className="border border-gray-300 rounded-md w-full py-2 px-4"
                    maxLength={150}
                    rows={3}
                  />
                </Form.Item>
              </Form>
              {error && (
                <div className="bg-red-50 text-red-500 rounded-md text-sm text-center py-2 w-full">
                  {error}
                </div>
              )}
            </div>
          }
          button1="Cancel"
          button2="Request Timeoff"
          hideButton1=""
          hideButton2=""
          button1Color="red-500"
          button2Color="white"
          button1BgColor="secondaryColor"
          button2BgColor="primaryColor"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}

      {popupDeleteTimeoffRequestModal && timeoffRequestId && (
        <PopupModal
          top="20vh"
          width={450}
          open={popupDeleteTimeoffRequestModal}
          onCancel={onDeleteTimeoffRequestCancel}
          onOkay={onDeleteTimeoffRequestOkay}
          content={
            <div className="flex flex-col items-start gap-8 w-full p-2">
              <div className="text-xl font-semibold">
                Delete Timeoff Request !
              </div>
              <div>
                Are you sure you want to delete this timeoff request of{"  "}
                <span className="font-medium text-primaryColor">
                  {timeoffStart} - {timeoffEnd}
                </span>
                ?
              </div>
            </div>
          }
          button1="Cancel"
          button2="Delete Request"
          hideButton1=""
          hideButton2=""
          button1Color="primaryColor"
          button2Color="white"
          button1BgColor="secondaryColor"
          button2BgColor="rejectedColor"
          icon={"ok"}
          setLoading={setLoading}
          loading={loading}
        />
      )}
    </div>
  );
};

export default EmployeeTimeOff;
